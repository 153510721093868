<template>
  <v-card
    outlined
    elevation="0"
    width="400"
    height="430"
    class="pa-10 text-center"
  >
    <div class="d-flex justify-center">
      <div>
        <v-img width="140" src="@/assets/logo.png" />
      </div>
    </div>
    <v-card-title class="justify-center">パスワードの再設定</v-card-title>
    <v-card-subtitle>新しいパスワードを設定してください</v-card-subtitle>
    <div class="mt-2">
      <v-form v-model="btnDisable">
        <v-text-field
          label="パスワード"
          placeholder="パスワード"
          outlined
          type="password"
          :value="passwordInitialValue"
          @input="updatePassword"
          :rules="rules.password"
        />
        <v-text-field
          label="パスワード確認"
          placeholder="パスワード確認"
          outlined
          type="password"
          :value="passwordConfirmationInitialValue"
          @input="updatePasswordConfirmation"
          :rules="rules.passwordConfirmation"
        />
      </v-form>
    </div>
    <v-card-actions class="mt-0 pa-0 justify-space-between">
      <v-btn
        text
        color="primary"
        @click="$router.push({ path: '/login' })"
        v-text="'ログインへ戻る'"
      />
      <div>
        <v-btn
          elevation="0"
          color="primary"
          class="white--text"
          block
          @click="update"
          v-text="'更新'"
        />
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'UpdatePasswordForm',
  props: ['value'],
  data() {
    return {
      btnDisable: false,
      rules: {
        password: [value => validate.adminPassword(value)],
        passwordConfirmation: [value => validate.adminPassword(value)],
      },
    };
  },
  computed: {
    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },
    passwordConfirmationInitialValue() {
      return this.value == null ? null : this.value.password_confirmation;
    },
  },
  methods: {
    update() {
      this.$emit('updatePassword', !this.btnDisable);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
