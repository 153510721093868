export default {
  data() {
    return {
      employmentStatus: [
        { id: 0, value: '準委任' },
        { id: 1, value: '派遣' },
        { id: 2, value: '請負' },
      ],
    };
  },
};
