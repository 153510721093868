export default {
  data() {
    return {
      people: [
        { id: 0, value: 1 },
        { id: 1, value: 2 },
        { id: 2, value: 3 },
        { id: 3, value: 4 },
        { id: 4, value: 5 },
        { id: 5, value: 6 },
        { id: 6, value: 7 },
        { id: 7, value: 8 },
        { id: 8, value: 9 },
        { id: 9, value: 10 },
      ],
    };
  },
};
