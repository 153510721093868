export default {
  data() {
    return {
      paymentDay: [
        { id: 0, value: 1 },
        { id: 1, value: 5 },
        { id: 2, value: 10 },
        { id: 3, value: 15 },
        { id: 4, value: 20 },
        { id: 5, value: 25 },
        { id: 6, value: 30 },
      ],
    };
  },
};
