const textCopy = value => {
  // iPhone, iPadは以下のコピーする処理が動作しないためスキップ
  const agent = window.navigator.userAgent;
  if (agent.indexOf('iPhone') != -1 || agent.indexOf('iPad') != -1) return;

  const formattedText = `${value}`;
  navigator.clipboard.writeText(formattedText);
};

export default {
  textCopy,
};
