var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%"}},[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',[_vm._v("退会者一覧")]),_c('v-spacer')],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.users,"items-per-page":_vm.itemsPerPage,"fixed-header":"","height":"70vh"},on:{"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"pr-4",attrs:{"to":{ name: 'WithdrawalUser', params: { id: item.id } }},domProps:{"textContent":_vm._s(("#" + (item.id)))}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayUserName(item))+" ")]}},{key:"item.birth",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4",domProps:{"textContent":_vm._s(_vm.getAge(item.birth) || '-')}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_vm._v(" "+_vm._s(item.email || '-')+" ")])]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_vm._v(" "+_vm._s(_vm.getGender(item.gender))+" ")])]}},{key:"item.closest_station",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_vm._v(" "+_vm._s(item.closest_station || '-')+" ")])]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_vm._v(" "+_vm._s(_vm.displayDeletedAt(item))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }