<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title>退会者一覧</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="users"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'WithdrawalUser', params: { id: item.id } }"
          v-text="`#${item.id}`"
        />
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ displayUserName(item) }}
      </template>

      <template v-slot:[`item.birth`]="{ item }">
        <div class="pr-4" v-text="getAge(item.birth) || '-'" />
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <div class="pr-4">
          {{ item.email || '-' }}
        </div>
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <div class="pr-4">
          {{ getGender(item.gender) }}
        </div>
      </template>

      <template v-slot:[`item.closest_station`]="{ item }">
        <div class="pr-4">
          {{ item.closest_station || '-' }}
        </div>
      </template>

      <template v-slot:[`item.deleted_at`]="{ item }">
        <div class="pr-4">
          {{ displayDeletedAt(item) }}
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import session from '../plugins/session';
import mixinDate from '../const/date';
import mixinEntryStatus from '../const/entry/status';
import mixinGender from '../const/user/genders';
import mixinPriorityStatus from '../const/entry/priority';
import mixinPagination from '../plugins/pagination';
import dayjs from 'dayjs';

export default {
  name: 'WithdrawalUsers',
  mixins: [
    mixinDate,
    mixinEntryStatus,
    mixinPriorityStatus,
    mixinGender,
    mixinPagination,
  ],
  data() {
    return {
      users: [],
      headers: [
        { text: 'User ID', align: 'center', value: 'id', width: '10%' },
        {
          text: 'ユーザ名',
          align: 'center',
          value: 'name',
          sortable: false,
          width: '15%',
        },
        { text: '年齢', align: 'center', value: 'birth', width: '10%' },
        {
          text: 'メールアドレス',
          align: 'center',
          value: 'email',
          sortable: false,
          width: '25%',
        },
        {
          text: '性別',
          align: 'center',
          value: 'gender',
          width: '10%',
        },
        {
          text: '最寄り駅',
          align: 'center',
          value: 'closest_station',
          width: '10%',
        },
        {
          text: '退会日時',
          align: 'center',
          value: 'deleted_at',
          width: '20%',
        },
      ],
      loading: true,
    };
  },
  async created() {
    await this.getWithdrawalUsers();
    this.loading = false;
  },
  computed: {
    displayUserName() {
      return user => {
        if (user.last_name && user.first_name)
          return user.last_name + ' ' + user.first_name;
        return '-';
      };
    },

    displayDeletedAt() {
      return user => {
        return this.formatDatetime(user.deleted_at);
      };
    },
  },
  methods: {
    getAge(birthDate) {
      if (birthDate == null) return '-';
      const now = dayjs().format('YYYYMMDD');
      const dayjsParse = dayjs(birthDate).format('YYYYMMDD');
      return Math.trunc((now - dayjsParse) / 10000);
    },

    getGender(genderId) {
      if (genderId == null) return '-';
      const g = this.genders.find(gender => gender.id === genderId);
      return g.value;
    },

    async getWithdrawalUsers() {
      try {
        const result = await this.$axios.get('/api/v1/admin/withdrawal_users', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.users = result.data;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },
  },
};
</script>
