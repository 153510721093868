import common from './common';

const validate = {};

// common
validate.checkNumber = function (value) {
  return Number.isInteger(value) || '不正な値です。';
};

validate.checkBoolean = function (value) {
  const result = value === true || value === false;
  return result || '不正な値です。';
};

validate.checkExist = function (value) {
  return !!value || value === 0 || '入力してください';
};

// admin
validate.adminName = function (value, type) {
  if (!value) return '入力してください';
  const adminNameMaxLength = 20;
  return (
    value.length <= adminNameMaxLength || `${type}は20字以内で入力してください`
  );
};

validate.adminEmail = function (value) {
  if (!value) return '入力してください';
  const REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return REGEX.test(value) || '不正なメールアドレスです';
};

validate.adminPassword = function (value) {
  if (!value) return '入力してください';
  const REGEX = /^[a-zA-Z0-9!-/:-@\\[-`{-~]{8,32}$/;
  return (
    REGEX.test(value) ||
    '半角英数記号のみで、8文字以上32文字以内で入力してください'
  );
};

validate.adminId = function (value) {
  if (!value) return true;
  return Number.isInteger(value) || '不正な値です。';
};

// user
validate.userRemark = function (value) {
  if (!value) return true;
  const remarkMaxLength = 150;
  return (
    value.length <= remarkMaxLength || 'コメントは150文字以内で入力してください'
  );
};

// project
validate.projectName = function (value) {
  if (!value) return '入力してください';
  const projectNameMaxLength = 60;
  return (
    value.length <= projectNameMaxLength ||
    '案件名は60文字以内で入力してください'
  );
};

validate.projectDescription = function (value) {
  if (!value) return '入力してください';
  const descriptionMaxLength = 1000;
  return (
    value.length <= descriptionMaxLength ||
    '案件詳細説明は1000文字以内で入力してください'
  );
};

validate.projectNote = function (value) {
  if (!value) return true;
  const noteMaxLength = 1000;
  return (
    value.length <= noteMaxLength || '備考は1000文字以内で入力してください'
  );
};

validate.projectRequireSkill = function (value) {
  if (!value) return '入力してください';
  const requireSkillMaxLength = 1000;
  return (
    value.length <= requireSkillMaxLength ||
    '求められるスキルは1000文字以内で入力してください'
  );
};

validate.projectStation = function (value) {
  if (!value) return '入力してください';
  const stationMaxLength = 20;
  return (
    value.length <= stationMaxLength || 'エリアは20文字以内で入力してください'
  );
};

validate.projectCost = function (value) {
  if (!value) return '入力してください';
  const REGEX = /^(0|[1-9]\d{0,3})$/;
  return REGEX.test(value) || '4桁以下の半角数値で入力してください';
};

validate.projectInterviewType = function (value, type) {
  if (!value && type === 'second') return true;
  return ['オンライン', '対面'].includes(value) || '入力項目が不正です';
};

// entry
validate.entryNote = function (value) {
  if (!value) return true;
  const noteMaxLength = 150;
  return value.length <= noteMaxLength || '備考は500文字以内で入力してください';
};

validate.datetime = function (startAt, endAt, type) {
  if (!startAt || !endAt) return '';
  switch (type) {
    case 'recruitment':
      if (common.compare(startAt, endAt)) {
        return '';
      } else {
        return '募集の開始日は終了日より前に設定してください';
      }
    case 'uptime':
      if (common.compare(startAt, endAt)) {
        return '';
      } else {
        return '最低値が最高値より高くなっています';
      }
    case 'businessHours':
      if (common.compare(startAt, endAt)) {
        return '';
      }
      return '業務の開始時間は終了時間より前に設定してください';
  }
};

// skill
validate.skillName = function (value) {
  if (!value) return '入力してください';
  const skillNameMaxLength = 30;
  return (
    value.length <= skillNameMaxLength ||
    `スキルは${String(skillNameMaxLength)}文字以内で入力してください`
  );
};

export default validate;
