<template>
  <v-card
    outlined
    elevation="0"
    width="400"
    height="400"
    class="pa-10 text-center"
  >
    <div class="d-flex justify-center">
      <div>
        <v-img width="140" src="@/assets/logo.png" />
      </div>
    </div>
    <v-card-title class="justify-center">パスワードの再設定</v-card-title>
    <v-card-subtitle>Emailでパスワードを再設定</v-card-subtitle>
    <div class="mt-2">
      <v-form v-model="btnDisable">
        <v-text-field
          label="メールアドレス"
          placeholder="メールアドレス"
          outlined
          :value="emailInitialValue"
          @input="updateEmail"
          :rules="rules.email"
        />
      </v-form>
    </div>
    <v-card-actions class="mt-3 pa-0 justify-space-between">
      <v-btn
        elevation="0"
        color="primary"
        class="white--text"
        block
        @click="resetPassword"
      >
        <v-icon
          class="mail-icon-position"
          dark
          size="25"
          v-text="'mdi-email-outline'"
        />
        再設定メールを送信
      </v-btn>
    </v-card-actions>
    <v-card-actions class="mt-3 pa-0 justify-space-between">
      <v-btn
        text
        color="primary"
        @click="$router.push({ path: '/login' })"
        v-text="'ログインへ戻る'"
      />
      <v-btn
        text
        color="primary"
        @click="$router.push({ path: '/registeradmin' })"
        v-text="'新規登録へ'"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'ResetPasswordForm',
  props: ['value'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.adminEmail(value)],
      },
    };
  },
  computed: {
    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },
  },
  methods: {
    updateEmail(event_email) {
      const newValue = {
        ...this.value,
        email: event_email,
      };
      this.$emit('input', newValue);
    },
    resetPassword() {
      this.$emit('resetPassword', !this.btnDisable);
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}
</style>
