const session = {};

session.returnItemKeys = function () {
  return {
    'access-token': localStorage.getItem('admin_token'),
    client: localStorage.getItem('admin_client'),
    uid: localStorage.getItem('admin_uid'),
  };
};

session.removeItemKeys = function () {
  localStorage.removeItem('admin_token');
  localStorage.removeItem('admin_client');
  localStorage.removeItem('admin_uid');
};

session.setItemKeys = function (headers) {
  if ('localStorage' in window && window.localStorage !== null) {
    localStorage.setItem('admin_uid', headers['uid']);
    localStorage.setItem('admin_token', headers['access-token']);
    localStorage.setItem('admin_client', headers['client']);
  }
};

session.apiAuthHeaders = function () {
  const type = { 'Content-Type': 'application/json' };
  const auth_info = session.returnItemKeys();
  return Object.assign(type, auth_info);
};

session.isAuthenticated = function () {
  const keys = this.returnItemKeys();
  return !(!keys['access-token'] && !keys['client'] && !keys['uid']);
};

session.setUidToItemKeys = function (admin_uid) {
  localStorage.setItem('admin_uid', admin_uid);
};

export default session;
