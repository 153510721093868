<template>
  <v-responsive max-width="1161" class="mx-auto">
    <v-container>
      <v-container class="px-0 d-flex">
        <h2>問い合わせ詳細</h2>
      </v-container>
      <v-divider />
      <v-row
        v-if="inquiryLoading || inquiryStatusHistoriesLoading || adminLoading"
      >
        <v-col cols="12" align="center" style="height: 50vh">
          <div class="d-flex justify-center align-center" style="height: 100%">
            <v-progress-circular
              :width="3"
              size="32"
              color="primary"
              indeterminate
            />
          </div>
        </v-col>
      </v-row>
      <div v-else>
        <div class="pt-4">
          <h3 class="py-4">問い合わせ内容</h3>
          <InquiryDetail :inquiry="inquiry" @getCopyEmail="getCopyEmail" />
        </div>
        <div class="pt-4">
          <h3 class="py-4">問い合わせ管理情報</h3>
          <InquiryUpdateDetail
            v-model="inquiry"
            :admins="admins"
            @update="updateInquiry"
          />
        </div>
        <div class="pt-4">
          <h3 class="py-4">問い合わせステータス変更履歴</h3>
          <InquiryStatusHistory
            :inquiryStatusHistories="inquiryStatusHistories"
          />
        </div>
      </div>
    </v-container>
  </v-responsive>
</template>

<script>
import session from '../plugins/session';
import copy from '../plugins/copy';
import InquiryDetail from '../components/pc/InquiryDetail.vue';
import InquiryUpdateDetail from '../components/pc/InquiryUpdateDetail.vue';
import InquiryStatusHistory from '../components/pc/InquiryStatusHistory.vue';

export default {
  name: 'Inquiry',
  components: {
    InquiryDetail,
    InquiryUpdateDetail,
    InquiryStatusHistory,
  },
  data() {
    return {
      inquiry: null,
      inquiryStatusHistories: [],
      admins: [],
      adminLoading: true,
      inquiryLoading: true,
      inquiryStatusHistoriesLoading: true,
    };
  },
  created() {
    this.getInquiry();
    this.getInquiryStatusHistories();
    this.getAdmins();
  },
  methods: {
    async getAdmins() {
      try {
        const result = await this.$axios.get('/api/v1/admin/admins', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.admins = result.data;
        this.adminLoading = false;
      } catch (err) {
        this.adminLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getCopyEmail(targetEmail) {
      copy.textCopy(targetEmail);
      this.$emit('showSnackbar', 'success', 'コピーしました。');
    },

    async getInquiry() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/inquiries/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) this.inquiry = result.data;
        this.inquiryLoading = false;
      } catch (err) {
        this.inquiryLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getInquiryStatusHistories() {
      try {
        const result = await this.$axios.get(
          `/api/v1/admin/inquiries/${this.$route.params.id}/inquiry_status_histories`,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) this.inquiryStatusHistories = result.data;
        this.inquiryStatusHistoriesLoading = false;
      } catch (err) {
        this.inquiryStatusHistoriesLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async updateInquiry(inValid) {
      if (inValid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      try {
        const result = await this.$axios.patch(
          '/api/v1/admin/inquiries/' + this.$route.params.id,
          { inquiry: this.inquiry },
          { headers: session.apiAuthHeaders() }
        );
        this.inquiry = result.data;
        if (this.inquiry.admin_id != null) {
          this.$emit('showSnackbar', 'success', '更新に成功しました。');
          await this.getInquiryStatusHistories();
        } else {
          this.$emit(
            'showSnackbar',
            'success',
            '更新に成功しました。（担当者が未設定です。）'
          );
          await this.getInquiryStatusHistories();
        }
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したお問い合わせが見つかりません。'
            );
            this.$router.push({ name: 'Inquiries' });
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },
  },
};
</script>
