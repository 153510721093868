<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-container>
      <v-row>
        <v-col cols="6">
          <h5>件名</h5>
          <v-text-field
            outlined
            readonly
            dense
            hide-details
            :value="getTitle"
            class="pt-2"
          />
        </v-col>
        <template v-if="inquiry.project_id != null">
          <v-col cols="6">
            <h5>関連案件名</h5>
            <div class="pt-2">
              <router-link
                :to="{ name: 'Project', params: { id: inquiry.project_id } }"
                v-text="inquiry.project.name"
              />
            </div>
          </v-col>
        </template>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h5>内容</h5>
          <v-textarea
            outlined
            dense
            auto-grow
            readonly
            hide-details
            :value="inquiry.content"
            class="pt-2"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>ユーザー名</h5>
          <div class="pt-2">
            <router-link
              :to="{ name: 'User', params: { id: inquiry.user.id } }"
              v-if="
                this.inquiry.user.last_name != null ||
                this.inquiry.user.first_name != null
              "
            >
              {{ getUserName }}
            </router-link>
            <div v-else v-text="getUserName" />
          </div>
        </v-col>
        <v-col cols="6">
          <h5>メールアドレス</h5>
          <div class="d-flex">
            <div class="pt-2" v-text="getEmail" />
            <v-btn icon class="pt-1" @click="$emit('getCopyEmail', getEmail)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h5>送信日時</h5>
          <div class="pt-2" v-text="getSendTime" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import mixinDate from '../../const/date';
import mixinTitle from '../../const/inquiry/title';

export default {
  name: 'InquiryDetail',
  props: ['inquiry'],
  mixins: [mixinDate, mixinTitle],
  computed: {
    getTitle() {
      const exceptTitleId = this.inquiryTitle.find(
        element => element.value === 'その他'
      ).id;
      const targetInquiry = this.inquiryTitle.find(
        element => element.id === this.inquiry.title
      );
      if (targetInquiry.id === exceptTitleId) return this.inquiry.other_title;
      return targetInquiry.value;
    },

    getSendTime() {
      return this.formatDatetime(this.inquiry.created_at);
    },

    getUserName() {
      return this.inquiry.user.last_name != null ||
        this.inquiry.user.first_name != null
        ? this.inquiry.user.last_name + ' ' + this.inquiry.user.first_name
        : this.inquiry.name || '-';
    },

    getEmail() {
      return this.inquiry.user.email != null
        ? this.inquiry.user.email
        : this.inquiry.email;
    },
  },
};
</script>
