<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title>問い合わせ一覧</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-data-table
      show-select
      :loading="loading"
      v-model="selectedInquiries"
      :headers="headers"
      :items="inquiries"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'Inquiry', params: { id: item.id } }"
        >
          {{ '#' + item.id }}
        </router-link>
      </template>

      <!-- TODO: 案件に紐づく問い合わせが復活した際にコメントアウト外す -->
      <!-- <template v-slot:[`item.project`]="{ item }">
        <template v-if="item.project.name === undefined">
          {{ '-' }}
        </template>
        <template v-else>
          <router-link
            :to="{ name: 'Project', params: { id: item.project.id } }"
          >
            {{ item.project.name }}
          </router-link>
        </template>
      </template> -->

      <template v-slot:[`item.title`]="{ item }">
        {{ getTitle(item) }}
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <template
          v-if="item.user.last_name === null || item.user.first_name === null"
        >
          {{ item.name || '-' }}
        </template>
        <template v-else>
          <!-- TODO: 案件に紐づく問い合わせが復活した際にコメントアウト外す -->
          <router-link :to="{ name: 'User', params: { id: item.user.id } }">
            {{ item.user.last_name + item.user.first_name }}
          </router-link>
        </template>
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <v-icon
          class="pr-4"
          v-text="getPriority(item.priority).icon"
          :color="getPriority(item.priority).color"
        />
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="pr-4">
          <v-chip :color="getStatus(item.status).color" dense>
            {{ getStatus(item.status).value }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.admin`]="{ item }">
        {{ item.admin.last_name + item.admin.first_name || '-' }}
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        <div class="pr-4" v-text="displayUpdatedAt(item.updated_at)" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import session from '../plugins/session';
import mixinDate from '../const/date';
import mixinStatus from '../const/inquiry/status';
import mixinPriority from '../const/inquiry/priority';
import mixinTitle from '../const/inquiry/title';
import mixinPagination from '../plugins/pagination';

export default {
  name: 'Inquiries',
  mixins: [mixinDate, mixinStatus, mixinPriority, mixinTitle, mixinPagination],
  data() {
    return {
      headers: [
        { text: 'Inquiry ID', align: 'center', value: 'id', width: '10%' },
        // { text: '案件名', align: 'center', value: 'project', sortable: false },
        {
          text: '問い合わせ件名',
          align: 'center',
          value: 'title',
          sortable: false,
          width: '28%',
        },
        {
          text: 'ユーザー',
          align: 'center',
          value: 'user',
          sortable: false,
          width: '12%',
        },
        { text: '優先度', align: 'center', value: 'priority', width: '12%' },
        { text: '進行状況', align: 'center', value: 'status', width: '12%' },
        {
          text: '担当者',
          align: 'center',
          value: 'admin',
          sortable: false,
          width: '12%',
        },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '14%',
        },
      ],
      inquiries: [],
      selectedInquiries: [],
      loading: true,
    };
  },
  created() {
    this.getInquiries();
  },
  computed: {
    displayUpdatedAt() {
      return updatedAt => {
        return this.formatDatetime(updatedAt);
      };
    },
  },
  methods: {
    async getInquiries() {
      try {
        const result = await this.$axios.get('/api/v1/admin/inquiries', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.inquiries = result.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getPriority(eachNum) {
      const targetValue = this.inquiryPriority.find(
        element => element.id === eachNum
      );
      return { color: targetValue.color, icon: targetValue.icon };
    },

    getStatus(eachNum) {
      const targetValue = this.inquiryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getTitle(inquiry) {
      const exceptTitleId = this.inquiryTitle.find(
        element => element.value === 'その他'
      ).id;
      const targetInquiry = this.inquiryTitle.find(
        element => element.id === inquiry.title
      );
      if (targetInquiry.id === exceptTitleId) return inquiry.other_title;
      return targetInquiry.value;
    },
  },
};
</script>
