export default {
  data() {
    return {
      itemsPerPageKeyName: `${this.$route.name}.${this.$options.name}.ItemsPerPage`,
      defaultItemsPerPage: 10,
    };
  },
  computed: {
    itemsPerPage() {
      return this.$cookies.isKey(this.itemsPerPageKeyName)
        ? Number(this.$cookies.get(this.itemsPerPageKeyName))
        : this.defaultItemsPerPage;
    },
  },
  methods: {
    updateItemsPerPage(value) {
      this.$cookies.set(this.itemsPerPageKeyName, value);
    },
  },
};
