<template>
  <v-container>
    <h1>案件新規作成</h1>

    <v-divider />

    <v-row v-if="projectLoading && skillLoading">
      <v-col cols="12" align="center" style="height: 70vh">
        <div class="d-flex justify-center align-center" style="height: 100%">
          <v-progress-circular
            :width="3"
            size="32"
            color="primary"
            indeterminate
          />
        </div>
      </v-col>
    </v-row>

    <v-card v-else outlined elevation="0" class="mt-5 px-5 pt-2 pb-5">
      <ProjectOpenDetail
        v-model="project"
        @updateBtnDisable="btnDisable = $event"
        @updateIsValidDatetime="isValidDatetime = $event"
        :btnDisable="btnDisable"
        :isValidDatetime="isValidDatetime"
      />
      <ProjectSkillDetail
        v-model="selectSkills"
        :skills="skills"
        :project="project"
        :skillLoading="skillLoading"
        :projectLoading="projectLoading"
      />
    </v-card>

    <v-divider />

    <v-container class="d-flex ml-3 mt-5 pt-2 pb-5">
      <v-spacer />
      <v-btn
        class="mr-3"
        elevation="0"
        text
        color="primary"
        @click="$router.push({ name: 'Projects' })"
      >
        キャンセル
      </v-btn>
      <v-btn elevation="0" color="primary" @click="setModal('create')">
        新規作成
      </v-btn>
    </v-container>

    <Confirm
      ref="child"
      :type="modal.type"
      :dialog="modal.show"
      :title="modal.title"
      :text="modal.text"
      :color="modal.color"
      :yesBtn="modal.yesBtn"
      @commit="commitAction"
      @close="closeAction(modal.type)"
    />
  </v-container>
</template>

<script>
import session from '../plugins/session';
import ProjectOpenDetail from '../components/pc/ProjectOpenDetail';
import Confirm from '../components/pc/modals/Confirm';
import ProjectSkillDetail from '../components/pc/ProjectSkillDetail.vue';

export default {
  name: 'ProjectsAdd',
  components: {
    ProjectOpenDetail,
    Confirm,
    ProjectSkillDetail,
  },
  data() {
    return {
      project: {
        cost: null,
        new_project_finish_at: null,
        description: null,
        day_start_at: '0900',
        day_finish_at: '1800',
        employment_status: 0,
        industry: null,
        interview_first_time_format: 'オンライン',
        interview_second_time_format: null,
        minimum_work_day: 5,
        municipality: null,
        note: null,
        name: null,
        payment_day: 30,
        people: null,
        prefecture: 12,
        public_project: false,
        recruitment_start_at: null,
        recruitment_finish_at: null,
        start_work_at: null,
        station: null,
        work_location: null,
        work_time_start: 140,
        work_time_finish: 180,
        require_skill: null,
        project_skills: [],
      },
      skills: [],
      selectSkills: [],
      projectLoading: true,
      skillLoading: true,
      modal: {
        type: null,
        show: false,
        title: null,
        text: null,
        color: null,
        yesBtn: null,
      },
      btnDisable: false,
      isValidDatetime: false,
      isValidSkills: true,
    };
  },
  created() {
    if (this.$route.query['source'] != null) {
      this.getProject(this.$route.query['source']);
    } else {
      this.projectLoading = false;
    }
    this.getSkills();
  },
  methods: {
    commitAction(type) {
      switch (type) {
        case 'create':
          this.createProject();
          break;
      }
    },

    closeAction(type) {
      this.resetModel();
      switch (type) {
        case 'create':
          break;
      }
    },

    async createProject() {
      if (this.btnDisable || this.isValidDatetime) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        this.resetModel();
        return;
      }
      for (let index in this.project.skills) {
        if (this.project.skills[index].reference_type === null) {
          this.isValidSkills = false;
          break;
        }
      }
      if (!this.isValidSkills) {
        this.$emit('showSnackbar', 'error', 'スキルを正しく入力してください。');
        this.isValidSkills = true;
        this.resetModel();
        return;
      }

      try {
        const result = await this.$axios.post(
          '/api/v1/admin/projects',
          { project: this.project, project_skills: this.selectSkills },
          { headers: session.apiAuthHeaders() }
        );
        this.resetModel();
        if (result.status == 204) {
          this.$emit('showSnackbar', 'success', '保存に成功しました。');
          this.$router.push({ name: 'Projects' });
        }
      } catch (err) {
        this.resetModel();
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 404) {
          this.$emit(
            'showSnackbar',
            'error',
            '指定したパラメータが見つかりません。'
          );
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    resetModel() {
      this.modal.type = null;
      this.modal.title = null;
      this.modal.text = null;
      this.modal.show = false;
      this.$refs.child.finishLoading();
    },

    setModal(type) {
      this.modal.type = type;
      this.modal.show = true;
      switch (type) {
        case 'create':
          this.modal.color = 'primary';
          this.modal.yesBtn = '新規作成';
          this.modal.title = '案件を新規作成しますか?';
          this.modal.text = '作成した案件は非公開設定となります。';
          break;
        default:
          this.modal.type = null;
          this.modal.title = null;
          this.modal.text = null;
          this.modal.show = false;
      }
    },

    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/admin/required_skills', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.skills = result.data;
        this.skillLoading = false;
      } catch (err) {
        this.skillLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getProject(id) {
      try {
        const result = await this.$axios.get('/api/v1/admin/projects/' + id, {
          headers: session.apiAuthHeaders(),
        });
        if (result.status === 200) {
          this.project = JSON.parse(JSON.stringify(result.data));
          this.project.name = this.project.name + ' のコピー';
          this.project.public_project = false;
        }
        this.projectLoading = false;
      } catch (err) {
        this.projectLoading = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit('showSnackbar', 'error', '複製元の案件が見つかりません。');
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
