export default {
  data() {
    return {
      workTimes: [
        { id: 0, value: 100 },
        { id: 1, value: 110 },
        { id: 2, value: 120 },
        { id: 3, value: 130 },
        { id: 4, value: 140 },
        { id: 5, value: 150 },
        { id: 6, value: 160 },
        { id: 7, value: 170 },
        { id: 8, value: 180 },
        { id: 9, value: 190 },
        { id: 10, value: 200 },
        { id: 11, value: 210 },
        { id: 12, value: 220 },
        { id: 13, value: 230 },
        { id: 14, value: 240 },
        { id: 15, value: 250 },
      ],
    };
  },
};
