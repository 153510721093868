<template>
  <v-container>
    <v-responsive max-width="1161" class="mx-auto">
      <v-container class="px-0 d-flex">
        <h2>管理者詳細</h2>
      </v-container>
      <v-divider />
      <v-row v-if="inquiryLoading || entryLoading || adminLoading">
        <v-col cols="12" align="center" style="height: 50vh">
          <div class="d-flex justify-center align-center" style="height: 100%">
            <v-progress-circular
              :width="3"
              size="32"
              color="primary"
              indeterminate
            />
          </div>
        </v-col>
      </v-row>
      <div v-else>
        <div class="pt-4">
          <h3 class="py-4">基本情報</h3>
          <AdminDetail :admin="admin" @getCopyEmail="getCopyEmail" />
        </div>
        <div class="pt-4">
          <h3 class="py-4">担当中の応募</h3>
          <AdminEntries :entries="entries" />
        </div>
        <div class="pt-4">
          <h3 class="py-4">担当中の問い合わせ</h3>
          <AdminInquiries :inquiries="inquiries" />
        </div>
      </div>
    </v-responsive>
  </v-container>
</template>

<script>
import session from '../plugins/session';
import copy from '../plugins/copy';
import AdminDetail from '../components/pc/AdminDetail.vue';
import AdminEntries from '../components/pc/AdminEntries.vue';
import AdminInquiries from '../components/pc/AdminInquiries.vue';

export default {
  name: 'Admin',
  components: {
    AdminDetail,
    AdminEntries,
    AdminInquiries,
  },
  data() {
    return {
      admin: null,
      entries: null,
      inquiries: null,
      adminLoading: true,
      inquiryLoading: true,
      entryLoading: true,
    };
  },
  created() {
    this.getAdmin();
    this.getEntries();
    this.getInquiries();
  },
  methods: {
    async getAdmin() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/admins/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) this.admin = result.data;
        this.adminLoading = false;
      } catch (err) {
        this.adminLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getCopyEmail() {
      copy.textCopy(this.admin.email);
      this.$emit('showSnackbar', 'success', 'コピーしました。');
    },

    async getEntries() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/admins/' + this.$route.params.id + '/entries',
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) this.entries = result.data;
        this.entryLoading = false;
      } catch (err) {
        this.entryLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getInquiries() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/admins/' + this.$route.params.id + '/inquiries',
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) this.inquiries = result.data;
        this.inquiryLoading = false;
      } catch (err) {
        this.inquiryLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },
  },
};
</script>
