export default {
  data() {
    return {
      inquiryTitle: [
        { id: 0, value: '金額面について' },
        { id: 1, value: '開発内容について' },
        { id: 2, value: '応募条件について' },
        { id: 3, value: 'その他' },
      ],
    };
  },
};
