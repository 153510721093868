var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-1",attrs:{"outlined":"","elevation":"0"}},[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.inquiries,"items-per-page":_vm.itemsPerPage,"fixed-header":"","height":"70vh"},on:{"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"pr-4",attrs:{"to":{ name: 'Inquiry', params: { id: item.id } }}},[_vm._v(" "+_vm._s('#' + item.id)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInquiryTitle(item))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4",domProps:{"textContent":_vm._s(_vm.displayUserName(item.user))}})]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pr-4",attrs:{"color":_vm.getInquiryPriority(item.priority).color},domProps:{"textContent":_vm._s(_vm.getInquiryPriority(item.priority).icon)}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_c('v-chip',{attrs:{"color":_vm.getInquiryStatus(item.status).color,"dense":""}},[_vm._v(" "+_vm._s(_vm.getInquiryStatus(item.status).value)+" ")])],1)]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.admin.last_name + item.admin.first_name || '-')+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4",domProps:{"textContent":_vm._s(_vm.displayUpdatedAt(item.updated_at))}})]}}],null,true),model:{value:(_vm.selectedInquiries),callback:function ($$v) {_vm.selectedInquiries=$$v},expression:"selectedInquiries"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }