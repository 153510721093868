<template>
  <div>
    <v-dialog
      :value="skillDialog"
      persistent
      scrollable
      max-width="800"
      @click:outside="close"
    >
      <v-card class="pa-0">
        <v-card-title class="font-weight-bold">
          {{ skill.id ? 'スキル詳細・編集' : '新規作成' }}
          <v-spacer />
          <v-icon v-text="'mdi-close'" @click="close" />
        </v-card-title>
        <v-divider class="pb-4" />
        <v-card-text>
          <div v-if="skill.id">
            <h5>選択中のスキル</h5>
            <v-card outlined flat class="pa-3 mb-5">
              <v-row>
                <v-col cols="2">
                  <h5>【ID】</h5>
                  <div class="pl-3">
                    {{ skill.id }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <h5>【カテゴリ】</h5>
                  <div class="pl-3">
                    {{ displayCategory(skill.category) }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <h5>【スキル名】</h5>
                  <div class="pl-3">
                    {{ skill.name }}
                  </div>
                </v-col>
                <v-col cols="3">
                  <h5>【更新日】</h5>
                  <div class="pl-3">
                    {{ formatDatetime(skill.updated_at) }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-form ref="form" v-model="updateDisable" style="width: 100%">
            <v-row>
              <v-col cols="6">
                <h5>カテゴリ</h5>
                <v-select
                  outlined
                  hide-details="auto"
                  dense
                  label="カテゴリ"
                  menu-props="auto"
                  class="pt-2"
                  :items="skillCategories"
                  :value="skillDeepCopy.category"
                  item-text="value"
                  item-value="id"
                  @change="updateCategory"
                  :rules="rules.category"
                />
              </v-col>
              <v-col cols="6">
                <h5>スキル名</h5>
                <v-text-field
                  placeholder="スキル名"
                  outlined
                  dense
                  hideDetails="auto"
                  class="pt-2"
                  :value="skillDeepCopy.name"
                  :counter="30"
                  @input="updateSkillName"
                  :rules="rules.skillName"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-5 px-5 pt-6">
          <v-row class="px-1">
            <v-col cols="12" align="right" class="pt-1">
              <v-btn
                class="ml-2 font-weight-bold"
                elevation="0"
                outlined
                color="success"
                v-text="'キャンセル'"
                width="110"
                @click="cancel"
              />
              <v-btn
                class="ml-2 font-weight-bold"
                elevation="0"
                color="success"
                v-text="skill.id ? '更新' : '登録'"
                width="110"
                :disabled="!updateDisable"
                @click="setModal(skill.id ? 'update' : 'create', skillDeepCopy)"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Confirm
      ref="confirmChild"
      :type="confirmModal.type"
      :dialog="confirmModal.show"
      :title="confirmModal.title"
      :text="confirmModal.text"
      :color="confirmModal.color"
      :yesBtn="confirmModal.yesBtn"
      @commit="commitAction"
      @close="closeAction(confirmModal.type)"
    />
  </div>
</template>

<script>
import mixinDate from '../../../const/date';
import mixinSkill from '../../../const/skill';
import validate from '../../../plugins/validate';
import Confirm from './Confirm.vue';
export default {
  name: 'SkillModal',
  components: { Confirm },
  mixins: [mixinDate, mixinSkill],
  props: ['skill', 'skillDialog'],
  data() {
    return {
      skillDeepCopy: null,
      updateDisable: false,
      rules: {
        skillName: [value => validate.skillName(value)],
        category: [value => validate.checkExist(value)],
      },
      confirmModal: {
        type: null,
        show: false,
        title: null,
        text: null,
        color: null,
        yesBtn: null,
        functionArg: null,
      },
    };
  },
  watch: {
    skill() {
      const deepCopy = JSON.parse(JSON.stringify(this.skill));
      this.skillDeepCopy = deepCopy;
      if (!deepCopy.id) {
        this.resetValidate();
      }
    },
  },
  methods: {
    resetValidate() {
      this.$refs.form.resetValidation();
    },

    commitAction(type) {
      switch (type) {
        case 'update':
          this.update(this.confirmModal.functionArg);
          break;
      }
    },

    closeAction(type) {
      this.resetModel();
      switch (type) {
        case 'update':
          break;
      }
    },

    resetModel() {
      this.confirmModal.type = null;
      this.confirmModal.title = null;
      this.confirmModal.text = null;
      this.confirmModal.show = false;
      this.confirmModal.functionArg = null;
      this.$refs.confirmChild.finishLoading();
    },

    setModal(type, arg) {
      if (type === 'create') {
        this.update(arg);
      }

      this.confirmModal.type = type;
      this.confirmModal.show = true;
      this.confirmModal.functionArg = arg;
      switch (type) {
        case 'update':
          this.confirmModal.color = 'primary';
          this.confirmModal.yesBtn = '更新';
          this.confirmModal.title = 'スキルを更新しますか?';
          this.confirmModal.text =
            'スキルを更新すると、サイトへ即時反映されます。';
          break;
        default:
          this.confirmModal.type = null;
          this.confirmModal.title = null;
          this.confirmModal.text = null;
          this.confirmModal.show = false;
      }
    },

    close() {
      this.$emit('setSkillModal', false);
    },

    update(skill) {
      if (this.skill.id) {
        // 更新
        this.$emit('updateSkill', skill);
      } else {
        // 新規作成
        this.$emit('createSkill', skill);
      }
      this.resetModel();
      this.close();
    },

    cancel() {
      this.close();
    },

    updateCategory(e) {
      const newValue = {
        ...this.skillDeepCopy,
        category: e,
      };
      this.skillDeepCopy = newValue;
    },

    updateSkillName(e) {
      const newValue = {
        ...this.skillDeepCopy,
        name: e,
      };
      this.skillDeepCopy = newValue;
    },

    displayCategory(eachNum) {
      const targetValue = this.skillCategories.find(
        element => element.id === eachNum
      );
      return targetValue.value;
    },
  },
};
</script>
