<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title>ブックマーク一覧</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-data-table
      show-select
      :loading="loading"
      :headers="headers"
      :items="bookmarkUsers"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'User', params: { id: item.id } }"
          v-text="`#${item.id}`"
        />
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="pr-4">
          {{ displayUserName(item) }}
        </div>
      </template>

      <template v-slot:[`item.birth`]="{ item }">
        <div class="pr-4">
          {{ getAge(item.birth) || '-' }}
        </div>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        <div class="pr-4">
          {{ item.email || '-' }}
        </div>
      </template>

      <template v-slot:[`item.gender`]="{ item }">
        <div class="pr-4">
          {{ getGender(item.gender) }}
        </div>
      </template>

      <template v-slot:[`item.closest_station`]="{ item }">
        <div class="pr-4">
          {{ item.closest_station || '-' }}
        </div>
      </template>

      <template v-slot:[`item.remove`]="{ item }">
        <v-icon
          color="error"
          elevation="1"
          @click="showModal(item.id)"
          v-text="'mdi-delete'"
        />
      </template>
    </v-data-table>

    <Confirm
      ref="confirm"
      :type="modal.type"
      :color="modal.color"
      :dialog="modal.show"
      :title="modal.title"
      :text="modal.text"
      :yesBtn="modal.yesBtn"
      @commit="deleteBookmark(deleteBookmarkId)"
      @close="closeModal"
    />
  </v-card>
</template>

<script>
import session from '../plugins/session';
import mixinGender from '../const/user/genders';
import mixinPagination from '../plugins/pagination';
import dayjs from 'dayjs';
import Confirm from '../components/pc/modals/Confirm';

export default {
  name: 'Bookmarks',
  mixins: [mixinGender, mixinPagination],
  components: {
    Confirm,
  },
  data() {
    return {
      bookmarkUsers: [],
      headers: [
        { text: 'User ID', align: 'center', value: 'id', width: '10%' },
        {
          text: 'ユーザ名',
          align: 'center',
          value: 'name',
          sortable: false,
          width: '25%',
        },
        { text: '年齢', align: 'center', value: 'birth', width: '14%' },
        {
          text: 'メールアドレス',
          align: 'center',
          value: 'email',
          sortable: false,
          width: '17%',
        },
        {
          text: '性別',
          align: 'center',
          value: 'gender',
          width: '14%',
        },
        {
          text: '最寄り駅',
          align: 'center',
          value: 'closest_station',
          width: '14%',
        },
        {
          text: '削除',
          align: 'center',
          value: 'remove',
          sortable: false,
          width: '16%',
        },
      ],
      deleteBookmarkId: null,
      modal: {
        type: 'destroy',
        show: false,
        title: 'ブックマークを解除しますか？',
        text: 'ブックマークを解除すると、元に戻すことはできません。',
        color: 'red',
        yesBtn: '解除する',
      },
      loading: true,
    };
  },

  async created() {
    await this.getBookmarks();
    this.loading = false;
  },

  computed: {
    displayUserName() {
      return user => {
        if (user.last_name && user.first_name)
          return user.last_name + ' ' + user.first_name;
        return '-';
      };
    },
  },

  methods: {
    getAge(birthDate) {
      if (birthDate == null) return '-';
      const now = dayjs().format('YYYYMMDD');
      const dayjsParse = dayjs(birthDate).format('YYYYMMDD');
      return Math.trunc((now - dayjsParse) / 10000);
    },

    getGender(genderId) {
      if (genderId == null) return '-';
      const g = this.genders.find(gender => gender.id === genderId);
      return g.value;
    },

    showModal(userId) {
      this.deleteBookmarkId = userId;
      this.modal.show = true;
    },

    closeModal() {
      this.modal.show = false;
    },

    async deleteBookmark(userId) {
      try {
        await this.$axios.delete(`/api/v1/admin/favorites/${userId}`, {
          headers: session.apiAuthHeaders(),
        });
        this.$emit('showSnackbar', 'success', 'ブックマークを解除しました');
        await this.getBookmarks();
        this.closeModal();
        this.$refs.confirm.finishLoading();
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit('showSnackbar', 'error', 'ブックマークが見つかりません');
            this.closeModal();
            this.$refs.confirm.finishLoading();
            this.getBookmarks();
            break;
          default:
            this.$emit('showSnackbar', 'error', 'エラーが発生しました');
            this.closeModal();
            this.$refs.confirm.finishLoading();
            break;
        }
      }
    },

    async getBookmarks() {
      try {
        const { status, data } = await this.$axios.get(
          '/api/v1/admin/favorites',
          {
            headers: session.apiAuthHeaders(),
          }
        );
        if (status === 200) this.bookmarkUsers = data;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },
  },
};
</script>
