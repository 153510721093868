export default {
  data() {
    return {
      skillCategories: [
        { id: 0, value: '言語', color: 'deep-orange lighten-2 white--text' },
        {
          id: 1,
          value: 'フレームワーク',
          color: 'teal lighten-2 white--text',
        },
        {
          id: 2,
          value: 'その他',
          color: 'indigo lighten-2 white--text',
        },
      ],
    };
  },
};
