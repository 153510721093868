<template>
  <v-dialog
    :value="dialog"
    persistent
    width="80%"
    max-width="1400"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="font-weight-bold">
        応募状況
        <v-spacer />
        <v-icon v-text="'mdi-close'" @click="close" />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="false"
          v-model="selectedEntries"
          :headers="headers"
          :items="entries"
          :items-per-page="itemsPerPage"
          @update:items-per-page="updateItemsPerPage"
          fixed-header
          height="70vh"
        >
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              class="pr-4"
              :to="{ name: 'Entry', params: { id: item.id } }"
            >
              {{ '#' + item.id }}
            </router-link>
          </template>

          <template v-slot:[`item.project`]="{ item }">
            <router-link
              :to="{ name: 'Project', params: { id: item.project.id } }"
            >
              {{ item.project.name }}
            </router-link>
          </template>

          <template v-slot:[`item.user`]="{ item }">
            <router-link :to="{ name: 'User', params: { id: item.user.id } }">
              {{ item.user.last_name + item.user.first_name }}
            </router-link>
          </template>

          <template v-slot:[`item.internal_interview`]="{ item }">
            <div class="pr-4" v-text="item.internal_interview ? 'あり' : '-'" />
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <div class="pr-4">
              <v-chip :color="getStatus(item.status).color" dense>
                {{ getStatus(item.status).value }}
              </v-chip>
            </div>
          </template>

          <template v-slot:[`item.priority`]="{ item }">
            <v-icon
              class="pr-4"
              :color="getPriority(item.priority).color"
              v-text="getPriority(item.priority).value"
            />
          </template>

          <template v-slot:[`item.evaluation`]="{ item }">
            <div class="pr-4">
              <v-chip :color="getEvaluation(item.evaluation).color" dense>
                {{ getEvaluation(item.evaluation).value }}
              </v-chip>
            </div>
          </template>

          <template v-slot:[`item.admin`]="{ item }">
            {{ item.admin.last_name + item.admin.first_name || '-' }}
          </template>

          <template v-slot:[`item.updated_at`]="{ item }">
            <div class="pr-4" v-text="displayUpdatedAt(item.updated_at)" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinDate from '../../../const/date';
import mixinEvaluation from '../../../const/entry/evaluation';
import mixinStatus from '../../../const/entry/status';
import mixinPriority from '../../../const/entry/priority';
import mixinPagination from '../../../plugins/pagination';

export default {
  name: 'ProjectStatus',
  mixins: [
    mixinDate,
    mixinEvaluation,
    mixinStatus,
    mixinPriority,
    mixinPagination,
  ],
  props: ['entries', 'dialog'],
  data() {
    return {
      headers: [
        { text: 'Entry ID', align: 'center', value: 'id', width: '9%' },
        {
          text: '応募者',
          align: 'center',
          value: 'user',
          sortable: false,
          width: '21%',
        },
        {
          text: '事前面談',
          align: 'center',
          value: 'internal_interview',
          width: '10%',
        },
        { text: '優先度', align: 'center', value: 'priority', width: '9%' },
        { text: '進行状況', align: 'center', value: 'status', width: '13%' },
        {
          text: '担当者',
          align: 'center',
          value: 'admin',
          sortable: false,
          width: '21%',
        },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '15%',
        },
      ],
      selectedEntries: [],
    };
  },
  computed: {
    displayUpdatedAt() {
      return updatedAt => {
        return this.formatDatetime(updatedAt);
      };
    },
  },
  methods: {
    close() {
      this.$emit('setEntryStatus', false);
    },

    getPriority(eachNum) {
      const targetValue = this.entryPriority.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getStatus(eachNum) {
      const targetValue = this.entryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getEvaluation(eachNum) {
      const targetValue = this.entryEvaluation.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },
  },
};
</script>
