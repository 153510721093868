<template>
  <div>
    <v-dialog
      :value="dialog"
      persistent
      scrollable
      width="80%"
      max-width="1400"
      @click:outside="close"
    >
      <v-card class="pa-0">
        <v-card-title class="font-weight-bold">
          ユーザー備考
          <v-spacer />
          <v-icon v-text="'mdi-close'" @click="close" />
        </v-card-title>
        <v-divider class="pb-4" />
        <v-card-text>
          <v-card
            outlined
            elevation="1"
            color="#fff9f9"
            class="px-5 py-3 mb-3"
            v-for="(remark, index) in userRemarks"
            :key="index"
          >
            <v-form v-model="updateTextValid" style="width: 100%">
              <v-row>
                <v-col align="left" class="pb-1">
                  <v-textarea
                    v-if="remark.editFlag"
                    dense
                    outlined
                    auto-grow
                    class="font-weight-bold"
                    hideDetails="auto"
                    style="background-color: white"
                    :value="updateText"
                    @input="updateRemarkText($event, remark.id)"
                    :rules="rules.userRemark"
                    :counter="150"
                  />
                  <div
                    v-else
                    class="font-weight-bold"
                    style="white-space: pre-line"
                    v-html="remark.text"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col align="right" class="mt-1">
                <div v-if="remark.editFlag">
                  <v-row>
                    <v-col>
                      <v-btn
                        outlined
                        class="ml-2 font-weight-bold"
                        elevation="0"
                        color="success"
                        v-text="'キャンセル'"
                        width="110"
                        @click="editCancel(remark)"
                      />
                      <v-btn
                        class="ml-2 font-weight-bold"
                        elevation="0"
                        color="success"
                        width="110"
                        :loading="updateLoading"
                        :disabled="!updateTextValid"
                        @click="updateRemark(remark)"
                      >
                        更新
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <span>{{ formatDatetime(remark.updated_at) }}</span>
                  <span
                    :class="
                      admin.id === remark.admin_id ? 'pl-4' : 'pl-4 pr-13'
                    "
                    style="margin-top: 20px"
                  >
                    {{ remark.admin_name }} 様 がコメント
                  </span>
                  <span class="pl-2" v-if="admin.id === remark.admin_id">
                    <v-icon
                      v-text="'mdi-pencil'"
                      size="16"
                      color="primary"
                      @click="changeEdit(remark, true)"
                    />
                    <v-icon
                      v-text="'mdi-delete'"
                      size="16"
                      color="error"
                      class="ml-2"
                      @click="setModal('delete', remark.id)"
                    />
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-actions class="pb-5 px-5 pt-6">
          <v-form v-model="createTextValid" style="width: 100%">
            <v-row class="px-1">
              <v-col cols="12">
                <h3>コメントを登録</h3>
                <div class="pt-1">
                  <v-textarea
                    outlined
                    dense
                    hideDetails="auto"
                    class="mt-2"
                    auto-grow
                    style="background-color: white"
                    v-model="createText"
                    :rules="rules.userRemark"
                    :counter="150"
                  />
                </div>
              </v-col>
              <v-col cols="12" align="right" class="pt-1">
                <v-btn
                  class="ml-2 font-weight-bold"
                  elevation="0"
                  color="success"
                  width="110"
                  :loading="createLoading"
                  :disabled="!createTextValid"
                  @click="createRemark"
                >
                  登録
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Confirm
      ref="child"
      :type="confirmModal.type"
      :dialog="confirmModal.show"
      :title="confirmModal.title"
      :text="confirmModal.text"
      :color="confirmModal.color"
      :yesBtn="confirmModal.yesBtn"
      @commit="commitAction"
      @close="closeAction(confirmModal.type)"
    />
  </div>
</template>

<script>
import mixinDate from '../../../const/date';
import validate from '../../../plugins/validate';
import Confirm from './Confirm.vue';

export default {
  name: 'UserRemarkModal',
  components: { Confirm },
  mixins: [mixinDate],
  props: ['user', 'admin', 'dialog', 'createLoading', 'updateLoading'],
  data() {
    return {
      editFlag: false,
      userDeepCopy: null,
      createText: null,
      updateText: null,
      createTextValid: false,
      updateTextValid: false,
      confirmModal: {
        type: null,
        show: false,
        title: null,
        text: null,
        color: null,
        yesBtn: null,
        functionArg: null,
      },
      rules: {
        userRemark: [value => validate.userRemark(value)],
      },
    };
  },
  watch: {
    user() {
      const deepCopy = JSON.parse(JSON.stringify(this.user));
      const userRemark = deepCopy?.user_remarks.map(remark => {
        return {
          ...remark,
          editFlag: false,
        };
      });
      this.userDeepCopy = {
        ...deepCopy,
        user_remarks: userRemark,
      };
    },
  },
  computed: {
    displayUpdatedAt() {
      return updatedAt => {
        return this.formatDatetime(updatedAt);
      };
    },
    
    userRemarks() {
      return this.userDeepCopy?.user_remarks ? this.userDeepCopy?.user_remarks : [];
    }
  },
  methods: {
    commitAction(type) {
      switch (type) {
        case 'delete':
          this.deleteRemarkText(this.confirmModal.functionArg);
          break;
      }
    },

    closeAction(type) {
      this.resetModel();
      switch (type) {
        case 'delete':
          break;
      }
    },

    resetModel() {
      this.confirmModal.type = null;
      this.confirmModal.title = null;
      this.confirmModal.text = null;
      this.confirmModal.show = false;
      this.confirmModal.functionArg = null;
      this.$refs.child.finishLoading();
    },

    setModal(type, arg) {
      this.confirmModal.type = type;
      this.confirmModal.show = true;
      this.confirmModal.functionArg = arg;
      switch (type) {
        case 'delete':
          this.confirmModal.color = 'error';
          this.confirmModal.yesBtn = '削除';
          this.confirmModal.title = 'コメントを削除しますか?';
          this.confirmModal.text = '削除したコメントは元に戻せません。';
          break;
        default:
          this.confirmModal.type = null;
          this.confirmModal.title = null;
          this.confirmModal.text = null;
          this.confirmModal.show = false;
      }
    },

    deleteRemarkText(targetId) {
      this.$emit('deleteRemark', targetId);
      this.resetModel();
    },

    close() {
      this.$emit('close', false);
    },

    changeEdit(remark, type) {
      this.editFlag = type;
      const newRemarkValue = this.userDeepCopy.user_remarks.map(element => {
        if (element.id === remark.id) {
          this.updateText = element.text;
          return {
            ...element,
            editFlag: !element.editFlag,
          };
        } else {
          return {
            ...element,
            editFlag: false,
          };
        }
      });
      this.userDeepCopy = {
        ...this.userDeepCopy,
        user_remarks: newRemarkValue,
      };
    },

    editCancel(remark) {
      this.changeEdit(remark, false);
      this.updateText = null;
    },

    createRemark() {
      this.$emit('createRemark', this.createText);
      this.createText = null;
    },

    updateRemark(targetRemark) {
      this.$emit('updateRemark', targetRemark, this.updateText);
    },

    updateRemarkText(event) {
      this.updateText = event;
    },
  },
};
</script>
