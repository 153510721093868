<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <div v-if="isHeader">
      <v-navigation-drawer
        app
        clipped
        v-model="drawer"
        :style="{ background: $vuetify.theme.themes.light.background }"
      >
        <v-list dense nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app outlined elevation="0" clipped-left v-if="isHeader">
        <v-app-bar-nav-icon @click="drawer = !drawer" />
        <!-- divで囲むとwidthが適用される -->
        <!-- inlineとblockの違いだと思うが、よくわからない -->
        <div>
          <v-img
            width="140"
            src="@/assets/logo.png"
            @click="$router.push({ path: '/' }).catch(() => {})"
          />
        </div>
        <v-spacer />
        <v-btn
          text
          v-text="'マイページ'"
          class="mb-1 font-weight-bold"
          elevation="0"
          color="primary"
          @click="$router.push({ name: 'AdminMypage' }).catch(() => {})"
        />
      </v-app-bar>
    </div>

    <v-main>
      <v-snackbar
        v-model="snackbar.show"
        top
        :color="snackbar.color"
        :timeout="3500"
        style="white-space: pre-line"
      >
        {{ snackbar.responseText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.show = false"
            v-text="'閉じる'"
          />
        </template>
      </v-snackbar>
      <router-view @showSnackbar="showSnackbar" />
    </v-main>

    <v-footer app v-if="isFooter">
      <!-- -->
    </v-footer>
  </v-app>
</template>

<style scoped>
/* v-app-barの背景色が上書きされてしまうので、直接指定している */
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
}
</style>

<script>
import session from './plugins/session';

export default {
  name: 'App',
  data() {
    return {
      drawer: true,
      items: [
        { title: 'ユーザ一覧', icon: 'mdi-account-supervisor', path: '/users' },
        { title: '案件一覧', icon: 'mdi-image', path: '/projects' },
        {
          title: '応募一覧',
          icon: 'mdi-badge-account-horizontal',
          path: '/entries',
        },
        {
          title: 'お問合せ一覧',
          icon: 'mdi-card-account-mail',
          path: '/inquiries',
        },
        { title: '管理者一覧', icon: 'mdi-account-cog', path: '/admins' },
        { title: 'ブックマーク一覧', icon: 'mdi-bookmark', path: '/bookmarks' },
        { title: 'スキル一覧', icon: 'mdi-web-box', path: '/skills' },
      ],
      snackbar: {
        show: false,
        color: null,
        responseText: null,
      },
      isLogin: session.isAuthenticated(),
    };
  },
  created() {
    this.routerGuard();
    this.$router.beforeEach((to, from, next) => {
      const requiresAuth = to.meta.requiresAuth;
      this.isLogin = session.isAuthenticated();
      if (requiresAuth && !this.isLogin) {
        next({ name: 'Login' });
      } else if (this.isLogin && from.name === 'Login' && to.name === 'Home') {
        next();
      } else if (['Login', 'RegisterAdmin'].includes(to.name) && this.isLogin) {
        next({ name: 'Home' });
      } else {
        next();
      }
    });
  },
  computed: {
    isHeader() {
      switch (this.$route.name) {
        case 'RegisterAdmin':
          return false;
        case 'Login':
          return false;
        case 'ResetPassword':
          return false;
        case 'UpdatePassword':
          return false;
        case 'ConfirmationEmail':
          return false;
        default:
          return true;
      }
    },

    isFooter() {
      switch (this.$route.name) {
        case 'RegisterAdmin':
          return false;
        case 'Login':
          return false;
        case 'ResetPassword':
          return false;
        case 'UpdatePassword':
          return false;
        case 'ConfirmationEmail':
          return false;
        default:
          return true;
      }
    },
  },
  methods: {
    // route.beforeEachでも同じようなコードがあるが、
    // こちらは直リンク初回アクセス時の対応
    routerGuard() {
      const requiresAuth = this.$route.meta.requiresAuth;
      this.isLogin = session.isAuthenticated();
      if (!this.isLogin && requiresAuth) {
        this.$router.push({ name: 'Login' }).catch(() => {});
      } else if (
        this.isLogin &&
        ['Login', 'RegisterAdmin'].includes(this.$route.name)
      ) {
        this.$router.push({ name: 'Home' }).catch(() => {});
      }
    },

    showSnackbar(color, text) {
      this.snackbar.color = color;
      this.snackbar.responseText = text;
      this.snackbar.show = true;
    },
  },
};
</script>
