export default {
  data() {
    return {
      inquiryStatus: [
        { id: 0, value: '未対応', color: 'red white--text' },
        { id: 1, value: '対応中', color: 'primary white--text' },
        { id: 2, value: '対応済み', color: 'orange white--text' },
      ],
    };
  },
};
