<template>
  <v-container>
    <v-responsive max-width="1161" class="mx-auto">
      <v-container class="px-0 d-flex">
        <v-row>
          <v-col>
            <h2>ユーザー詳細</h2>
          </v-col>

          <v-col align="right">
            <v-btn
              elevation="0"
              outlined
              class="font-weight-bold mr-5"
              color="success"
              width="120"
              @click="userRemarkDialog = true"
            >
              <v-icon color="success" v-text="'mdi-note-edit-outline'" />
              <h4 v-text="'メモ'" />
            </v-btn>
            <v-btn
              elevation="0"
              outlined
              class="font-weight-bold"
              color="primary"
              width="180"
              @click="toggleBookmark"
            >
              <v-icon color="primary" v-text="bookmarkIcon" />
              <h4 v-text="bookmarkText" />
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-row
        v-if="inquiryLoading || entryLoading || userLoading || skillLoading"
      >
        <v-col cols="12" align="center" style="height: 50vh">
          <div class="d-flex justify-center align-center" style="height: 100%">
            <v-progress-circular
              :width="3"
              size="32"
              color="primary"
              indeterminate
            />
          </div>
        </v-col>
      </v-row>
      <div v-else>
        <div v-if="user" class="pt-4">
          <h3 class="py-4">基本情報</h3>
          <UserDetail :user="user" @getCopy="getCopy" />
        </div>
        <div v-if="user && skills" class="pt-4">
          <h3 class="py-4">スキル情報</h3>
          <UserSkill
            :user="user"
            :skills="skills"
            :userSkills="userSkills"
            :skillSheet="skillSheet"
            @download="download"
          />
        </div>
        <div v-if="user && entries" class="pt-4">
          <h3 class="py-4">応募した案件</h3>
          <UserEntries :entries="entries" />
        </div>
        <div v-if="user && inquiries" class="pt-4">
          <h3 class="py-4">お問い合わせ</h3>
          <UserInquiries :inquiries="inquiries" />
        </div>
      </div>
      <UserRemarkModal
        ref="userRemarkModal"
        :user="user"
        :admin="admin"
        :dialog="userRemarkDialog"
        :createLoading="userRemarkLoading.create"
        :updateLoading="userRemarkLoading.update"
        @close="changeUserRemarkDialog"
        @createRemark="createRemark"
        @updateRemark="updateRemark"
        @deleteRemark="deleteRemark"
      />
    </v-responsive>
  </v-container>
</template>

<script>
import session from '../plugins/session';
import copy from '../plugins/copy';
import UserDetail from '../components/pc/UserDetail.vue';
import UserSkill from '../components/pc/UserSkill.vue';
import UserRemarkModal from '../components/pc/modals/UserRemark.vue';
import UserEntries from '../components/pc/UserEntries.vue';
import UserInquiries from '../components/pc/UserInquiries.vue';

export default {
  name: 'User',
  components: {
    UserDetail,
    UserSkill,
    UserRemarkModal,
    UserEntries,
    UserInquiries,
  },
  data() {
    return {
      user: null,
      entries: null,
      inquiries: null,
      skillSheet: null,
      userLoading: true,
      inquiryLoading: true,
      entryLoading: true,
      skillLoading: true,
      userRemarkLoading: {
        create: false,
        update: false,
      },
      bookmarks: null,
      userRemarkDialog: false,
      admin: null,
      skills: [],
      userSkills: {
        languages: [],
        frameworks: [],
        others: [],
      },
    };
  },
  created() {
    this.getUser();
    this.getAdmin();
    this.getSkillSheet();
    this.getEntries();
    this.getInquiries();
    this.getBookmarks();
  },
  computed: {
    bookmarked() {
      if (!this.bookmarks) return false;
      return this.bookmarks.some(
        bookmark => bookmark.id === Number(this.$route.params.id)
      );
    },

    bookmarkIcon() {
      return this.bookmarked ? 'mdi-bookmark' : 'mdi-bookmark-outline';
    },

    bookmarkText() {
      return this.bookmarked ? 'ブックマーク済み' : 'ブックマークする';
    },
  },
  methods: {
    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/admin/required_skills', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.skills = result.data;
        this.skillLoading = false;
      } catch (err) {
        this.skillLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    setSkills() {
      this.userSkills = {
        languages: [],
        frameworks: [],
        others: [],
      };
      this.user.user_skills.forEach(skill => {
        const targetValue = this.skills.find(targetSkill => {
          return skill.required_skill_id === targetSkill.id;
        });
        if (targetValue) {
          switch (targetValue.category) {
            case 0:
              this.userSkills.languages.push(targetValue);
              break;
            case 1:
              this.userSkills.frameworks.push(targetValue);
              break;
            case 2:
              this.userSkills.others.push(targetValue);
              break;
            default:
              break;
          }
        }
      });
    },

    async postBookmark(userId) {
      const favorite = {
        user_id: userId,
      };
      try {
        await this.$axios.post('/api/v1/admin/favorites', favorite, {
          headers: session.apiAuthHeaders(),
        });
        this.$emit('showSnackbar', 'success', 'ブックマークしました');
        await this.getBookmarks();
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 400:
            this.$emit('showSnackbar', 'error', '既にブックマークしています');
            this.getBookmarks();
            break;
          default:
            this.$emit('showSnackbar', 'error', 'エラーが発生しました');
            break;
        }
      }
    },

    async deleteBookmark(userId) {
      try {
        await this.$axios.delete(`/api/v1/admin/favorites/${userId}`, {
          headers: session.apiAuthHeaders(),
        });
        this.$emit('showSnackbar', 'success', 'ブックマークを解除しました');
        await this.getBookmarks();
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit('showSnackbar', 'error', 'ブックマークが見つかりません');
            this.getBookmarks();
            break;
          default:
            this.$emit('showSnackbar', 'error', 'エラーが発生しました');
            break;
        }
      }
    },

    async toggleBookmark() {
      if (this.bookmarked) {
        await this.deleteBookmark(this.$route.params.id);
      } else {
        await this.postBookmark(this.$route.params.id);
      }
    },

    async getBookmarks() {
      try {
        const { status, data } = await this.$axios.get(
          '/api/v1/admin/favorites',
          {
            headers: session.apiAuthHeaders(),
          }
        );
        if (status === 200) this.bookmarks = data;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getUser() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/users/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          this.user = result.data;
          await this.getSkills();
          this.setSkills();
        }
        this.userLoading = false;
      } catch (err) {
        this.userLoading = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getSkillSheet() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/users/' + this.$route.params.id + '/skill_sheets',
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) this.skillSheet = result.data;
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async download() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/users/' +
            this.$route.params.id +
            '/skill_sheets/download',
          {
            headers: session.apiAuthHeaders(),
            responseType: 'blob',
          }
        );
        if (result.status === 200 && result.data) {
          // Generate response data.
          const url = URL.createObjectURL(result.data);
          const a = document.createElement('a');
          a.download = this.skillSheet.filename;
          a.href = url;
          a.click();
          // Remove a tag only use downloading.
          a.remove();
          URL.revokeObjectURL(url);
        }
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit(
            'showSnackbar',
            'error',
            'スキルシートがアップロードされていないユーザーです。'
          );
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          'スキルシートのダウンロードに失敗しました。'
        );
        return;
      }
    },

    getCopy(type) {
      copy.textCopy(this.user[type]);
      this.$emit('showSnackbar', 'success', 'コピーしました。');
    },

    async getEntries() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/users/' + this.$route.params.id + '/entries',
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) this.entries = result.data;
        this.entryLoading = false;
      } catch (err) {
        this.entryLoading = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getInquiries() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/users/' + this.$route.params.id + '/inquiries',
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) this.inquiries = result.data;
        this.inquiryLoading = false;
      } catch (err) {
        this.inquiryLoading = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getAdmin() {
      try {
        const result = await this.$axios.get('/api/v1/admin/admins/mypage', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.admin = result.data;
      } catch (err) {
        if (err.response.status == 401) {
          this.$router.push({ path: '/' });
          return;
        }
      }
    },

    async getUserRemark() {
      try {
        const result = await this.$axios.get(
          `/api/v1/admin/users/${this.$route.params.id}/remarks`,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          const newValue = {
            ...this.user,
            user_remarks: result.data,
          };
          this.user = newValue;
        }
        this.userLoading = false;
      } catch (err) {
        this.userLoading = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async createRemark(newText) {
      if (this.isInValidRemark(newText)) {
        this.$emit('showSnackbar', 'error', 'コメントを入力してください。');
        return;
      }
      try {
        this.setUserRemarkLoading('create', true);
        await this.$axios.post(
          '/api/v1/admin/user_remarks/',
          { user_remark: { user_id: this.user.id, text: newText } },
          { headers: session.apiAuthHeaders() }
        );
        this.getUserRemark();
        this.$emit('showSnackbar', 'success', '登録に成功しました。');
        this.setUserRemarkLoading('create', false);
      } catch (err) {
        this.setUserRemarkLoading('create', false);
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 400:
            this.$emit(
              'showSnackbar',
              'error',
              'ご確認の上、再度実行してください。'
            );
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    async updateRemark(targetRemark, newText) {
      if (this.isInValidRemark(newText)) {
        this.$emit('showSnackbar', 'error', 'コメントを入力してください。');
        return;
      }
      if (targetRemark.admin_id !== this.admin.id) {
        this.$emit(
          'showSnackbar',
          'error',
          '指定したコメントは更新できません。'
        );
        this.$refs.userRemarkModal.editCancel(targetRemark);
        return;
      }
      try {
        this.setUserRemarkLoading('update', true);
        await this.$axios.patch(
          '/api/v1/admin/user_remarks/' + String(targetRemark.id),
          { user_remark: { text: newText } },
          { headers: session.apiAuthHeaders() }
        );
        this.getUserRemark();
        this.$emit('showSnackbar', 'success', '更新に成功しました。');
        this.setUserRemarkLoading('update', false);
      } catch (err) {
        this.setUserRemarkLoading('update', false);
        switch (err.response.status) {
          case 401:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したコメントは更新できません。'
            );
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したコメントが見つかりません。'
            );
            this.$router.push({ name: 'Users' });
            break;
          case 400:
            this.$emit(
              'showSnackbar',
              'error',
              'ご確認の上、再度実行してください。'
            );
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
        this.$refs.userRemarkModal.editCancel(targetRemark);
      }
    },

    async deleteRemark(targetId) {
      try {
        await this.$axios.delete('/api/v1/admin/user_remarks/' + targetId, {
          headers: session.apiAuthHeaders(),
        });
        this.getUserRemark();
        this.$emit('showSnackbar', 'success', '削除に成功しました。');
      } catch (err) {
        switch (err.response.status) {
          case 401:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したコメントは削除できません。'
            );
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したコメントが見つかりません。'
            );
            this.$router.push({ name: 'Users' });
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    isInValidRemark(newText) {
      // 未入力または空白のみの場合は弾く
      if (typeof newText !== 'string') return true;
      const removeWhiteSpace = newText.replace(/[\p{C}\p{Z}]/gu, '');
      return removeWhiteSpace.length === 0 || newText?.length < 1;
    },

    changeUserRemarkDialog(value) {
      this.userRemarkDialog = value;
    },

    setUserRemarkLoading(type, value) {
      if (this.userRemarkLoading[type] !== undefined) {
        this.userRemarkLoading[type] = value;
      }
    },
  },
};
</script>
