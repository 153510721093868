<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-container>
      <v-row>
        <v-col>
          <h5>ID</h5>
          <div class="pt-2" v-text="entry.id" />
        </v-col>
        <v-col>
          <h5>案件名</h5>
          <router-link
            :to="{ name: 'Project', params: { id: entry.project.id } }"
          >
            <div class="pt-2" v-text="entry.project.name" />
          </router-link>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h5>ユーザー名</h5>
          <div class="pt-2">
            <router-link
              :to="{ name: 'User', params: { id: entry.user.id } }"
              v-if="
                this.entry.user.last_name != null ||
                this.entry.user.first_name != null
              "
            >
              {{ getUserName }}
            </router-link>
            <div v-else v-text="getUserName" />
          </div>
        </v-col>
        <v-col>
          <h5>メールアドレス</h5>
          <div class="d-flex">
            <div class="pt-2" v-text="entry.user.email" />
            <v-btn icon class="pt-1" @click="$emit('getCopy', 'email')">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h5>応募日時</h5>
          <div class="pt-2" v-text="getSendTime" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import mixinDate from '../../const/date';

export default {
  name: 'EntryDetail',
  props: ['entry'],
  mixins: [mixinDate],
  computed: {
    getSendTime() {
      return this.formatDatetime(this.entry.created_at);
    },

    getUserName() {
      return this.entry.user.last_name != null ||
        this.entry.user.first_name != null
        ? this.entry.user.last_name + ' ' + this.entry.user.first_name
        : this.inquiry.name;
    },
  },
};
</script>
