var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-1",attrs:{"outlined":"","elevation":"0"}},[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":_vm.entries,"items-per-page":_vm.itemsPerPage,"fixed-header":"","height":"70vh"},on:{"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"pr-4",attrs:{"to":{ name: 'Entry', params: { id: item.id } }},domProps:{"textContent":_vm._s('#' + item.id)}})]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Project', params: { id: item.project.id } }},domProps:{"textContent":_vm._s(item.project.name)}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'User', params: { id: item.user.id } }},domProps:{"textContent":_vm._s(item.user.last_name + item.user.first_name)}})]}},{key:"item.internal_interview",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4",domProps:{"textContent":_vm._s(item.internal_interview ? 'あり' : '-')}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_c('v-chip',{attrs:{"color":_vm.getEntryStatus(item.status).color,"dense":""}},[_vm._v(" "+_vm._s(_vm.getEntryStatus(item.status).value)+" ")])],1)]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pr-4",attrs:{"color":_vm.getEntryPriority(item.priority).color},domProps:{"textContent":_vm._s(_vm.getEntryPriority(item.priority).value)}})]}},{key:"item.evaluation",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getEntryEvaluation(item.evaluation).color,"dense":""}},[_vm._v(" "+_vm._s(_vm.getEntryEvaluation(item.evaluation).value)+" ")])]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.admin.last_name + item.admin.first_name || '-')+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4",domProps:{"textContent":_vm._s(_vm.updatedAtValue(item))}})]}}],null,true),model:{value:(_vm.selectedEntries),callback:function ($$v) {_vm.selectedEntries=$$v},expression:"selectedEntries"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }