<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-stepper v-model="stepperPage" elevation="0">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-responsive class="mx-auto d-flex justify-center align-center">
            <ConfirmationEmailForm v-model="user" @sendEmail="sendEmail" />
          </v-responsive>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-responsive class="mx-auto d-flex justify-center align-center">
            <v-card outlined elevation="0" width="400" class="pt-5">
              <v-container>
                <div class="d-flex justify-center">
                  <div>
                    <v-img width="140" src="@/assets/logo.png" />
                  </div>
                </div>
                <v-card-title class="justify-center font-weight-bold mt-3 px-0">
                  確認メールの送信
                </v-card-title>
                <v-card-text class="mt-2 mb-2">
                  {{ user.email }}宛に確認メールを送信しました。<br />
                  1時間以内にメール内に記載されているURLにアクセスして登録を完了させてください。
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    class="font-weight-bold"
                    color="primary"
                    elevation="0"
                    text
                    @click="$router.push({ path: '/login' })"
                    v-text="'ログインへ'"
                  />
                </v-card-actions>
              </v-container>
            </v-card>
          </v-responsive>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ConfirmationEmailForm from '../components/pc/ConfirmationEmailForm';

export default {
  name: 'ConfirmationEmail',
  components: {
    ConfirmationEmailForm,
  },
  data() {
    return {
      stepperPage: 1,
      user: {
        email: null,
      },
    };
  },
  methods: {
    async sendEmail(isInvalid) {
      if (isInvalid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      try {
        const result = await this.$axios.post(
          '/api/v1/admin/admins/send_confirmation_email',
          this.user,
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (result.status == 200) this.stepperPage = 2;
      } catch (err) {
        if (err.response.status === 401) {
          this.$router.push({ name: 'Login' });
          this.$emit('logout');
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
