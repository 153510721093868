export default {
  data() {
    return {
      entryStatus: [
        // blue darken-1
        { id: 0, value: '応募済み', color: 'blue darken-1 white--text' },
        { id: 1, value: '面談依頼', color: 'blue darken-1 white--text' },
        { id: 2, value: '面談調整', color: 'blue darken-1 white--text' },
        { id: 3, value: '1次面談実施', color: 'blue darken-1 white--text' },
        { id: 4, value: '1次面談結果待ち', color: 'blue darken-1 white--text' },
        { id: 5, value: '2次面談実施', color: 'blue darken-1 white--text' },
        { id: 6, value: '2次面談結果待ち', color: 'blue darken-1 white--text' },
        // green lighten-1
        { id: 7, value: 'オファー', color: 'green lighten-1 white--text' },
        { id: 8, value: '成約', color: 'green lighten-1 white--text' },
        { id: 9, value: '稼働中', color: 'green lighten-1 white--text' },
        { id: 10, value: '再エントリー', color: 'green lighten-1 white--text' },
        // red lighten-1
        { id: 11, value: '面談見送り', color: 'red lighten-1 white--text' },
        { id: 12, value: 'キャンセル', color: 'red lighten-1 white--text' },
        { id: 13, value: '稼働終了', color: 'red lighten-1 white--text' },
      ],
    };
  },
};
