<template>
  <v-card
    outlined
    elevation="0"
    width="400"
    height="565"
    class="pa-10 text-center"
  >
    <div class="d-flex justify-center">
      <div>
        <v-img width="140" src="@/assets/logo.png" />
      </div>
    </div>
    <v-card-title class="justify-center">管理者アカウント登録</v-card-title>
    <div class="mt-2">
      <v-form v-model="btnDisable">
        <v-row>
          <v-col cols="6">
            <v-text-field
              label="姓"
              placeholder="姓"
              outlined
              :value="lastNameInitialValue"
              @input="updateLastName"
              :rules="rules.lastName"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="名"
              placeholder="名"
              outlined
              :value="firstNameInitialValue"
              @input="updateFirstName"
              :rules="rules.firstName"
            />
          </v-col>
        </v-row>
        <v-text-field
          label="メールアドレス"
          placeholder="メールアドレス"
          outlined
          :value="emailInitialValue"
          @input="updateEmail"
          :rules="rules.email"
        />
        <v-text-field
          label="パスワード"
          placeholder="パスワード"
          outlined
          type="password"
          :value="passwordInitialValue"
          @input="updatePassword"
          :rules="rules.password"
        />
        <v-text-field
          label="パスワード確認"
          placeholder="パスワード確認"
          outlined
          type="password"
          :value="passwordConfirmationInitialValue"
          @input="updatePasswordConfirmation"
          :rules="rules.passwordConfirmation"
        />
      </v-form>
    </div>
    <v-card-actions class="mt-1 pa-0 justify-space-between">
      <v-btn
        text
        color="primary"
        @click="$router.push({ path: '/login' })"
        v-text="'キャンセル'"
      />
      <v-btn
        elevation="0"
        color="primary"
        class="white--text"
        @click="create"
        v-text="'新規登録'"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'RegisterForm',
  props: ['value'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.adminEmail(value)],
        firstName: [value => validate.adminName(value, '名')],
        lastName: [value => validate.adminName(value, '姓')],
        password: [value => validate.adminPassword(value)],
        passwordConfirmation: [value => validate.adminPassword(value)],
      },
    };
  },
  computed: {
    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },

    firstNameInitialValue() {
      return this.value == null ? null : this.value.first_name;
    },

    lastNameInitialValue() {
      return this.value == null ? null : this.value.last_name;
    },

    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },

    passwordConfirmationInitialValue() {
      return this.value == null ? null : this.value.password_confirmation;
    },
  },
  methods: {
    create() {
      this.$emit('create', !this.btnDisable);
    },

    updateEmail(e) {
      const newValue = {
        ...this.value,
        email: e,
      };
      this.$emit('input', newValue);
    },

    updateFirstName(e) {
      const newValue = {
        ...this.value,
        first_name: e,
      };
      this.$emit('input', newValue);
    },

    updateLastName(e) {
      const newValue = {
        ...this.value,
        last_name: e,
      };
      this.$emit('input', newValue);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
