<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-stepper v-model="stepperPage" elevation="0">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <UpdatePasswordForm
            v-model="admin"
            @updatePassword="updatePassword"
          />
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-card outlined elevation="0" width="400" class="pt-5">
            <v-container>
              <div class="d-flex justify-center">
                <div>
                  <v-img width="140" src="@/assets/logo.png" />
                </div>
              </div>

              <v-card-title class="justify-center font-weight-bold mt-3"
                >パスワードの再設定</v-card-title
              >

              <v-card-text class="mt-2 mb-2">
                パスワードの再設定に成功しました。
                ログインフォームからログインを完了してください。
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn
                  class="font-weight-bold"
                  color="primary"
                  elevation="0"
                  text
                  @click="$router.push({ path: '/login' })"
                >
                  ログインへ
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import UpdatePasswordForm from '../components/pc/UpdatePasswordForm';
import session from '../plugins/session';

export default {
  name: 'UpdatePassword',
  components: {
    UpdatePasswordForm,
  },
  data() {
    return {
      stepperPage: 1,
      admin: {
        password: null,
        password_confirmation: null,
      },
    };
  },
  created() {
    this.routerGuard();
  },
  methods: {
    routerGuard() {
      if (this.$route.query.reset_password_token != undefined) return;
      this.$router.push({ path: '/' });
    },

    async updatePassword(isInvalid) {
      if (isInvalid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      const hasNoMatchedPassword = !(
        this.admin.password === this.admin.password_confirmation
      );
      if (hasNoMatchedPassword) {
        this.$emit(
          'showSnackbar',
          'error',
          'パスワードとパスワード確認が一致していません。'
        );
        return;
      }
      try {
        const result = await this.$axios.patch('/api/v1/admin_auth/password', {
          admin: {
            password: this.admin.password,
            password_confirmation: this.admin.password_confirmation,
            reset_password_token: this.$route.query.reset_password_token,
          },
          change_password: false,
        });
        if (result.status == 204) {
          this.stepperPage = 2;
          return;
        }
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
