<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-data-table
      :headers="headers"
      :items="inquiryStatusHistories"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.admin_full_name }}
      </template>
      <template v-slot:[`item.before_status`]="{ item }">
        <div class="pr-4">
          <v-chip :color="getStatus(item.before_status).color" dense>
            {{ getStatus(item.before_status).value }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.after_status`]="{ item }">
        <div class="pr-4">
          <v-chip :color="getStatus(item.after_status).color" dense>
            {{ getStatus(item.after_status).value }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ getCreatedAt(item.created_at) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import mixinDate from '../../const/date';
import mixinStatus from '../../const/inquiry/status';
import mixinPagination from '../../plugins/pagination';

export default {
  name: 'InquiryStatusHistory',
  props: ['inquiryStatusHistories'],
  mixins: [mixinDate, mixinStatus, mixinPagination],
  data() {
    return {
      headers: [
        {
          text: '管理者',
          align: 'center',
          value: 'admin_full_name',
          sortable: false,
          width: '25%',
        },
        {
          text: '変更前ステータス',
          align: 'center',
          value: 'before_status',
          sortable: false,
          width: '25%',
        },
        {
          text: '変更後ステータス',
          align: 'center',
          value: 'after_status',
          sortable: false,
          width: '25%',
        },
        {
          text: '更新日時',
          align: 'center',
          value: 'created_at',
          sortable: true,
          width: '25%',
        },
      ],
    };
  },
  computed: {
    getCreatedAt() {
      return createdAt => {
        return this.formatDatetime(createdAt);
      };
    },
  },
  methods: {
    getStatus(eachNum) {
      const targetValue = this.inquiryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },
  },
};
</script>
