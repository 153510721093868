export default {
  data() {
    return {
      industry: [
        { id: 0, value: 'ゲーム' },
        { id: 1, value: '金融' },
        { id: 2, value: '教育' },
        { id: 3, value: '医療' },
        { id: 4, value: 'EC' },
        { id: 5, value: 'ビッグデータ' },
        { id: 6, value: '音楽' },
        { id: 7, value: '広告' },
        { id: 8, value: 'その他' },
      ],
    };
  },
};
