export default {
  data() {
    return {
      inquiryPriority: [
        { id: 0, icon: 'mdi-arrow-down', color: 'gray', value: '低' },
        { id: 1, icon: 'mdi-arrow-right', color: 'primary', value: '中' },
        { id: 2, icon: 'mdi-arrow-up', color: 'red', value: '高' },
      ],
    };
  },
};
