<template>
  <div>
    <v-card outlined elevation="0" class="px-5 pt-3 pb-5">
      <v-container>
        <v-form v-model="btnDisable">
          <v-row>
            <v-col cols="6">
              <h5>姓</h5>
              <v-text-field
                placeholder="姓"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                :value="lastNameInitialValue"
                @input="updateLastName"
                :rules="rules.lastName"
              />
            </v-col>
            <v-col cols="6">
              <h5>名</h5>
              <v-text-field
                placeholder="名"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                :value="firstNameInitialValue"
                @input="updateFirstName"
                :rules="rules.firstName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h5>メールアドレス</h5>
              <v-text-field
                placeholder="メールアドレス"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                :value="emailInitialValue"
                @input="updateEmail"
                :rules="rules.email"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <div class="d-flex mt-3">
      <v-spacer />
      <v-btn
        v-text="'更新'"
        class="mt-2"
        elevation="0"
        color="primary"
        @click="update"
      />
    </div>
  </div>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'AccountMypage',
  props: ['value'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.adminEmail(value)],
        firstName: [value => validate.adminName(value, '名')],
        lastName: [value => validate.adminName(value, '姓')],
      },
    };
  },
  computed: {
    emailInitialValue() {
      return this.value?.email == null ? null : this.value.email;
    },

    lastNameInitialValue() {
      return this.value?.last_name == null ? null : this.value.last_name;
    },

    firstNameInitialValue() {
      return this.value?.first_name == null ? null : this.value.first_name;
    },
  },
  methods: {
    update() {
      this.$emit('update', !this.btnDisable);
    },

    updateEmail(e) {
      const newValue = {
        ...this.value,
        email: e,
      };
      this.$emit('input', newValue);
    },

    updateLastName(e) {
      const newValue = {
        ...this.value,
        last_name: e,
      };
      this.$emit('input', newValue);
    },

    updateFirstName(e) {
      const newValue = {
        ...this.value,
        first_name: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
