<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title>管理者一覧</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-data-table
      show-select
      :loading="loading"
      v-model="selectedAdmins"
      show-expand
      :headers="headers"
      :items="admins"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'Admin', params: { id: item.id } }"
          v-text="'#' + item.id"
        />
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.last_name + item.first_name }}
      </template>

      <template v-slot:[`item.active_entries_count`]="{ item }">
        {{ item.active_entries_count }}
      </template>

      <template v-slot:[`item.active_inquiries_count`]="{ item }">
        {{ item.active_inquiries_count }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0">
          <v-data-table
            hide-default-footer
            group-by="status"
            :headers="expandedHeaders"
            :items="getActiveEntries(item.entries)"
            item-key="id"
            :items-per-page="10"
          >
            <template v-slot:group.header="{ group, headers, isOpen, toggle }">
              <td :colspan="headers.length">
                <v-btn icon small @click="toggle">
                  <v-icon v-if="isOpen">mdi-minus</v-icon>
                  <v-icon v-else>mdi-plus</v-icon>
                </v-btn>
                ステータス: {{ getStatus(group).value }}
              </td>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td></td>
                <td class="text-center">
                  <router-link
                    class="pr-4"
                    :to="{ name: 'Entry', params: { id: item.id } }"
                    v-text="'#' + item.id"
                  />
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ name: 'Project', params: { id: item.project_id } }"
                    v-text="item.project_name"
                  />
                </td>
                <td class="text-center pr-8">
                  {{ item.internal_interview ? 'あり' : '-' }}
                </td>
                <td class="text-center pr-8">
                  <v-icon
                    :color="getPriority(item.priority).color"
                    v-text="getPriority(item.priority).value"
                  />
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ name: 'User', params: { id: item.user_id } }"
                    v-text="item.user_last_name + ' ' + item.user_first_name"
                  />
                </td>
                <td class="text-center">
                  <div class="pr-4" v-text="entryUpdatedAt(item)" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import session from '../plugins/session';
import mixinDate from '../const/date';
import mixinEntryStatus from '../const/entry/status';
import mixinPriorityStatus from '../const/entry/priority';
import mixinInquiryStatus from '../const/inquiry/status';
import mixinPagination from '../plugins/pagination';

export default {
  name: 'Admins',
  mixins: [
    mixinDate,
    mixinEntryStatus,
    mixinPriorityStatus,
    mixinInquiryStatus,
    mixinPagination,
  ],
  data() {
    return {
      admins: [],
      selectedAdmins: [],
      headers: [
        { text: 'Admin ID', align: 'center', value: 'id', width: '20%' },
        {
          text: '管理者名',
          align: 'center',
          value: 'name',
          sortable: false,
          width: '20%',
        },
        {
          text: '担当中の応募件数',
          align: 'center',
          value: 'active_entries_count',
          width: '20%',
        },
        {
          text: '担当中の問い合わせ件数',
          align: 'center',
          value: 'active_inquiries_count',
          width: '20%',
        },
        { text: '', align: 'center', value: 'data-table-expand', width: '20%' },
      ],
      expandedHeaders: [
        { text: '', value: '', sortable: false, width: '10%' },
        { text: '応募ID', align: 'center', value: 'id', width: '15%' },
        {
          text: '案件名',
          align: 'center',
          value: 'project_name',
          sortable: false,
          width: '25%',
        },
        {
          text: '事前面談',
          align: 'center',
          value: 'first_interview',
          width: '10%',
        },
        { text: '優先度', align: 'center', value: 'priority', width: '10%' },
        {
          text: '応募者',
          align: 'center',
          value: 'user',
          sortable: false,
          width: '15%',
        },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '15%',
        },
      ],
      loading: true,
    };
  },
  created() {
    this.getAdmins();
  },
  computed: {
    getActiveEntries() {
      return userEntries => {
        const cancelEntryId = this.entryStatus.find(
          element => element.value === 'キャンセル'
        ).id;
        const sendOffEntryId = this.entryStatus.find(
          element => element.value === '面談見送り'
        ).id;
        const workingEndEntryId = this.entryStatus.find(
          element => element.value === '稼働終了'
        ).id;
        return userEntries.filter(
          entry =>
            ![cancelEntryId, sendOffEntryId, workingEndEntryId].includes(
              entry.status
            )
        );
      };
    },

    getActiveInquiries() {
      return userInquiries => {
        const completedInquiryId = this.inquiryStatus.find(
          element => element.value === '対応済み'
        ).id;
        return userInquiries.filter(
          inquiry => completedInquiryId != inquiry.status
        );
      };
    },

    entryUpdatedAt() {
      return entry => {
        return this.formatDatetime(entry.updated_at);
      };
    },
  },
  methods: {
    async getAdmins() {
      try {
        const result = await this.$axios.get('/api/v1/admin/admins', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.admins = result.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getPriority(eachNum) {
      const targetValue = this.entryPriority.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getStatus(eachNum) {
      const targetValue = this.entryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },
  },
};
</script>
