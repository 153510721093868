<template>
  <div>
    <v-card outlined elevation="0" class="px-5 pt-3 pb-5">
      <v-container>
        <v-form ref="form" v-model="btnDisable">
          <v-row>
            <v-col cols="12">
              <h5>現在のパスワード</h5>
              <v-text-field
                placeholder="現在のパスワード"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                type="password"
                :value="currentPasswordInitialValue"
                @input="updateCurrentPassword"
                :rules="rules.currentPassword"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <h5>新しいパスワード</h5>
              <v-text-field
                placeholder="新しいパスワード"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                type="password"
                :value="passwordInitialValue"
                @input="updatePassword"
                :rules="rules.password"
              />
            </v-col>
            <v-col cols="6">
              <h5>新しいパスワード確認</h5>
              <v-text-field
                placeholder="新しいパスワード確認"
                outlined
                dense
                hideDetails="auto"
                class="pt-2"
                type="password"
                :value="passwordConfirmationInitialValue"
                @input="updatePasswordConfirmation"
                :rules="rules.passwordConfirmation"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <div class="d-flex mt-3">
      <v-spacer />
      <v-btn
        v-text="'更新'"
        class="mt-2"
        elevation="0"
        color="primary"
        @click="update"
      />
    </div>
  </div>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'PasswordMypage',
  props: ['value'],
  data() {
    return {
      btnDisable: false,
      rules: {
        currentPassword: [value => validate.adminPassword(value)],
        password: [value => validate.adminPassword(value)],
        passwordConfirmation: [value => validate.adminPassword(value)],
      },
    };
  },
  computed: {
    currentPasswordInitialValue() {
      return this.value?.current_password == null
        ? null
        : this.value.current_password;
    },

    passwordInitialValue() {
      return this.value?.password == null ? null : this.value.password;
    },

    passwordConfirmationInitialValue() {
      return this.value?.password_confirmation == null
        ? null
        : this.value.password_confirmation;
    },
  },
  methods: {
    resetValidate() {
      this.$refs.form.resetValidation();
    },

    update() {
      this.$emit('update', !this.btnDisable);
    },

    updateCurrentPassword(e) {
      const newValue = {
        ...this.value,
        current_password: e,
      };
      this.$emit('input', newValue);
    },

    updatePassword(e) {
      const newValue = {
        ...this.value,
        password: e,
      };
      this.$emit('input', newValue);
    },

    updatePasswordConfirmation(e) {
      const newValue = {
        ...this.value,
        password_confirmation: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
