<template>
  <v-card
    outlined
    elevation="0"
    width="400"
    height="400"
    class="pa-10 text-center"
  >
    <v-responsive max-width="340" style="height: 100%" class="mx-auto d-flex">
      <div class="d-flex justify-center">
        <div>
          <v-img width="140" src="@/assets/logo.png" />
        </div>
      </div>
      <v-card-title class="justify-center">登録確認メールの送信</v-card-title>
      <div class="mt-7">
        <v-form v-model="btnDisable">
          <v-text-field
            label="メールアドレス"
            placeholder="メールアドレス"
            outlined
            :value="emailInitialValue"
            @input="updateEmail"
            :rules="rules.email"
            data-cy="confirmation-email-form-email-form"
          />
        </v-form>
      </div>
      <v-card-actions class="mt-3 pa-0 justify-space-between">
        <v-btn
          elevation="0"
          color="primary"
          class="white--text"
          block
          @click="sendEmail"
          data-cy="confirmation-email-form-submit-btn"
        >
          <v-icon
            class="mail-icon-position"
            dark
            size="25"
            v-text="'mdi-email-outline'"
          />
          登録確認メールを送信
        </v-btn>
      </v-card-actions>
      <v-card-actions class="mt-3 pa-0 justify-center">
        <v-btn
          text
          color="primary"
          @click="$router.push({ path: '/login' })"
          v-text="'ログインへ戻る'"
          data-cy="confirmation-email-form-first-to-login-btn"
        />
      </v-card-actions>
    </v-responsive>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'ConfirmationEmailForm',
  props: ['value'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.adminEmail(value)],
      },
    };
  },
  computed: {
    emailInitialValue() {
      return this.value.email != null ? this.value.email : null;
    },
  },
  methods: {
    updateEmail(event_email) {
      const newValue = {
        ...this.value,
        email: event_email,
      };
      this.$emit('input', newValue);
    },

    sendEmail() {
      this.$emit('sendEmail', !this.btnDisable);
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}
</style>
