<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-container>
      <v-form v-model="btnDisable">
        <v-row class="px-3">
          <h5>応募者に対する評価</h5>
        </v-row>
        <v-row>
          <v-col cols="4">
            <h5>事前面談</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="事前面談"
                menu-props="auto"
                :items="interviewItems"
                :value="internalInterviewInitialValue"
                item-text="value"
                item-value="interview"
                @change="updateInterview"
                :rules="rules.internal_interview"
              />
              <v-btn
                style="margin-top: 2px"
                class="ml-2 font-weight-bold"
                elevation="0"
                color="primary"
                @click="update('internal_interview')"
                v-text="'更新'"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <h5>評価</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="評価"
                menu-props="auto"
                :items="entryEvaluation"
                :value="evaluationInitialValue"
                item-text="value"
                item-value="id"
                @change="updateDetail"
                :rules="rules.evaluation"
              />
              <v-btn
                style="margin-top: 2px"
                class="ml-2 font-weight-bold"
                elevation="0"
                color="primary"
                @click="update('evaluation')"
                v-text="'更新'"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <h5>管理</h5>
        </v-row>
        <v-row>
          <v-col cols="4">
            <h5>優先度</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="優先度"
                menu-props="auto"
                :items="entryPriority"
                :value="priorityInitialValue"
                item-text="text"
                item-value="id"
                @change="updatePriority"
                :rules="rules.priority"
              />
              <v-btn
                style="margin-top: 2px"
                class="ml-2 font-weight-bold"
                elevation="0"
                color="primary"
                @click="update('priority')"
                v-text="'更新'"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <h5>進行状況</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="進行状況"
                menu-props="auto"
                :items="entryStatus"
                :value="statusInitialValue"
                item-text="value"
                item-value="id"
                @change="updateStatus"
                :rules="rules.status"
              />
              <v-btn
                style="margin-top: 2px"
                class="ml-2 font-weight-bold"
                elevation="0"
                color="primary"
                @click="update('status')"
                v-text="'更新'"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <h5>担当者</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="担当者"
                menu-props="auto"
                :items="admins"
                item-text="name"
                item-value="id"
                :value="adminInitialValue"
                @change="updateAdmin"
                :rules="rules.admin_id"
              />
              <v-btn
                style="margin-top: 2px"
                class="ml-2 font-weight-bold"
                elevation="0"
                color="primary"
                @click="update('admin_id')"
                v-text="'更新'"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pr-2">
            <h5>備考</h5>
            <v-textarea
              outlined
              dense
              rows="1"
              auto-grow
              hideDetails="auto"
              class="mt-2"
              :value="noteInitialValue"
              @input="updateNote"
              :rules="rules.note"
              :counter="500"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row class="pt-2">
        <v-col cols="10">
          <h5>最終更新日時</h5>
          <div class="pt-2" v-text="updatedTimeValue" />
        </v-col>
        <v-col cols="2" align="right">
          <v-btn
            elevation="0"
            class="mt-2 font-weight-bold"
            color="primary"
            @click="update"
            v-text="'一括更新'"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';
import mixinDate from '../../const/date';
import mixinTitle from '../../const/entry/evaluation';
import mixinPriority from '../../const/entry/priority';
import mixinStatus from '../../const/entry/status';

export default {
  name: 'EntryUpdateDetail',
  props: ['value', 'admins'],
  mixins: [mixinDate, mixinTitle, mixinPriority, mixinStatus],
  data() {
    return {
      interviewItems: [
        { interview: true, value: 'あり' },
        { interview: false, value: 'なし' },
      ],
      btnDisable: false,
      rules: {
        internal_interview: [value => validate.checkBoolean(value)],
        evaluation: [value => validate.checkNumber(value)],
        priority: [value => validate.checkNumber(value)],
        status: [value => validate.checkNumber(value)],
        admin_id: [value => validate.adminId(value)],
        note: [value => validate.entryNote(value)],
      },
    };
  },
  computed: {
    internalInterviewInitialValue() {
      return this.value.internal_interview
        ? this.value.internal_interview
        : false;
    },

    evaluationInitialValue() {
      const result = this.entryEvaluation.find(
        element => element.id === this.value.evaluation
      );
      return result != null ? result.id : null;
    },

    adminInitialValue() {
      return this.value != null ? this.value.admin_id : null;
    },

    priorityInitialValue() {
      const result = this.entryPriority.find(
        element => element.id === this.value.priority
      );
      return result != null ? result.id : null;
    },

    statusInitialValue() {
      const result = this.entryStatus.find(
        element => element.id === this.value.status
      );
      return result != null ? result.id : null;
    },

    updatedTimeValue() {
      return this.formatDatetime(this.value.updated_at);
    },

    noteInitialValue() {
      return this.value.note != null ? this.value.note : null;
    },
  },
  methods: {
    update(column) {
      this.$emit('update', !this.btnDisable, column);
    },

    updateInterview(e) {
      const newValue = {
        ...this.value,
        internal_interview: e,
      };
      this.$emit('input', newValue);
    },

    updateDetail(e) {
      const newValue = {
        ...this.value,
        evaluation: e,
      };
      this.$emit('input', newValue);
    },

    updateAdmin(e) {
      const newValue = {
        ...this.value,
        admin_id: e,
      };
      this.$emit('input', newValue);
    },

    updatePriority(e) {
      const newValue = {
        ...this.value,
        priority: e,
      };
      this.$emit('input', newValue);
    },

    updateStatus(e) {
      const newValue = {
        ...this.value,
        status: e,
      };
      this.$emit('input', newValue);
    },

    updateNote(e) {
      const newValue = {
        ...this.value,
        note: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
