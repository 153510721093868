<template>
  <v-container>
    <v-responsive max-width="1161" class="mx-auto">
      <v-container class="px-0 d-flex">
        <h2>マイページ</h2>
      </v-container>
      <v-divider />
      <v-row v-if="adminLoading">
        <v-col cols="12" align="center" style="height: 50vh">
          <div class="d-flex justify-center align-center" style="height: 100%">
            <v-progress-circular
              :width="3"
              size="32"
              color="primary"
              indeterminate
            />
          </div>
        </v-col>
      </v-row>
      <template v-else>
        <v-container class="mt-4 pa-0">
          <v-row>
            <v-col cols="3" class="py-0" />
            <v-col cols="9" class="py-0">
              <h3 class="pt-3" v-text="sideHeaders[selectedPage].text" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-card outlined elevation="0" class="mx-auto" width="100%">
                <v-list dense>
                  <v-list-item-group
                    :value="selectedPage"
                    @change="changePage"
                    color="primary"
                  >
                    <v-list-item v-for="item in sideHeaders" :key="item.pageId">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                          class="font-weight-bold text--secondary"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="9">
              <!-- sideHeadersプロパティで選択した項目に対応するコンポーネントを表示しています -->
              <component
                ref="child"
                :is="sideHeaders[selectedPage].componentName"
                v-model="admin"
                @update="update"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-responsive>
  </v-container>
</template>

<script>
import session from '../plugins/session';
import AccountMypage from '../components/pc/AccountMypage.vue';
import PasswordMypage from '../components/pc/PasswordMypage.vue';

export default {
  name: 'AdminMypage',
  components: {
    AccountMypage,
    PasswordMypage,
  },
  data() {
    return {
      admin: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        password_confirmation: null,
        current_password: null,
      },
      selectedPage: 0,
      sideHeaders: [
        {
          pageId: 0,
          icon: 'mdi-account',
          text: '基本情報',
          componentName: 'AccountMypage',
        },
        {
          pageId: 1,
          icon: 'mdi-account',
          text: 'パスワード変更',
          componentName: 'PasswordMypage',
        },
      ],
      adminLoading: true,
    };
  },
  created() {
    this.getAdmin();
  },
  methods: {
    changePage(nextPage) {
      // v-list-item-groupで現在のタブ（sideHeadersの基本情報等の項目）を連続で押下した際に
      // nextPageにundefinedが代入されるのを防ぐための条件分岐
      if (Number.isInteger(nextPage)) this.selectedPage = nextPage;
    },

    async getAdmin() {
      try {
        const result = await this.$axios.get('/api/v1/admin/admins/mypage', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) {
          const resultData = {
            ...this.admin,
            id: result.data.id,
            email: result.data.email,
            first_name: result.data.first_name,
            last_name: result.data.last_name,
          };
          this.admin = resultData;
        }
        this.adminLoading = false;
      } catch (err) {
        this.adminLoading = false;
        if (err.response.status == 401) {
          this.$router.push({ path: '/' });
          return;
        }
      }
    },

    update(isInvalid) {
      if (isInvalid) {
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }
      switch (this.selectedPage) {
        case 0:
          this.updateAccount();
          break;
        case 1:
          this.updatePassword();
          break;
        default:
          this.$emit('showSnackbar', 'error', '更新に失敗しました。');
      }
    },

    async updateAccount() {
      if (this.admin.id == null) {
        this.$router.push({ path: '/' });
        return;
      }
      try {
        const result = await this.$axios.patch(
          '/api/v1/admin/admins/' + this.admin.id,
          {
            admin: {
              id: this.admin.id,
              email: this.admin.email,
              first_name: this.admin.first_name,
              last_name: this.admin.last_name,
            },
          },
          { headers: session.apiAuthHeaders() }
        );
        const resultData = {
          ...this.admin,
          id: result.data.id,
          email: result.data.email,
          first_name: result.data.first_name,
          last_name: result.data.last_name,
        };
        this.admin = resultData;
        session.setUidToItemKeys(result.headers.uid);
        this.$emit('showSnackbar', 'success', '更新に成功しました。');
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async updatePassword() {
      const hasNoMatchedPassword = !(
        this.admin.password === this.admin.password_confirmation
      );
      if (hasNoMatchedPassword) {
        this.$emit(
          'showSnackbar',
          'error',
          'パスワードとパスワード確認が一致していません。'
        );
        return;
      }
      const hasMatchedNewPassword =
        this.admin.password === this.admin.current_password;
      if (hasMatchedNewPassword) {
        this.$emit('showSnackbar', 'error', 'パスワードが更新されていません。');
        return;
      }
      try {
        const result = await this.$axios.patch(
          '/api/v1/admin_auth/password',
          {
            admin: {
              password: this.admin.password,
              password_confirmation: this.admin.password_confirmation,
              current_password: this.admin.current_password,
            },
            change_password: true,
          },
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) {
          this.selectedPage = 0;
          this.$emit('showSnackbar', 'success', '更新に成功しました。');
          const resetData = {
            ...this.admin,
            password: null,
            password_confirmation: null,
            current_password: null,
          };
          this.admin = resetData;
          this.$refs.childMypage.resetValidate();
        } else if (result.status == 204) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
        }
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
