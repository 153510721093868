<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-container>
      <v-row>
        <v-col cols="6">
          <h5>姓</h5>
          <v-text-field
            outlined
            dense
            hide-details
            readonly
            :value="lastNameInitialValue"
            class="pt-2"
          />
        </v-col>
        <v-col cols="6">
          <h5>名</h5>
          <v-text-field
            outlined
            dense
            hide-details
            readonly
            :value="firstNameInitialValue"
            class="pt-2"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <h5>メールアドレス</h5>
          <div class="d-flex">
            <div class="pt-4" v-text="emailInitialValue" />
            <v-btn icon class="pt-5" @click="$emit('getCopyEmail')">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'AdminDetail',
  props: ['admin'],
  computed: {
    emailInitialValue() {
      return this.admin == null ? null : this.admin.email;
    },
    lastNameInitialValue() {
      return this.admin == null ? null : this.admin.last_name;
    },
    firstNameInitialValue() {
      return this.admin == null ? null : this.admin.first_name;
    },
  },
};
</script>
