<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <LoginForm
      v-model="user"
      :loading="loading"
      @login="login"
      :isConfirmationEmail="isConfirmationEmail"
    />
  </div>
</template>

<script>
import LoginForm from '../components/pc/LoginForm';
import session from '../plugins/session';

export default {
  name: 'Login',
  components: {
    LoginForm,
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      loading: false,
      isConfirmationEmail: false,
    };
  },
  beforeRouteEnter(_, form, next) {
    // ブラウザバック時に認証メールの判定処理を発火させないように制御
    if (form.name === null) {
      next(vm => vm.authenticationEmailDecision());
    } else {
      next();
    }
  },
  methods: {
    authenticationEmailDecision() {
      if (Object.keys(this.$route.query).length < 1) return;
      if (this.$route.query.account_confirmation_success === 'true') return;
      switch (this.$route.query.type) {
        case 'invalid':
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレスの確認のリクエストに失敗しました。'
          );
          break;
        case 'confirmation_period_expired':
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレスの確認のリクエストの期限が切れています'
          );
          break;
        case 'already_confirmed':
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレス確認のURLが既に使用されています'
          );
          break;
        default:
          this.$emit(
            'showSnackbar',
            'error',
            'メールアドレスの確認のリクエストに失敗しました。'
          );
          break;
      }
    },

    async login(isformValid) {
      if (isformValid) {
        session.removeItemKeys();
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }

      try {
        this.loading = true;
        const result = await this.$axios.post(
          '/api/v1/admin_auth/sign_in',
          this.user,
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (
          result.status == 200 &&
          Object.prototype.hasOwnProperty.call(result.headers, 'access-token')
        ) {
          session.setItemKeys(result.headers);
          this.$emit('showSnackbar', 'success', 'ログインに成功しました。');
          this.$router.push({ path: '/' });
          return;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.isConfirmationEmail = true;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$emit('showSnackbar', 'error', 'ログインに失敗しました。');
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
