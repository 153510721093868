<template>
  <v-card
    outlined
    elevation="0"
    width="400"
    height="cardHeight"
    class="pa-10 text-center"
  >
    <div class="d-flex justify-center">
      <div>
        <v-img width="140" src="@/assets/logo.png" />
      </div>
    </div>
    <v-card-title class="justify-center">ログイン</v-card-title>
    <v-card-subtitle>qualitéA管理者としてログイン</v-card-subtitle>
    <div class="mt-2">
      <v-form v-model="btnDisable">
        <v-text-field
          label="メールアドレス"
          placeholder="メールアドレス"
          outlined
          :value="emailInitialValue"
          @input="updateEmail"
          :rules="rules.email"
        />
        <v-text-field
          label="パスワード"
          placeholder="パスワード"
          outlined
          type="password"
          :value="passwordInitialValue"
          @input="updatePassword"
          :rules="rules.password"
        />
      </v-form>
    </div>
    <v-card-actions class="pa-0 justify-end" v-if="isConfirmationEmail">
      <v-btn
        data-cy="login-form-confirmation-email-link"
        text
        color="primary"
        v-text="'メール認証がお済みでない方はこちら'"
        @click="$router.push({ path: '/confirmation-email' })"
      />
    </v-card-actions>
    <v-card-actions class="mt-3 pa-0 justify-space-between">
      <v-btn
        elevation="0"
        color="primary"
        class="white--text"
        block
        @click="login"
        :loading="loading"
      >
        <v-icon
          class="mail-icon-position"
          dark
          size="25"
          v-text="'mdi-email-outline'"
        />
        ログイン
      </v-btn>
    </v-card-actions>
    <v-card-actions class="mt-3 pa-0 justify-space-between">
      <v-btn
        text
        color="primary"
        @click="$router.push({ path: '/resetpassword' })"
        v-text="'パスワードを忘れた'"
      />
      <v-btn
        text
        color="primary"
        @click="$router.push({ path: '/registeradmin' })"
        v-text="'新規登録へ'"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';

export default {
  name: 'LoginForm',
  props: ['value', 'loading', 'isConfirmationEmail'],
  data() {
    return {
      btnDisable: false,
      rules: {
        email: [value => validate.adminEmail(value)],
        password: [value => validate.adminPassword(value)],
      },
    };
  },
  computed: {
    emailInitialValue() {
      return this.value == null ? null : this.value.email;
    },
    passwordInitialValue() {
      return this.value == null ? null : this.value.password;
    },
    cardHeight() {
      return this.isConfirmationEmail ? 490 : 470;
    },
  },
  methods: {
    updateEmail(event_email) {
      const newValue = {
        ...this.value,
        email: event_email,
      };
      this.$emit('input', newValue);
    },
    updatePassword(event_password) {
      const newValue = {
        ...this.value,
        password: event_password,
      };
      this.$emit('input', newValue);
    },
    login() {
      this.$emit('login', !this.btnDisable);
    },
  },
};
</script>

<style scoped>
.mail-icon-position {
  position: absolute;
  left: -1px;
  bottom: -2px;
}
</style>
