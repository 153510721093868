<template>
  <v-card outlined elevation="0" class="pa-1">
    <v-data-table
      show-select
      v-model="selectedInquiries"
      :headers="headers"
      :items="inquiries"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'Inquiry', params: { id: item.id } }"
          v-text="'#' + item.id"
        />
      </template>

      <!-- TODO: 案件に紐づく問い合わせが復活した際にコメントアウト外す -->
      <!-- <template v-slot:[`item.project`]="{ item }">
        <router-link
          :to="{ name: 'Project', params: { id: item.project.id } }"
        >
          {{ item.project.name }}
        </router-link>
      </template> -->

      <template v-slot:[`item.title`]="{ item }">
        <div class="pr-4" v-text="getInquiryTitle(item)" />
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <router-link
          :to="{ name: 'User', params: { id: item.user.id } }"
          v-text="item.user.last_name + item.user.first_name"
        />
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <v-icon
          class="pr-4"
          v-text="getInquiryPriority(item.priority).icon"
          :color="getInquiryPriority(item.priority).color"
        />
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="pr-4">
          <v-chip :color="getInquiryStatus(item.status).color" dense>
            {{ getInquiryStatus(item.status).value }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.admin`]="{ item }">
        {{ item.admin.last_name + item.admin.first_name || '-' }}
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        <div class="pr-4" v-text="updatedAtValue(item)" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import mixinDate from '../../const/date';
import mixinInquiryStatus from '../../const/inquiry/status';
import mixinInquiryPriority from '../../const/inquiry/priority';
import mixinInquiryTitle from '../../const/inquiry/title';
import mixinPagination from '../../plugins/pagination';

export default {
  name: 'AdminInquiries',
  props: ['inquiries'],
  mixins: [
    mixinDate,
    mixinInquiryStatus,
    mixinInquiryPriority,
    mixinInquiryTitle,
    mixinPagination,
  ],
  data() {
    return {
      headers: [
        { text: 'Inquiry ID', align: 'center', value: 'id', width: '10%' },
        // { text: '案件名', align: 'center', value: 'project' },
        {
          text: '問い合わせ件名',
          align: 'center',
          value: 'title',
          width: '20%',
        },
        {
          text: 'ユーザー',
          align: 'center',
          value: 'user',
          sortable: false,
          width: '14%',
        },
        { text: '優先度', align: 'center', value: 'priority', width: '12%' },
        { text: '進行状況', align: 'center', value: 'status', width: '14%' },
        {
          text: '担当者',
          align: 'center',
          value: 'admin',
          sortable: false,
          width: '15%',
        },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '15%',
        },
      ],
      selectedInquiries: [],
    };
  },
  computed: {
    updatedAtValue() {
      return value => {
        return this.formatDatetime(value.updated_at);
      };
    },
  },
  methods: {
    getInquiryPriority(eachNum) {
      const targetValue = this.inquiryPriority.find(
        element => element.id === eachNum
      );
      return { color: targetValue.color, icon: targetValue.icon };
    },

    getInquiryStatus(eachNum) {
      const targetValue = this.inquiryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getInquiryTitle(inquiry) {
      const exceptTitleId = this.inquiryTitle.find(
        element => element.value === 'その他'
      ).id;
      const targetInquiry = this.inquiryTitle.find(
        element => element.id === inquiry.title
      );
      if (targetInquiry.id === exceptTitleId) return inquiry.other_title;
      return targetInquiry.value;
    },
  },
};
</script>
