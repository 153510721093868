<template>
  <v-container>
    <h4
      class="pb-4"
      v-if="value.id"
      v-text="`案件番号: ${projectNumber(value.id)}`"
    />
    <v-form :value="initialBtnValue" @input="updateBtnDisable">
      <v-row>
        <!-- 案件名 -->
        <v-col cols="12">
          <h4>
            案件名
            <span class="pl-1 required-text">※</span>
          </h4>
          <v-text-field
            outlined
            dense
            hideDetails="auto"
            class="mt-2"
            auto-grow
            :value="nameInitialValue"
            @input="updateName"
            :rules="rules.name"
            :counter="60"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 案件詳細説明 -->
        <v-col cols="12">
          <h4>
            案件詳細説明
            <span class="pl-1 required-text">※</span>
          </h4>
          <v-textarea
            outlined
            dense
            hideDetails="auto"
            class="mt-2"
            auto-grow
            :value="descriptionInitialValue"
            @input="updateDescription"
            :rules="rules.description"
            :counter="1000"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 求められるスキル -->
        <v-col cols="12">
          <h4>
            求められるスキル
            <span class="pl-1 required-text">※</span>
          </h4>
          <v-textarea
            outlined
            dense
            hideDetails="auto"
            class="mt-2"
            auto-grow
            :value="requireSkillInitialValue"
            @input="updateRequireSkill"
            :rules="rules.requireSkill"
            :counter="1000"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 案件備考 -->
        <v-col cols="12">
          <h4>案件備考</h4>
          <v-textarea
            outlined
            dense
            hideDetails="auto"
            class="mt-2"
            auto-grow
            :value="noteInitialValue"
            @input="updateNote"
            :rules="rules.note"
            :counter="1000"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 勤務地 -->
        <v-col cols="12">
          <h4>
            勤務地
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="都道府県"
            menu-props="auto"
            :items="prefectures"
            :value="prefectureInitialValue"
            item-text="value"
            @change="updatePrefecture"
            :rules="rules.prefecture"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="市区町村"
            menu-props="auto"
            :items="municipalities"
            :value="municipalityInitialValue"
            item-text="value"
            @change="updateMunicipality"
            :rules="rules.municipality"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- エリア -->
        <v-col col="12">
          <h4>
            エリア
            <span class="pl-1 required-text">※</span>
          </h4>
          <v-text-field
            outlined
            dense
            hideDetails="auto"
            class="mt-2"
            :value="stationInitialValue"
            @input="updateStation"
            :rules="rules.station"
            :counter="20"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 募集開始日 -->
        <v-col cols="12">
          <h4>
            募集開始日
            <span class="pl-1 required-text">※</span>
            <span class="pl-1 text-body-2 required-text">
              {{ this.validateRecruitment }}
            </span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="年"
            menu-props="auto"
            :items="setYearList('recruitment_start_at')"
            item-text="value"
            :value="recruitmentStartYear"
            @input="updateRecruitmentStartYear"
            :rules="rules.recruitmentStartYear"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="月"
            menu-props="auto"
            :items="months"
            item-text="value"
            :value="recruitmentStartMonth"
            @input="updateRecruitmentStartMonth"
            :rules="rules.recruitmentStartMonth"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="日"
            menu-props="auto"
            :items="setDateList('recruitment_start_at')"
            item-text="value"
            :value="recruitmentStartDate"
            @input="updateRecruitmentStartDate"
            :rules="rules.recruitmentStartDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 募集終了日 -->
        <v-col cols="12">
          <h4>
            募集終了日
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="年"
            menu-props="auto"
            :items="setYearList('recruitment_finish_at')"
            item-text="value"
            :value="recruitmentFinishYear"
            @input="updateRecruitmentFinishYear"
            :rules="rules.recruitmentFinishYear"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="月"
            menu-props="auto"
            :items="months"
            item-text="value"
            :value="recruitmentFinishMonth"
            @input="updateRecruitmentFinishMonth"
            :rules="rules.recruitmentFinishMonth"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="日"
            menu-props="auto"
            :items="setDateList('recruitment_finish_at')"
            item-text="value"
            :value="recruitmentFinishDate"
            @input="updateRecruitmentFinishDate"
            :rules="rules.recruitmentFinishDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 勤務開始日 -->
        <v-col cols="12">
          <h4>
            勤務開始日
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="年"
            menu-props="auto"
            :items="setYearList('start_work_at')"
            item-text="value"
            :value="startWorkYear"
            @input="updateStartWorkYear"
            :rules="rules.startWorkYear"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="月"
            menu-props="auto"
            :items="months"
            item-text="value"
            :value="startWorkMonth"
            @input="updateStartWorkMonth"
            :rules="rules.startWorkMonth"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="日"
            menu-props="auto"
            :items="setDateList('start_work_at')"
            item-text="value"
            :value="startWorkDate"
            @input="updateStartWorkDate"
            :rules="rules.startWorkDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 月間労働時間 -->
        <v-col cols="12">
          <h4>
            月間稼働時間
            <span class="pl-1 required-text">※</span>
            <span class="pl-1 text-body-2 required-text">
              {{ this.validateUptime }}
            </span>
          </h4>
          <v-row class="pt-3">
            <v-col cols="4" class="pr-0">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="月間稼働時間"
                menu-props="auto"
                :items="workTimes"
                item-text="value"
                :value="workTimeStartInitialValue"
                @input="updateWorkTimeStart"
                :rules="rules.workTime"
              />
            </v-col>
            <v-col cols="1" align="center">
              <h4 class="pt-1">〜</h4>
            </v-col>
            <v-col cols="4" class="pl-0">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="月間稼働時間"
                menu-props="auto"
                :items="workTimes"
                item-text="value"
                :value="workTimeFinishInitialValue"
                @input="updateWorkTimeFinish"
                :rules="rules.workTime"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <!-- 1週間の最低稼働日数 -->
        <v-col cols="12">
          <h4>
            1週間の最低稼働日数
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="最低稼働日数"
            menu-props="auto"
            :items="minimumWorkDays"
            item-text="value"
            :value="minimumWorkDayInitialValue"
            @input="updateMinimumWorkDay"
            :rules="rules.minimumWorkDay"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 業務開始時間 -->
        <v-col cols="6">
          <h4>
            業務開始時間
            <span class="pl-1 required-text">※</span>
            <span class="pl-1 text-body-2 required-text">
              {{ this.validateBusinessHours }}
            </span>
          </h4>
          <v-row class="pt-3">
            <v-col cols="6">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="時"
                menu-props="auto"
                :items="hours"
                item-text="value"
                :value="dayStartHourInitialValue"
                @input="updateStartHour"
                :rules="rules.StartHour"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="分"
                menu-props="auto"
                :items="minutes"
                item-text="value"
                :value="dayStartMinutesInitialValue"
                @input="updateStartMinutes"
                :rules="rules.StartMinutes"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6">
          <!-- 業務終了時間 -->
          <h4>業務終了時間</h4>
          <v-row class="pt-3">
            <v-col cols="6">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="時"
                menu-props="auto"
                :items="hours"
                item-text="value"
                :value="dayFinishHourInitialValue"
                @input="updateFinishHour"
                :rules="rules.FinishHour"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="分"
                menu-props="auto"
                :items="minutes"
                item-text="value"
                :value="dayFinishMinutesInitialValue"
                @input="updateFinishMinutes"
                :rules="rules.finishMinutes"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <!-- 募集人数 -->
        <v-col cols="12">
          <h4>
            募集人数
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="募集人数"
            menu-props="auto"
            :items="people"
            item-text="value"
            :value="peopleInitialValue"
            @input="updatePeople"
            :rules="rules.people"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 面談形式 -->
        <v-col cols="4">
          <h4>
            面談1回目
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="8">
          <h4>面談2回目（ 面談が2回実施される場合のみ選択してください。）</h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="面談形式"
            menu-props="auto"
            :items="interviewTypeList"
            item-value="value"
            item-text="text"
            :value="interviewTypeFirstInitialValue"
            @input="updateInterviewFirstTimeFormat"
            :rules="rules.interviewTypeFirst"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="面談形式"
            clearable
            menu-props="auto"
            :items="interviewTypeList"
            item-value="value"
            item-text="text"
            :value="interviewTypeSecondInitialValue"
            @input="updateInterviewSecondTimeFormat"
            :rules="rules.interviewTypeSecond"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 契約形態 -->
        <v-col cols="12">
          <h4>
            契約形態
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="契約形態"
            menu-props="auto"
            :items="employmentStatus"
            item-text="value"
            :value="employmentStatusInitialValue"
            @input="updateEmploymentStatus"
            :rules="rules.employment_status"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 勤務場所 -->
        <v-col cols="12">
          <h4>
            勤務場所
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="勤務場所"
            menu-props="auto"
            :items="workLocations"
            item-text="value"
            :value="workLocationsInitialValue"
            @input="updateWorkLocation"
            :rules="rules.workLocation"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 単価 -->
        <v-col cols="12">
          <h4>
            単価
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0 pb-1">
          <v-text-field
            outlined
            dense
            label="単価"
            suffix="万円"
            :value="costInitialValue"
            @input="updateCost"
            :rules="rules.cost"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 支払日 -->
        <v-col cols="12" class="pt-0">
          <h4>
            支払日
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="支払日"
            menu-props="auto"
            :items="paymentDay"
            item-text="value"
            :value="paymentDayInitialValue"
            @input="updatePaymentDay"
            :rules="rules.paymentDay"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 業界 -->
        <v-col cols="12">
          <h4>
            業界
            <span class="pl-1 required-text">※</span>
          </h4>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="業界"
            menu-props="auto"
            :items="industry"
            item-text="value"
            :value="industryListInitialValue"
            @input="updateIndustry"
            :rules="rules.industry"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- 新着期間終了日 -->
        <v-col cols="12">
          <div class="d-flex">
            <div>
              <h4 v-text="'新着期間終了日'" />
            </div>
            <div>
              <h5 class="pt-1 pl-5" v-text="newProjectFinishMessage" />
            </div>
          </div>
        </v-col>
        <v-col cols="3" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="年"
            menu-props="auto"
            :items="setYearList('new_project_finish_at')"
            item-text="value"
            :value="newProjectSpanYear"
            @input="updateNewProjectSpanYear"
          />
        </v-col>
        <v-col cols="3" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="月"
            menu-props="auto"
            :items="months"
            item-text="value"
            :value="newProjectSpanMonth"
            @input="updateNewProjectSpanMonth"
          />
        </v-col>
        <v-col cols="3" class="pt-0">
          <v-select
            outlined
            hide-details="auto"
            dense
            label="日"
            menu-props="auto"
            :items="setDateList('new_project_finish_at')"
            item-text="value"
            :value="newProjectSpanDate"
            @input="updateNewProjectSpanDate"
          />
        </v-col>
        <v-col cols="3" class="pt-0">
          <v-btn
            elevation="0"
            outlined
            color="primary"
            height="40"
            width="160"
            class="font-weight-bold"
            @click="resetNewProjectSpan"
          >
            選択をクリア
            <v-icon color="primary" v-text="'mdi-close'" small />
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<style scoped>
.required-text {
  color: red;
}
</style>

<script>
import validate from '../../plugins/validate';
import common from '../../plugins/common';
import mixinPrefecture from '../../const/project/prefecture';
import mixinMunicipality from '../../const/project/municipality';
import mixinDate from '../../const/date';
import mixinWorkTime from '../../const/project/workTime';
import mixinMinimumWorkDay from '../../const/project/minimumWorkDay';
import mixinPeople from '../../const/project/people';
import mixinEmploymentStatus from '../../const/project/employmentStatus';
import mixinPaymentDay from '../../const/project/paymentDay';
import mixinIndustry from '../../const/project/industry';
import mixinWorkLocation from '../../const/project/workLocation';
import dayjs from 'dayjs';

export default {
  name: 'ProjectOpenDetail',
  // カスタムコンポーネントでv-model使用時は、propsのキーをvalueにする
  props: ['value', 'btnDisable'],
  mixins: [
    mixinPrefecture,
    mixinMunicipality,
    mixinDate,
    mixinWorkTime,
    mixinMinimumWorkDay,
    mixinPeople,
    mixinEmploymentStatus,
    mixinPaymentDay,
    mixinIndustry,
    mixinWorkLocation,
  ],
  data() {
    return {
      rules: {
        name: [value => validate.projectName(value)],
        requireSkill: [value => validate.projectRequireSkill(value)],
        description: [value => validate.projectDescription(value)],
        prefecture: [value => validate.checkExist(value)],
        municipality: [value => validate.checkExist(value)],
        station: [value => validate.projectStation(value)],
        recruitmentStartYear: [value => validate.checkExist(value)],
        recruitmentStartMonth: [value => validate.checkExist(value)],
        recruitmentStartDate: [value => validate.checkExist(value)],
        recruitmentFinishYear: [value => validate.checkExist(value)],
        recruitmentFinishMonth: [value => validate.checkExist(value)],
        recruitmentFinishDate: [value => validate.checkExist(value)],
        startWorkYear: [value => validate.checkExist(value)],
        startWorkMonth: [value => validate.checkExist(value)],
        startWorkDate: [value => validate.checkExist(value)],
        workTime: [value => validate.checkNumber(value)],
        minimumWorkDay: [value => validate.checkExist(value)],
        StartHour: [value => validate.checkExist(value)],
        StartMinutes: [value => validate.checkExist(value)],
        FinishHour: [value => validate.checkExist(value)],
        finishMinutes: [value => validate.checkExist(value)],
        people: [value => validate.checkNumber(value)],
        interviewTypeFirst: [
          value => validate.projectInterviewType(value, 'first'),
        ],
        interviewTypeSecond: [
          value => validate.projectInterviewType(value, 'second'),
        ],
        employmentStatus: [value => validate.checkNumber(value)],
        workLocation: [value => validate.checkExist(value)],
        cost: [value => validate.projectCost(value)],
        paymentDay: [value => validate.checkNumber(value)],
        industry: [value => validate.checkExist(value)],
        note: [value => validate.projectNote(value)],
      },
      interviewTypeList: [
        { text: 'オンライン', value: 'オンライン' },
        { text: '対面', value: '対面' },
      ],
    };
  },
  created() {
    this.setDefaultValue();
  },
  computed: {
    validateRecruitment() {
      return validate.datetime(
        this.value.recruitment_start_at,
        this.value.recruitment_finish_at,
        'recruitment'
      );
    },

    validateUptime() {
      return validate.datetime(
        this.value.work_time_start,
        this.value.work_time_finish,
        'uptime'
      );
    },

    validateBusinessHours() {
      const startTime = this.value.day_start_at;
      const startHours = parseInt(startTime.substr(0, 2)); // '08' -> 8（数値型）
      const startMinutes = parseInt(startTime.substr(2)); // '01' -> 1（数値型）

      const endTime = this.value.day_finish_at;
      const endHours = parseInt(endTime.substr(0, 2)); // '15' -> 15（数値型）
      const endMinutes = parseInt(endTime.substr(2)); // '30' -> 30（数値型）

      return validate.datetime(
        startHours * 100 + startMinutes, // 801
        endHours * 100 + endMinutes, // 1530
        'businessHours'
      );
    },

    setYearList() {
      return type => {
        let targetDate = null;
        switch (type) {
          case 'new_project_finish_at':
            targetDate = this.value.new_project_finish_at;
            break;
          case 'recruitment_start_at':
            targetDate = this.value.recruitment_start_at;
            break;
          case 'recruitment_finish_at':
            targetDate = this.value.recruitment_finish_at;
            break;
          case 'start_work_at':
            targetDate = this.value.start_work_at;
            break;
          default:
            targetDate = dayjs().format();
            break;
        }
        return this.years(targetDate);
      };
    },

    setDateList() {
      return type => {
        let targetDate = null;
        switch (type) {
          case 'new_project_finish_at':
            targetDate = this.value.new_project_finish_at;
            break;
          case 'recruitment_start_at':
            targetDate = this.value.recruitment_start_at;
            break;
          case 'recruitment_finish_at':
            targetDate = this.value.recruitment_finish_at;
            break;
          case 'start_work_at':
            targetDate = this.value.start_work_at;
            break;
          default:
            targetDate = dayjs().format();
            break;
        }
        const dayjsParse = dayjs(targetDate);
        if (!dayjsParse.isValid()) {
          // 入力されていない場合は28日までのリストを表示
          return this.dates(28);
        }
        return this.dates(dayjsParse.endOf('month').date());
      };
    },

    newProjectFinishMessage() {
      return `※未入力の場合、募集開始日から2週間後の日付が入力されます。`;
    },

    initialBtnValue() {
      return this.btnDisable == null ? null : this.btnDisable;
    },

    nameInitialValue() {
      return this.value == null ? null : this.value.name;
    },

    requireSkillInitialValue() {
      return this.value == null ? null : this.value.require_skill;
    },

    descriptionInitialValue() {
      return this.value == null ? null : this.value.description;
    },

    prefectureInitialValue() {
      const result = this.prefectures.find(
        element => element.id === this.value.prefecture
      );
      return result != null ? result.value : null;
    },

    municipalityInitialValue() {
      const result = this.municipalities.find(
        element => element.id === this.value.municipality
      );
      return result != null ? result.value : null;
    },

    stationInitialValue() {
      return this.value == null ? null : this.value.station;
    },

    newProjectSpanYear() {
      if (this.value.new_project_finish_at == null) return null;

      const dayjsParse = dayjs(this.value.new_project_finish_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.year();
    },

    newProjectSpanMonth() {
      if (this.value.new_project_finish_at == null) return null;

      const dayjsParse = dayjs(this.value.new_project_finish_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.month() + 1;
    },

    newProjectSpanDate() {
      if (this.value.new_project_finish_at == null) return null;

      const dayjsParse = dayjs(this.value.new_project_finish_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.date();
    },

    recruitmentStartYear() {
      if (this.value.recruitment_start_at == null) return null;

      const dayjsParse = dayjs(this.value.recruitment_start_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.year();
    },

    recruitmentStartMonth() {
      if (this.value.recruitment_start_at == null) return null;

      const dayjsParse = dayjs(this.value.recruitment_start_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.month() + 1;
    },

    recruitmentStartDate() {
      if (this.value.recruitment_start_at == null) return null;

      const dayjsParse = dayjs(this.value.recruitment_start_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.date();
    },

    recruitmentFinishYear() {
      if (this.value.recruitment_finish_at == null) return null;

      const dayjsParse = dayjs(this.value.recruitment_finish_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.year();
    },

    recruitmentFinishMonth() {
      if (this.value.recruitment_finish_at == null) return null;

      const dayjsParse = dayjs(this.value.recruitment_finish_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.month() + 1;
    },

    recruitmentFinishDate() {
      if (this.value.recruitment_finish_at == null) return null;

      const dayjsParse = dayjs(this.value.recruitment_finish_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.date();
    },

    startWorkYear() {
      if (this.value.start_work_at == null) return null;

      const dayjsParse = dayjs(this.value.start_work_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.year();
    },

    startWorkMonth() {
      if (this.value.start_work_at == null) return null;

      const dayjsParse = dayjs(this.value.start_work_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.month() + 1;
    },

    startWorkDate() {
      if (this.value.start_work_at == null) return null;

      const dayjsParse = dayjs(this.value.start_work_at);
      if (!dayjsParse.isValid()) return null;

      return dayjsParse.date();
    },

    workTimeStartInitialValue() {
      if (this.value == null) return null;

      if (this.value.work_time_start == null) return null;

      const result = this.workTimes.find(
        element => element.value === parseInt(this.value.work_time_start, 10)
      );
      return result != null ? result.value : null;
    },

    workTimeFinishInitialValue() {
      if (this.value == null) return null;

      if (this.value.work_time_finish == null) return null;

      const result = this.workTimes.find(
        element => element.value === parseInt(this.value.work_time_finish, 10)
      );
      return result != null ? result.value : null;
    },

    minimumWorkDayInitialValue() {
      if (this.value == null) return null;

      if (this.value.minimum_work_day == null) return null;

      const result = this.minimumWorkDays.find(
        element => element.value === this.value.minimum_work_day
      );
      return result != null ? result.value : null;
    },

    interviewTypeFirstInitialValue() {
      if (this.value == null) return null;

      if (this.value.interview_first_time_format == null) return null;

      return this.value.interview_first_time_format != null
        ? this.value.interview_first_time_format
        : null;
    },

    interviewTypeSecondInitialValue() {
      if (this.value == null) return null;

      if (this.value.interview_second_time_format == null) return null;

      return this.value.interview_second_time_format != null
        ? this.value.interview_second_time_format
        : null;
    },

    noteInitialValue() {
      return this.value == null ? null : this.value.note;
    },

    dayStartHourInitialValue() {
      if (this.value == null) return null;

      if (this.value.day_start_at == null) return null;

      const startHour = this.value.day_start_at.slice(0, 2); // 先頭2桁
      const result = this.hours.find(element => element.value === startHour);
      return result != null ? result.value : null;
    },

    dayStartMinutesInitialValue() {
      if (this.value == null) return null;

      if (this.value.day_start_at == null) return null;

      const startMinutes = this.value.day_start_at.slice(2, 4); // 後方2桁
      const result = this.minutes.find(
        element => element.value === startMinutes
      );
      return result != null ? result.value : null;
    },

    dayFinishHourInitialValue() {
      if (this.value == null) return null;

      if (this.value.day_finish_at == null) return null;

      const finishHour = this.value.day_finish_at.slice(0, 2); // 先頭2桁
      const result = this.hours.find(element => element.value === finishHour);
      return result != null ? result.value : null;
    },

    dayFinishMinutesInitialValue() {
      if (this.value == null) return null;

      if (this.value.day_finish_at == null) return null;

      const finishMinutes = this.value.day_finish_at.slice(2, 4); // 後方2桁
      const result = this.minutes.find(
        element => element.value === finishMinutes
      );
      return result != null ? result.value : null;
    },

    peopleInitialValue() {
      if (this.value == null) return null;

      if (this.value.people == null) return null;

      const result = this.people.find(
        element => element.value === this.value.people
      );
      return result != null ? result.value : null;
    },

    employmentStatusInitialValue() {
      if (this.value == null) return null;

      if (this.value.employment_status == null) return null;

      const result = this.employmentStatus.find(
        element => element.id === this.value.employment_status
      );
      return result != null ? result.value : null;
    },

    costInitialValue() {
      return this.value.cost != null ? this.value.cost : null;
    },

    paymentDayInitialValue() {
      if (this.value == null) return null;

      if (this.value.payment_day == null) return null;

      const result = this.paymentDay.find(
        element => element.value === this.value.payment_day
      );
      return result != null ? result.value : null;
    },

    industryListInitialValue() {
      if (this.value == null) return;

      if (this.value.industry == null) return null;

      const result = this.industry.find(
        element => element.id === this.value.industry
      );
      return result != null ? result.value : null;
    },

    workLocationsInitialValue() {
      if (this.value == null) return null;

      if (this.value.work_location == null) return null;

      const result = this.workLocations.find(
        element => element.id === this.value.work_location
      );
      return result != null ? result.value : null;
    },
  },
  // カスタムコンポーネントでv-model使用時は、inputイベントで親へ変更を送信する
  methods: {
    setDefaultValue() {
      if (this.value.industry != null) return;
      const industryDefaultValue = 8;
      const newValue = {
        ...this.value, // スプレッド構文
        industry: industryDefaultValue,
      };
      this.$emit('input', newValue);
    },

    projectNumber(projectId) {
      return common.formatProjectNum(projectId);
    },

    updateBtnDisable(event) {
      this.$emit('updateBtnDisable', !event);
    },

    updateName(event) {
      const newValue = {
        ...this.value,
        name: event,
      };
      this.$emit('input', newValue);
    },

    updateDescription(event) {
      const newValue = {
        ...this.value,
        description: event,
      };
      this.$emit('input', newValue);
    },

    updateRequireSkill(event) {
      const newValue = {
        ...this.value,
        require_skill: event,
      };
      this.$emit('input', newValue);
    },

    updatePrefecture(event) {
      const result = this.prefectures.find(element => element.value === event);
      const newValue = {
        ...this.value,
        prefecture: result != null ? result.id : null,
      };
      this.$emit('input', newValue);
    },

    updateMunicipality(event) {
      const result = this.municipalities.find(
        element => element.value === event
      );
      const newValue = {
        ...this.value,
        municipality: result != null ? result.id : null,
      };
      this.$emit('input', newValue);
    },

    updateStation(event) {
      const newValue = {
        ...this.value,
        station: event,
      };
      this.$emit('input', newValue);
    },

    updateNewProjectSpanYear(event) {
      if (this.value.new_project_finish_at == null) {
        const setValue = {
          ...this.value,
          new_project_finish_at: this.setYearValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.new_project_finish_at);
      const newValue = {
        ...this.value,
        new_project_finish_at: dayjsParse.year(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateNewProjectSpanMonth(event) {
      if (this.value.new_project_finish_at == null) {
        const setValue = {
          ...this.value,
          new_project_finish_at: this.setMonthValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.new_project_finish_at);
      const newValue = {
        ...this.value,
        new_project_finish_at: dayjsParse.month(event - 1).format(), // .month()は0オフセットのため補正
      };
      this.$emit('input', newValue);
    },

    updateNewProjectSpanDate(event) {
      if (this.value.new_project_finish_at == null) {
        const setValue = {
          ...this.value,
          new_project_finish_at: this.setDateValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.new_project_finish_at);
      const newValue = {
        ...this.value,
        new_project_finish_at: dayjsParse.date(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateRecruitmentStartYear(event) {
      if (this.value.recruitment_start_at == null) {
        const setValue = {
          ...this.value,
          recruitment_start_at: this.setYearValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.recruitment_start_at);
      const newValue = {
        ...this.value,
        recruitment_start_at: dayjsParse.year(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateRecruitmentStartMonth(event) {
      if (this.value.recruitment_start_at == null) {
        const setValue = {
          ...this.value,
          recruitment_start_at: this.setMonthValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.recruitment_start_at);
      const newValue = {
        ...this.value,
        recruitment_start_at: dayjsParse.month(event - 1).format(), // .month()は0オフセットのため補正
      };
      this.$emit('input', newValue);
    },

    updateRecruitmentStartDate(event) {
      if (this.value.recruitment_start_at == null) {
        const setValue = {
          ...this.value,
          recruitment_start_at: this.setDateValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.recruitment_start_at);
      const newValue = {
        ...this.value,
        recruitment_start_at: dayjsParse.date(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateRecruitmentFinishYear(event) {
      if (this.value.recruitment_finish_at == null) {
        const setValue = {
          ...this.value,
          recruitment_finish_at: this.setYearValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.recruitment_finish_at);
      const newValue = {
        ...this.value,
        recruitment_finish_at: dayjsParse.year(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateRecruitmentFinishMonth(event) {
      if (this.value.recruitment_finish_at == null) {
        const setValue = {
          ...this.value,
          recruitment_finish_at: this.setMonthValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.recruitment_finish_at);
      const newValue = {
        ...this.value,
        recruitment_finish_at: dayjsParse.month(event - 1).format(), // .month()は0オフセットのため補正
      };
      this.$emit('input', newValue);
    },

    updateRecruitmentFinishDate(event) {
      if (this.value.recruitment_finish_at == null) {
        const setValue = {
          ...this.value,
          recruitment_finish_at: this.setDateValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.recruitment_finish_at);
      const newValue = {
        ...this.value,
        recruitment_finish_at: dayjsParse.date(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateStartWorkYear(event) {
      if (this.value.start_work_at == null) {
        const setValue = {
          ...this.value,
          start_work_at: this.setYearValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.start_work_at);
      const newValue = {
        ...this.value,
        start_work_at: dayjsParse.year(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateStartWorkMonth(event) {
      if (this.value.start_work_at == null) {
        const setValue = {
          ...this.value,
          start_work_at: this.setMonthValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.start_work_at);
      const newValue = {
        ...this.value,
        start_work_at: dayjsParse.month(event - 1).format(), // .month()は0オフセットのため補正
      };
      this.$emit('input', newValue);
    },

    updateStartWorkDate(event) {
      if (this.value.start_work_at == null) {
        const setValue = {
          ...this.value,
          start_work_at: this.setDateValue(event),
        };
        this.$emit('input', setValue);
        return;
      }

      const dayjsParse = dayjs(this.value.start_work_at);
      const newValue = {
        ...this.value,
        start_work_at: dayjsParse.date(event).format(),
      };
      this.$emit('input', newValue);
    },

    updateWorkTimeStart(event) {
      const newValue = {
        ...this.value,
        work_time_start: event,
      };
      this.$emit('input', newValue);
    },

    updateWorkTimeFinish(event) {
      const newValue = {
        ...this.value,
        work_time_finish: event,
      };
      this.$emit('input', newValue);
    },

    updateInterviewFirstTimeFormat(event) {
      const newValue = {
        ...this.value,
        interview_first_time_format: event,
      };
      this.$emit('input', newValue);
    },

    updateInterviewSecondTimeFormat(event) {
      const newValue = {
        ...this.value,
        interview_second_time_format: event,
      };
      this.$emit('input', newValue);
    },

    updateMinimumWorkDay(event) {
      const newValue = {
        ...this.value,
        minimum_work_day: event,
      };
      this.$emit('input', newValue);
    },

    updateNote(event) {
      const newValue = {
        ...this.value,
        note: event,
      };
      this.$emit('input', newValue);
    },

    updateStartHour(event) {
      let newValue = null;
      if (this.value.day_start_at == null) {
        const result = event + '00';
        newValue = {
          ...this.value,
          day_start_at: result,
        };
      } else {
        const result = event + this.value.day_start_at.slice(2, 4); // 後方二桁
        newValue = {
          ...this.value,
          day_start_at: result,
        };
      }

      this.$emit('input', newValue);
    },

    updateStartMinutes(event) {
      let newValue = null;
      if (this.value.day_start_at == null) {
        const result = '09' + event;
        newValue = {
          ...this.value,
          day_start_at: result,
        };
      } else {
        const result = this.value.day_start_at.slice(0, 2) + event; // 前方二桁
        newValue = {
          ...this.value,
          day_start_at: result,
        };
      }

      this.$emit('input', newValue);
    },

    updateFinishHour(event) {
      let newValue = null;
      if (this.value.day_finish_at == null) {
        const result = event + '00';
        newValue = {
          ...this.value,
          day_finish_at: result,
        };
      } else {
        const result = event + this.value.day_finish_at.slice(2, 4); // 後方二桁
        newValue = {
          ...this.value,
          day_finish_at: result,
        };
      }

      this.$emit('input', newValue);
    },

    updateFinishMinutes(event) {
      let newValue = null;
      if (this.value.day_finish_at == null) {
        const result = '09' + event;
        newValue = {
          ...this.value,
          day_finish_at: result,
        };
      } else {
        const result = this.value.day_finish_at.slice(0, 2) + event; // 前方二桁
        newValue = {
          ...this.value,
          day_finish_at: result,
        };
      }

      this.$emit('input', newValue);
    },

    updatePeople(event) {
      const newValue = {
        ...this.value,
        people: event,
      };
      this.$emit('input', newValue);
    },

    updateEmploymentStatus(event) {
      const result = this.employmentStatus.find(
        element => element.value === event
      );
      const newValue = {
        ...this.value,
        employment_status: result.id,
      };
      this.$emit('input', newValue);
    },

    updateCost(event) {
      const newValue = {
        ...this.value,
        cost: event,
      };
      this.$emit('input', newValue);
    },

    updatePaymentDay(event) {
      const newValue = {
        ...this.value,
        payment_day: event,
      };
      this.$emit('input', newValue);
    },

    updateIndustry(event) {
      const result = this.industry.find(element => element.value === event);
      const newValue = {
        ...this.value,
        industry: result.id,
      };
      this.$emit('input', newValue);
    },

    updateWorkLocation(event) {
      const result = this.workLocations.find(
        element => element.value === event
      );
      const newValue = {
        ...this.value,
        work_location: result.id,
      };
      this.$emit('input', newValue);
    },

    setYearValue(event) {
      const now = dayjs().hour(0).minute(0); // 現在時刻が入ってしまうため、0で上書き
      const setDate =
        event > now.year() ? now.year(event).month(0).date(1) : now.year(event);
      return setDate.format();
    },

    setMonthValue(event) {
      const now = dayjs().hour(0).minute(0); // 現在時刻が入ってしまうため、0で上書き
      let setDate = null;
      if (now.month() + 1 > event) {
        setDate = now
          .add(1, 'y')
          .month(event - 1)
          .date(1);
      } else {
        setDate = now.month(event - 1); // .month()は0オフセットのため補正
      }
      return setDate.format();
    },

    setDateValue(event) {
      const now = dayjs().hour(0).minute(0); // 現在時刻が入ってしまうため、0で上書き
      const setDate =
        event < now.date() ? now.add(1, 'M').date(event) : now.date(event);
      return setDate.format();
    },

    resetNewProjectSpan() {
      const newValue = {
        ...this.value,
        new_project_finish_at: null,
      };
      this.$emit('input', newValue);
    },
  },
  // validateRecruitment と validateUptime と validateBusinessHours の結果をwatchして、結果の真偽値をemitする
  watch: {
    validateRecruitment() {
      this.$emit('updateIsValidDatetime', !!this.validateRecruitment);
    },
    validateUptime() {
      this.$emit('updateIsValidDatetime', !!this.validateUptime);
    },
    validateBusinessHours() {
      this.$emit('updateIsValidDatetime', !!this.validateBusinessHours);
    },
  },
};
</script>
