import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import router from './router';

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API_URL;

new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app');
