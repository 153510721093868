const formatProjectNum = num => {
  const zeroPaddingNum = String(num).padStart(5, '0');
  return `IN-${zeroPaddingNum}`;
};

const compare = function (value, targetValue) {
  return value <= targetValue;
};

export default {
  formatProjectNum,
  compare,
};
