var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"outlined":"","elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entryStatusHistories,"items-per-page":_vm.itemsPerPage,"fixed-header":"","height":"70vh"},on:{"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.admin_full_name)+" ")]}},{key:"item.before_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_c('v-chip',{attrs:{"color":_vm.getStatus(item.before_status).color,"dense":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.before_status).value)+" ")])],1)]}},{key:"item.after_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pr-4"},[_c('v-chip',{attrs:{"color":_vm.getStatus(item.after_status).color,"dense":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.after_status).value)+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCreatedAt(item.created_at))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }