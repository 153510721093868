<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title>応募一覧</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-divider />

    <v-data-table
      show-select
      :loading="loading"
      v-model="selectedEntries"
      :headers="headers"
      :items="entries"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'Entry', params: { id: item.id } }"
        >
          {{ '#' + item.id }}
        </router-link>
      </template>

      <template v-slot:[`item.project`]="{ item }">
        <router-link :to="{ name: 'Project', params: { id: item.project.id } }">
          {{ item.project.name }}
        </router-link>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <router-link :to="{ name: 'User', params: { id: item.user.id } }">
          {{ item.user.last_name + item.user.first_name }}
        </router-link>
      </template>

      <template v-slot:[`item.internal_interview`]="{ item }">
        <div class="pr-4" v-text="item.internal_interview ? 'あり' : '-'" />
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="pr-4">
          <v-chip :color="getStatus(item.status).color" dense>
            {{ getStatus(item.status).value }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <v-icon
          class="pr-4"
          :color="getPriority(item.priority).color"
          v-text="getPriority(item.priority).value"
        />
      </template>

      <template v-slot:[`item.evaluation`]="{ item }">
        <div class="pr-4">
          <v-chip :color="getEvaluation(item.evaluation).color" dense>
            {{ getEvaluation(item.evaluation).value }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.admin`]="{ item }">
        {{ item.admin.last_name + item.admin.first_name || '-' }}
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        <div class="pr-4" v-text="displayUpdatedAt(item.updated_at)" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import session from '../plugins/session';
import mixinDate from '../const/date';
import mixinEvaluation from '../const/entry/evaluation';
import mixinStatus from '../const/entry/status';
import mixinPriority from '../const/entry/priority';
import mixinPagination from '../plugins/pagination';

export default {
  name: 'Entries',
  mixins: [
    mixinDate,
    mixinEvaluation,
    mixinStatus,
    mixinPriority,
    mixinPagination,
  ],
  data() {
    return {
      headers: [
        { text: 'Entry ID', align: 'center', value: 'id', width: '9%' },
        {
          text: '案件名',
          align: 'center',
          value: 'project',
          sortable: false,
          width: '20%',
        },
        {
          text: '応募者',
          align: 'center',
          value: 'user',
          sortable: false,
          width: '11%',
        },
        {
          text: '事前面談',
          align: 'center',
          value: 'internal_interview',
          width: '10%',
        },
        { text: '優先度', align: 'center', value: 'priority', width: '9%' },
        { text: '進行状況', align: 'center', value: 'status', width: '13%' },
        {
          text: '担当者',
          align: 'center',
          value: 'admin',
          sortable: false,
          width: '11%',
        },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '15%',
        },
      ],
      entries: [],
      selectedEntries: [],
      loading: true,
    };
  },
  created() {
    this.getEntries();
  },
  computed: {
    displayUpdatedAt() {
      return updatedAt => {
        return this.formatDatetime(updatedAt);
      };
    },
  },
  methods: {
    getEvaluation(eachNum) {
      const targetValue = this.entryEvaluation.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    async getEntries() {
      try {
        const result = await this.$axios.get('/api/v1/admin/entries', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.entries = result.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getPriority(eachNum) {
      const targetValue = this.entryPriority.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getStatus(eachNum) {
      const targetValue = this.entryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },
  },
};
</script>
