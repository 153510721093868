<template>
  <v-container class="mt-2">
    <h4 class="pb-3">関連スキル</h4>
    <div class="pl-2">
      <div v-if="languages.length !== 0">
        <h5>【言語】</h5>
        <v-row class="mb-8 pl-1">
          <v-col
            cols="3"
            class="pb-1"
            v-for="(skill, index) in languages"
            :key="index"
          >
            <v-checkbox
              dense
              hide-details
              :label="skill.name"
              v-model="skill.value"
              @change="changeSelectSkill($event, skill.id)"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="frameworks.length !== 0">
        <h5>【フレームワーク】</h5>
        <v-row class="mb-8 pl-1">
          <v-col
            cols="3"
            class="pb-1"
            v-for="(skill, index) in frameworks"
            :key="index"
          >
            <v-checkbox
              dense
              hide-details
              :label="skill.name"
              v-model="skill.value"
              @change="changeSelectSkill($event, skill.id)"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="other.length !== 0">
        <h5>【その他】</h5>
        <v-row class="mb-4 pl-1">
          <v-col
            cols="3"
            class="pb-1"
            v-for="(skill, index) in other"
            :key="index"
          >
            <v-checkbox
              dense
              hide-details
              :label="skill.name"
              v-model="skill.value"
              @change="changeSelectSkill($event, skill.id)"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'ProjectSkillDetail',
  props: ['value', 'skills', 'project', 'skillLoading', 'projectLoading'],
  data() {
    return {
      languages: [],
      frameworks: [],
      other: [],
      selectSkills: [],
    };
  },
  watch: {
    skillLoading() {
      this.setSkills();
    },

    projectLoading() {
      this.setSkills();
    },
  },
  methods: {
    setSkills() {
      const newSkills = {
        languages: [],
        frameworks: [],
        other: [],
      };
      this.skills.forEach(skill => {
        switch (skill.category) {
          case 0:
            newSkills.languages.push({
              ...skill,
              value: this.isCheckValue(skill.id),
            });
            break;
          case 1:
            newSkills.frameworks.push({
              ...skill,
              value: this.isCheckValue(skill.id),
            });
            break;
          case 2:
            newSkills.other.push({
              ...skill,
              value: this.isCheckValue(skill.id),
            });
            break;
        }
      });
      this.languages = newSkills.languages;
      this.frameworks = newSkills.frameworks;
      this.other = newSkills.other;
      this.$emit('input', this.selectSkills);
    },

    isCheckValue(skillId) {
      if (!skillId || !this.project.project_skills) return false;
      const targetSkill = this.project.project_skills.find(skill => {
        return skill.required_skill_id === skillId;
      });
      if (targetSkill) {
        this.selectSkills.push(skillId);
      }
      return !!targetSkill;
    },

    changeSelectSkill(event, skillId) {
      if (event) {
        this.selectSkills.push(skillId);
      } else {
        const newValue = this.selectSkills.filter(id => id !== skillId);
        this.selectSkills = newValue;
      }
      this.$emit('input', this.selectSkills);
    },
  },
};
</script>
