<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-container>
      <v-row>
        <v-col>
          <h5>ID</h5>
          <div class="pt-2" v-text="user.id" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>氏名</h5>
          <div class="pt-2" v-text="nameInitialValue" />
        </v-col>
        <v-col cols="6">
          <h5>カナ</h5>
          <div class="pt-2" v-text="kanaInitialValue" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>年齢</h5>
          <div class="pt-2" v-text="ageInitialValue" />
        </v-col>
        <v-col cols="6">
          <h5>生年月日</h5>
          <div class="pt-2" v-text="birthInitialValue" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>メールアドレス</h5>
          <div class="d-flex">
            <div class="pt-4" v-text="emailInitialValue" />
            <v-btn icon class="pt-5" @click="$emit('getCopy', 'email')">
              <v-icon v-text="'mdi-content-copy'" />
            </v-btn>
          </div>
        </v-col>
        <v-col cols="6">
          <h5>電話番号</h5>
          <div class="d-flex">
            <div
              :class="user.tel != null ? 'pt-4' : 'pt-4 pl-2'"
              v-text="telInitialValue"
            />
            <v-btn
              v-if="user.tel != null"
              icon
              class="pt-5"
              @click="$emit('getCopy', 'tel')"
            >
              <v-icon v-text="'mdi-content-copy'" />
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>住所（市区町村）</h5>
          <div
            :class="user.live_area != null ? 'pt-4' : 'pt-4 pl-2'"
            v-text="liveAreaInitialValue"
          />
        </v-col>
        <v-col cols="6">
          <h5>最寄り駅</h5>
          <div
            :class="user.closest_station != null ? 'pt-4' : 'pt-4 pl-2'"
            v-text="closestStationInitialValue"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>性別</h5>
          <div
            :class="user.gender != null ? 'pt-4' : 'pt-4 pl-2'"
            v-text="genderInitialValue"
          />
        </v-col>
        <v-col cols="6">
          <h5>国籍</h5>
          <div
            :class="user.nationality != null ? 'pt-4' : 'pt-4 pl-2'"
            v-text="nationalityInitialValue"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>現在の状況</h5>
          <div
            :class="user.current_status != null ? 'pt-4' : 'pt-4 pl-2'"
            v-text="currentStatusInitialValue"
          />
        </v-col>
        <v-col cols="6">
          <h5>希望単価</h5>
          <div
            :class="user.desired_cost != null ? 'pt-4' : 'pt-4 pl-2'"
            v-text="desiredCostInitialValue"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h5>特記事項</h5>
          <div
            :class="user.note != null ? 'pt-4' : 'pt-4 pl-2'"
            v-text="noteInitialValue"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import mixinDate from '../../const/date';
import mixinGender from '../../const/user/genders';
import mixinCurrentStatus from '../../const/user/currentStatuses';

export default {
  name: 'UserDetail',
  props: ['user'],
  mixins: [mixinDate, mixinGender, mixinCurrentStatus],
  computed: {
    nameInitialValue() {
      if (this.user.last_name && this.user.first_name)
        return this.user.last_name + ' ' + this.user.first_name;
      return '-';
    },
    kanaInitialValue() {
      if (this.user.last_name_kana && this.user.first_name_kana)
        return this.user.last_name_kana + ' ' + this.user.first_name_kana;
      return '-';
    },

    emailInitialValue() {
      return this.user == null ? null : this.user.email;
    },

    telInitialValue() {
      return this.user.tel != null ? this.user.tel : '-';
    },

    ageInitialValue() {
      if (!this.user.birth) return '-';
      const now = dayjs().format('YYYYMMDD');
      const dayjsParse = dayjs(this.user.birth).format('YYYYMMDD');
      return Math.trunc((now - dayjsParse) / 10000);
    },

    birthInitialValue() {
      if (this.user.birth) return this.formatDate(this.user.birth);
      return '-';
    },

    liveAreaInitialValue() {
      return this.user.live_area == null ? '-' : this.user.live_area;
    },

    currentStatusInitialValue() {
      if (this.user.current_status == null) {
        return '-';
      } else {
        return this.currentStatuses.find(
          element => element.id === this.user.current_status
        ).value;
      }
    },

    nationalityInitialValue() {
      return this.user.nationality == null ? '-' : this.user.nationality;
    },

    genderInitialValue() {
      if (this.user.gender == null) {
        return '-';
      } else {
        return this.genders.find(element => element.id === this.user.gender)
          .value;
      }
    },

    closestStationInitialValue() {
      return this.user.closest_station == null
        ? '-'
        : this.user.closest_station;
    },

    desiredCostInitialValue() {
      return this.user.desired_cost == null
        ? '-'
        : `${this.user.desired_cost}万円`;
    },

    noteInitialValue() {
      return this.user.note == null ? '-' : this.user.note;
    },
  },
};
</script>
