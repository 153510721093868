<template>
  <div class="d-flex justify-center align-center" style="height: 100%">
    <v-stepper v-model="stepperPage" elevation="0">
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <RegisterForm v-model="admin" @create="create" />
        </v-stepper-content>

        <v-stepper-content step="2" class="pa-0">
          <v-card outlined elevation="0" width="400" class="pt-5">
            <v-container>
              <div class="d-flex justify-center">
                <div>
                  <v-img width="140" src="@/assets/logo.png" />
                </div>
              </div>

              <v-card-title class="justify-center font-weight-bold mt-3"
                >Email確認メールの送信</v-card-title
              >

              <v-card-text class="mt-2 mb-2">
                Email確認メールを送信しました。
                メール内のリンクからアカウントの確認を完了してください。
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn
                  class="font-weight-bold"
                  color="primary"
                  elevation="0"
                  text
                  @click="$router.push({ path: '/login' })"
                >
                  ログインへ
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import session from '../plugins/session';
import RegisterForm from '../components/pc/RegisterForm.vue';

export default {
  name: 'RegisterAdmin',
  components: {
    RegisterForm,
  },
  data() {
    return {
      stepperPage: 1,
      admin: {
        email: null,
        last_name: null,
        first_name: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    async create(isformValid) {
      if (isformValid) {
        session.removeItemKeys();
        this.$emit(
          'showSnackbar',
          'error',
          '入力項目を正しく入力してください。'
        );
        return;
      }

      const hasNoMatchedPassword = !(
        this.admin.password === this.admin.password_confirmation
      );
      if (hasNoMatchedPassword) {
        session.removeItemKeys();
        this.$emit(
          'showSnackbar',
          'error',
          'パスワードとパスワード確認が一致していません。'
        );
        return;
      }

      try {
        const result = await this.$axios.post(
          '/api/v1/admin_auth',
          { admin: this.admin },
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (result.status == 200) {
          this.stepperPage = 2;
          return;
        }
      } catch (err) {
        if (err.response.status == 409) {
          session.removeItemKeys();
          this.$emit(
            'showSnackbar',
            'error',
            '入力したメールアドレスはすでに登録されています。'
          );
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },
  },
};
</script>
