<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-container>
      <v-form v-model="btnDisable">
        <v-row>
          <v-col cols="4">
            <h5>優先度</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="優先度"
                menu-props="auto"
                :items="inquiryPriority"
                :value="priorityInitialValue"
                item-text="value"
                item-value="id"
                @change="updatePriority"
                :rules="rules.priority"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <h5>進行状況</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="進行状況"
                menu-props="auto"
                :items="inquiryStatus"
                item-text="value"
                item-value="id"
                :value="statusInitialValue"
                @change="updateStatus"
                :rules="rules.status"
              />
            </div>
          </v-col>
          <v-col cols="4">
            <h5>担当者</h5>
            <div class="pt-2 d-flex">
              <v-select
                outlined
                hide-details="auto"
                dense
                label="担当者"
                menu-props="auto"
                :items="admins"
                item-text="name"
                item-value="id"
                :value="adminInitialValue"
                @change="updateAdmin"
                :rules="rules.admin_id"
              />
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="10">
          <h5>最終更新日時</h5>
          <div class="pt-2" v-text="updatedTimeValue" />
        </v-col>
        <v-col cols="2">
          <div class="text-right pt-4">
            <v-btn
              class="ml-2"
              elevation="0"
              color="primary"
              @click="update"
              v-text="'更新'"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import validate from '../../plugins/validate';
import mixinDate from '../../const/date';
import mixinTitle from '../../const/inquiry/title';
import mixinPriority from '../../const/inquiry/priority';
import mixinStatus from '../../const/inquiry/status';

export default {
  name: 'InquiryUpdateDetail',
  props: ['value', 'admins'],
  mixins: [mixinDate, mixinTitle, mixinPriority, mixinStatus],
  data() {
    return {
      btnDisable: false,
      rules: {
        priority: [value => validate.checkNumber(value)],
        status: [value => validate.checkNumber(value)],
        admin_id: [value => validate.adminId(value)],
      },
    };
  },
  computed: {
    adminInitialValue() {
      return this.value != null ? this.value.admin_id : null;
    },

    priorityInitialValue() {
      const result = this.inquiryPriority.find(
        element => element.id === this.value.priority
      );
      return result != null ? result.id : null;
    },

    statusInitialValue() {
      const result = this.inquiryStatus.find(
        element => element.id === this.value.status
      );
      return result != null ? result.id : null;
    },

    updatedTimeValue() {
      return this.formatDatetime(this.value.updated_at);
    },
  },
  methods: {
    update() {
      this.$emit('update', !this.btnDisable);
    },

    updateAdmin(e) {
      const newValue = {
        ...this.value,
        admin_id: e,
      };
      this.$emit('input', newValue);
    },

    updatePriority(e) {
      const newValue = {
        ...this.value,
        priority: e,
      };
      this.$emit('input', newValue);
    },

    updateStatus(e) {
      const newValue = {
        ...this.value,
        status: e,
      };
      this.$emit('input', newValue);
    },
  },
};
</script>
