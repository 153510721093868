<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title v-text="'スキル一覧'" />
      <v-spacer />
      <v-btn
        elevation="0"
        color="primary"
        @click="setSkillModal(true, addSkill)"
        v-text="'新規追加'"
      />
    </v-toolbar>

    <v-divider />

    <v-data-table
      show-select
      :loading="loading"
      v-model="selectedSkill"
      :headers="headers"
      :items="skills"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <template v-slot:[`item.category`]="{ item }">
        <v-chip :color="getCategory(item.category).color" dense>
          {{ getCategory(item.category).value }}
        </v-chip>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        {{ displayUpdatedAt(item.updated_at) }}
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <v-btn
          color="amber"
          icon
          class="mr-2"
          @click="setConfirmModal('updateLockFlag', item)"
        >
          <v-icon elevation="1" v-text="displayLockFlagIcon(item)" />
        </v-btn>
        <v-btn
          color="success"
          icon
          class="mx-2"
          v-bind:disabled="item.lock_flag"
          @click="setSkillModal(true, item)"
        >
          <v-icon elevation="1" v-text="'mdi-pencil'" />
        </v-btn>
        <v-btn
          color="error"
          icon
          class="ml-2"
          @click="setConfirmModal('delete', item.id)"
        >
          <v-icon elevation="1" v-text="'mdi-delete'" />
        </v-btn>
      </template>
    </v-data-table>
    <SkillOpenDetail
      :skillDialog="skillDialog"
      :skill="targetSkill"
      @setSkillModal="setSkillModal"
      @createSkill="createSkill"
      @updateSkill="updateSkill"
    />
    <Confirm
      ref="child"
      :type="confirmModal.type"
      :dialog="confirmModal.show"
      :title="confirmModal.title"
      :text="confirmModal.text"
      :color="confirmModal.color"
      :yesBtn="confirmModal.yesBtn"
      @commit="commitAction"
      @close="closeAction(confirmModal.type)"
    />
  </v-card>
</template>

<script>
import session from '../plugins/session';
import mixinDate from '../const/date';
import mixinSkills from '../const/skill';
import mixinPagination from '../plugins/pagination';
import SkillOpenDetail from '../components/pc/modals/SkillOpenDetail.vue';
import Confirm from '../components/pc/modals/Confirm.vue';

export default {
  name: 'Skills',
  components: { SkillOpenDetail, Confirm },
  mixins: [mixinDate, mixinSkills, mixinPagination],
  data() {
    return {
      addSkill: {
        id: null,
        name: null,
        category: null,
        updated_at: null,
        created_at: null,
      },
      skills: [],
      targetSkill: null,
      selectedSkill: [],
      headers: [
        { text: 'Skill ID', align: 'center', value: 'id', width: '10%' },
        { text: 'カテゴリ', align: 'center', value: 'category', width: '20%' },
        {
          text: 'スキル名',
          align: 'center',
          value: 'name',
          sortable: false,
          width: '20%',
        },
        {
          text: '番号',
          align: 'center',
          value: 'number',
          width: '10%',
        },
        { text: '更新日', align: 'center', value: 'updated_at', width: '20%' },
        {
          text: 'ロック・編集・削除',
          align: 'center',
          value: 'edit',
          sortable: false,
          width: '30%',
        },
      ],
      skillDialog: false,
      loading: true,
      confirmModal: {
        type: null,
        show: false,
        title: null,
        text: null,
        color: null,
        yesBtn: null,
        functionArg: null,
      },
    };
  },
  created() {
    this.getSkills();
  },
  computed: {
    displayLockFlagIcon() {
      return item => {
        return item?.lock_flag ? 'mdi-lock' : 'mdi-lock-open';
      };
    },
  },
  methods: {
    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/admin/required_skills', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.skills = result.data;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async updateSkill(skill) {
      try {
        await this.$axios.patch(
          `/api/v1/admin/required_skills/${skill.id}`,
          { required_skill: skill },
          { headers: session.apiAuthHeaders() }
        );
        this.getSkills();
        this.$emit('showSnackbar', 'success', '登録に成功しました。');
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 403:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したスキルはロックされているので更新できません。'
            );
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したスキルが見つかりません。'
            );
            this.$router.push({ name: 'Skills' });
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : '既に登録されている可能性があります。\nご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    async createSkill(skill) {
      try {
        await this.$axios.post(
          '/api/v1/admin/required_skills',
          { required_skill: skill },
          { headers: session.apiAuthHeaders() }
        );
        this.getSkills();
        this.$emit('showSnackbar', 'success', '登録に成功しました。');
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '既に登録されている可能性があります。\n通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    async deleteSkill(skillId) {
      try {
        await this.$axios.delete(`/api/v1/admin/required_skills/${skillId}`, {
          headers: session.apiAuthHeaders(),
        });
        this.getSkills();
        this.$emit('showSnackbar', 'success', '削除に成功しました。');
        this.resetModel();
      } catch (err) {
        this.resetModel();
        switch (err.response.status) {
          case 401:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したスキルは削除できません。'
            );
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したスキルが見つかりません。'
            );
            this.$router.push({ name: 'Users' });
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    async updateLockFlag(skillId) {
      try {
        await this.$axios.patch(
          `/api/v1/admin/required_skills/${skillId}/update_lock_flag`,
          {},
          {
            headers: session.apiAuthHeaders(),
          }
        );
        this.getSkills();
        this.$emit('showSnackbar', 'success', '更新に成功しました。');
        this.resetModel();
      } catch (err) {
        this.resetModel();
        switch (err.response.status) {
          case 401:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したスキルは更新できません。'
            );
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定したスキルが見つかりません。'
            );
            this.$router.push({ name: 'Skills' });
            break;
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    displayUpdatedAt(updatedAt) {
      return this.formatDatetime(updatedAt);
    },

    setSkillModal(value, targetSkill) {
      this.skillDialog = value;
      if (value) {
        this.targetSkill = targetSkill;
      }
    },

    getCategory(eachNum) {
      const targetValue = this.skillCategories.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    commitAction(type) {
      switch (type) {
        case 'delete':
          this.deleteSkill(this.confirmModal.functionArg);
          break;
        case 'updateLockFlag':
          this.updateLockFlag(this.confirmModal.functionArg.id);
          break;
      }
    },

    closeAction(type) {
      this.resetModel();
      switch (type) {
        case 'delete':
          break;
      }
    },

    resetModel() {
      this.confirmModal.type = null;
      this.confirmModal.title = null;
      this.confirmModal.text = null;
      this.confirmModal.show = false;
      this.confirmModal.functionArg = null;
      this.$refs.child.finishLoading();
    },

    setConfirmModal(type, arg) {
      this.confirmModal.type = type;
      this.confirmModal.show = true;
      this.confirmModal.functionArg = arg;
      switch (type) {
        case 'delete':
          this.confirmModal.color = 'error';
          this.confirmModal.yesBtn = '削除';
          this.confirmModal.title = 'スキルを削除しますか?';
          this.confirmModal.text = '削除したスキルは元に戻せません。';
          break;
        case 'updateLockFlag':
          this.confirmModal.color = 'success';
          this.confirmModal.yesBtn = arg.lock_flag ? '解除' : 'ロック';
          this.confirmModal.title = arg.lock_flag
            ? 'スキルのロックを解除しますか?'
            : 'スキルをロックしますか?';
          this.confirmModal.text = null;
          break;
        default:
          this.confirmModal.type = null;
          this.confirmModal.title = null;
          this.confirmModal.text = null;
          this.confirmModal.show = false;
      }
    },
  },
};
</script>
