var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%"}},[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',[_vm._v("管理者一覧")]),_c('v-spacer')],1),_c('v-divider'),_c('v-data-table',{attrs:{"show-select":"","loading":_vm.loading,"show-expand":"","headers":_vm.headers,"items":_vm.admins,"items-per-page":_vm.itemsPerPage,"fixed-header":"","height":"70vh"},on:{"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"pr-4",attrs:{"to":{ name: 'Admin', params: { id: item.id } }},domProps:{"textContent":_vm._s('#' + item.id)}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name + item.first_name)+" ")]}},{key:"item.active_entries_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active_entries_count)+" ")]}},{key:"item.active_inquiries_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active_inquiries_count)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"hide-default-footer":"","group-by":"status","headers":_vm.expandedHeaders,"items":_vm.getActiveEntries(item.entries),"item-key":"id","items-per-page":10},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v("mdi-minus")]):_c('v-icon',[_vm._v("mdi-plus")])],1),_vm._v(" ステータス: "+_vm._s(_vm.getStatus(group).value)+" ")],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td'),_c('td',{staticClass:"text-center"},[_c('router-link',{staticClass:"pr-4",attrs:{"to":{ name: 'Entry', params: { id: item.id } }},domProps:{"textContent":_vm._s('#' + item.id)}})],1),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'Project', params: { id: item.project_id } }},domProps:{"textContent":_vm._s(item.project_name)}})],1),_c('td',{staticClass:"text-center pr-8"},[_vm._v(" "+_vm._s(item.internal_interview ? 'あり' : '-')+" ")]),_c('td',{staticClass:"text-center pr-8"},[_c('v-icon',{attrs:{"color":_vm.getPriority(item.priority).color},domProps:{"textContent":_vm._s(_vm.getPriority(item.priority).value)}})],1),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'User', params: { id: item.user_id } }},domProps:{"textContent":_vm._s(item.user_last_name + ' ' + item.user_first_name)}})],1),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"pr-4",domProps:{"textContent":_vm._s(_vm.entryUpdatedAt(item))}})])])]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.selectedAdmins),callback:function ($$v) {_vm.selectedAdmins=$$v},expression:"selectedAdmins"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }