<template>
  <v-container>
    <v-container class="mt-1 px-5 pt-2 pb-5 d-flex">
      <h1>案件詳細</h1>
      <v-chip class="orange darken-2 white--text mt-2 ml-4 mb-1" dense>
        {{ editProject.public_project ? '公開中' : '非公開中' }}
      </v-chip>
      <v-chip class="green darken-2 white--text mt-2 ml-4 mb-1" dense>
        {{ '応募： ' + entries.length + '件' }}
      </v-chip>
      <v-spacer />
      <v-btn
        elevation="0"
        class="mt-2 mr-2 green darken-2 white--text"
        :disabled="entries.length === 0"
        @click="setEntryStatus(true)"
      >
        応募状況
      </v-btn>
      <v-btn
        elevation="0"
        class="mt-2 mr-2 orange darken-2 white--text"
        @click="setModal('updatePublic')"
      >
        {{ (!editProject.public_project ? '公開' : '非公開') + 'に更新' }}
      </v-btn>
      <v-btn
        class="mt-2 mr-2"
        elevation="0"
        color="lime darken-1 white--text"
        @click="setModal('clone')"
      >
        複製
      </v-btn>
      <v-btn
        class="mt-2 mr-2"
        elevation="0"
        color="primary"
        @click="setModal('update')"
      >
        編集を保存
      </v-btn>
      <v-btn
        elevation="0"
        color="red white--text"
        class="mt-2"
        @click="setModal('destroy')"
      >
        削除
      </v-btn>
    </v-container>

    <v-divider />

    <v-row v-if="projectLoading && skillLoading">
      <v-col cols="12" align="center" style="height: 70vh">
        <div class="d-flex justify-center align-center" style="height: 100%">
          <v-progress-circular
            :width="3"
            size="32"
            color="primary"
            indeterminate
          />
        </div>
      </v-col>
    </v-row>

    <v-card v-else outlined elevation="0" class="mt-5 px-5 pt-2 pb-5">
      <ProjectOpenDetail
        v-model="editProject"
        @updateBtnDisable="btnDisable = $event"
        :btnDisable="btnDisable"
      />
      <ProjectSkillDetail
        v-model="selectedSkills"
        :skills="skills"
        :project="projectDeepCopy"
        :skillLoading="skillLoading"
        :projectLoading="projectLoading"
      />
    </v-card>

    <Confirm
      ref="child"
      :type="modal.type"
      :color="modal.color"
      :dialog="modal.show"
      :title="modal.title"
      :text="modal.text"
      :yesBtn="modal.yesBtn"
      @commit="commitAction"
      @close="closeAction(modal.type)"
    />

    <ProjectStatus
      :dialog="entryStatusDialog"
      :entries="entries"
      @setEntryStatus="setEntryStatus"
    />
  </v-container>
</template>

<script>
import session from '../plugins/session';
import ProjectOpenDetail from '../components/pc/ProjectOpenDetail';
import Confirm from '../components/pc/modals/Confirm';
import ProjectStatus from '../components/pc/modals/ProjectStatus.vue';
import ProjectSkillDetail from '../components/pc/ProjectSkillDetail.vue';

export default {
  name: 'Project',
  components: {
    ProjectOpenDetail,
    Confirm,
    ProjectStatus,
    ProjectSkillDetail,
  },
  data() {
    return {
      projectDeepCopy: {},
      editProject: {},
      entries: [],
      skills: [],
      selectedSkills: [],
      modal: {
        type: null,
        show: false,
        title: null,
        text: null,
        color: null,
        yesBtn: null,
      },
      entryStatusDialog: false,
      btnDisable: false,
      isValidSkills: true,
      projectLoading: true,
      skillLoading: true,
    };
  },
  created() {
    this.getProject();
    this.getEntries();
    this.getSkills();
  },
  methods: {
    setEntryStatus(type) {
      this.entryStatusDialog = type;
    },

    commitAction(type) {
      switch (type) {
        case 'update':
          if (this.btnDisable) {
            this.$emit(
              'showSnackbar',
              'error',
              '入力項目を正しく入力してください。'
            );
            this.resetModel();
            return;
          }
          this.updateProject();
          break;
        case 'updatePublic':
          this.updatePublic();
          break;
        case 'clone':
          this.clone();
          this.resetModel();
          break;
        case 'destroy':
          this.destroyProject();
          break;
      }
    },

    closeAction(type) {
      this.resetModel();
      switch (type) {
        case 'update':
          break;
        case 'updatePublic':
          this.projectDeepCopy.public_project =
            !this.projectDeepCopy.public_project;
          break;
        case 'destroy':
          break;
      }
    },

    async getSkills() {
      try {
        const result = await this.$axios.get('/api/v1/admin/required_skills', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.skills = result.data;
        this.skillLoading = false;
      } catch (err) {
        this.skillLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async destroyProject() {
      try {
        const result = await this.$axios.delete(
          '/api/v1/admin/projects/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        this.resetModel();
        if (result.status === 204) {
          this.$emit('showSnackbar', 'success', '削除に成功しました。');
          this.$router.push({ name: 'Projects' });
        }
      } catch (err) {
        this.resetModel();
        switch (err.response.status) {
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定した案件が見つかりません。'
            );
            this.$router.push({ name: 'Projects' });
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    async getProject() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/projects/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          // 片方のデータを書き換えるともう片方も書き変わってしまうためDeepCopyを使用しています。
          this.editProject = result.data;
          this.projectDeepCopy = JSON.parse(JSON.stringify(result.data));
        }
        this.projectLoading = false;
      } catch (err) {
        this.projectLoading = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit('showSnackbar', 'error', '指定した案件が見つかりません。');
          this.$router.push({ name: 'Projects' });
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    async getEntries() {
      try {
        const result = await this.$axios.get(
          `/api/v1/admin/projects/${this.$route.params.id}/entries`,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) {
          this.entries = result.data;
        }
      } catch (err) {
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status === 404) {
          this.$emit('showSnackbar', 'error', '指定した案件が見つかりません。');
          this.$router.push({ name: 'Projects' });
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    selectSkillArray() {
      return this.projectDeepCopy.project_skills.map(skill => {
        return skill.required_skill_id;
      });
    },

    async updateProject() {
      try {
        // MEMO
        // skillLoadingの状態を検知して
        // 子コンポーネントの方のスキルのチェックボックスの選択状態を判定している
        this.skillLoading = true;
        const result = await this.$axios.patch(
          '/api/v1/admin/projects/' + this.$route.params.id,
          { project: this.editProject, project_skills: this.selectedSkills },
          { headers: session.apiAuthHeaders() }
        );
        this.resetModel();
        if (result.status === 200) {
          this.editProject = result.data;
          this.projectDeepCopy = JSON.parse(JSON.stringify(result.data));
          this.$emit('showSnackbar', 'success', '更新に成功しました。');
        }
        this.skillLoading = false;
      } catch (err) {
        this.resetModel();
        this.skillLoading = false;
        switch (err.response.status) {
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定した案件が見つかりません。'
            );
            this.$router.push({ name: 'Projects' });
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
            break;
        }
      }
    },

    async updatePublic() {
      this.projectDeepCopy.public_project =
        !this.projectDeepCopy.public_project;
      try {
        // MEMO
        // skillLoadingの状態を検知して
        // 子コンポーネントの方のスキルのチェックボックスの選択状態を判定している
        this.skillLoading = true;
        const result = await this.$axios.patch(
          '/api/v1/admin/projects/' + this.$route.params.id,
          {
            project: this.projectDeepCopy,
            project_skills: this.selectSkillArray(),
          },
          { headers: session.apiAuthHeaders() }
        );
        this.resetModel();
        if (result.status === 200) {
          this.editProject = result.data;
          this.projectDeepCopy = JSON.parse(JSON.stringify(result.data));
          this.$emit('showSnackbar', 'success', '更新に成功しました。');
        }
        this.skillLoading = false;
      } catch (err) {
        this.resetModel();
        this.skillLoading = false;
        switch (err.response.status) {
          case 400:
            const error_message = err.response.data.message
              ? err.response.data.message
              : 'ご確認の上、再度実行してください。';
            this.$emit('showSnackbar', 'error', error_message);
            break;
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit(
              'showSnackbar',
              'error',
              '指定した案件が見つかりません。'
            );
            this.$router.push({ name: 'Projects' });
            break;
          default:
            this.$emit(
              'showSnackbar',
              'error',
              '通信環境をご確認の上、再度実行してください。'
            );
        }
      }
    },

    clone() {
      window.open('/projects/add?source=' + this.$route.params.id, '_blank');
    },

    resetModel() {
      this.modal.type = null;
      this.modal.title = null;
      this.modal.text = null;
      this.modal.show = false;
      this.$refs.child.finishLoading();
    },

    setModal(type) {
      this.modal.type = type;
      this.modal.show = true;
      switch (type) {
        case 'update':
          this.modal.color = 'primary';
          this.modal.yesBtn = '更新する';
          this.modal.title = '案件を更新しますか?';
          this.modal.text = '案件を更新すると、サイトへ即時反映されます。';
          break;
        case 'updatePublic':
          this.modal.color = 'orange darken-2';
          this.modal.yesBtn = '更新する';
          this.modal.title =
            (!this.projectDeepCopy.public_project ? '公開' : '非公開') +
            'に変更しますか?';
          this.modal.text = !this.projectDeepCopy.public_project
            ? '公開に変更すると、利用者が閲覧できるようになります。'
            : '非公開に変更すると、利用者が閲覧できなくなります。';
          break;
        case 'clone':
          this.modal.color = 'lime darken-1';
          this.modal.yesBtn = '複製する';
          this.modal.title = '案件を複製しますか?';
          this.modal.text = '案件の公開ステータスは非公開で複製されます。';
          break;
        case 'destroy':
          this.modal.color = 'red';
          this.modal.yesBtn = '削除する';
          this.modal.title = '案件を削除しますか?';
          this.modal.text = '案件を削除すると、元に戻すことはできません。';
          break;
        default:
          this.modal.type = null;
          this.modal.title = null;
          this.modal.text = null;
          this.modal.show = false;
      }
    },
  },
};
</script>
