<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title>ユーザ一覧</v-toolbar-title>
      <v-spacer />
      <v-btn
        elevation="0"
        color="primary"
        @click="$router.push({ name: 'WithdrawalUsers' })"
      >
        退会者一覧
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-data-table
      show-select
      :loading="loading"
      v-model="selectedUsers"
      show-expand
      :headers="headers"
      :items="usersWithBookmarks"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'User', params: { id: item.id } }"
          v-text="`#${item.id}`"
        />
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ displayUserName(item) }}
      </template>

      <template v-slot:[`item.birth`]="{ item }">
        <div class="pr-4" v-text="getAge(item.birth) || '-'" />
      </template>

      <template v-slot:[`item.active_entries_count`]="{ item }">
        {{ item.active_entries_count }}
      </template>

      <template v-slot:[`item.workStatus`]="{ item }">
        <template v-if="getWorkingEntries(item.entries).length > 0">
          <v-chip color="primary">稼働中</v-chip>
        </template>
        <template v-else>
          <div>-</div>
        </template>
      </template>

      <template v-slot:[`item.active_inquiries_count`]="{ item }">
        {{ item.active_inquiries_count }}
      </template>

      <template v-slot:[`item.bookmark`]="{ item }">
        <v-btn icon @click="toggleBookmark(item)">
          <v-icon
            color="primary"
            v-text="item.bookmark ? 'mdi-bookmark' : 'mdi-bookmark-outline'"
          />
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="px-0">
          <v-data-table
            hide-default-footer
            group-by="status"
            :headers="expandedHeaders"
            :items="getProgressEntries(item.entries)"
            item-key="id"
            :items-per-page="10"
          >
            <template v-slot:group.header="{ group, headers, isOpen, toggle }">
              <td :colspan="headers.length">
                <v-btn icon small @click="toggle">
                  <v-icon v-if="isOpen" v-text="'mdi-minus'" />
                  <v-icon v-else v-text="'mdi-plus'" />
                </v-btn>
                ステータス: {{ getStatus(group).value }}
              </td>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td />
                <td class="text-center">
                  <router-link
                    class="pr-4"
                    :to="{ name: 'Entry', params: { id: item.id } }"
                    v-text="`#${item.id}`"
                  />
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ name: 'Project', params: { id: item.project_id } }"
                    v-text="item.project_name"
                  />
                </td>
                <td class="text-center">
                  <div
                    class="pr-4"
                    v-text="item.internal_interview ? 'あり' : '-'"
                  />
                </td>
                <td class="text-center pr-4">
                  <v-icon
                    class="pr-4"
                    :color="getPriority(item.priority).color"
                    v-text="getPriority(item.priority).value"
                  />
                </td>
                <td class="text-center" v-text="entryRepAdmin(item)" />
                <td class="text-center">
                  <div class="pr-4" v-text="entryUpdatedAt(item.updated_at)" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import session from '../plugins/session';
import mixinDate from '../const/date';
import mixinEntryStatus from '../const/entry/status';
import mixinPriorityStatus from '../const/entry/priority';
import mixinPagination from '../plugins/pagination';
import dayjs from 'dayjs';

export default {
  name: 'Users',
  mixins: [mixinDate, mixinEntryStatus, mixinPriorityStatus, mixinPagination],
  data() {
    return {
      users: [],
      bookmarks: [],
      selectedUsers: [],
      headers: [
        { text: 'User ID', align: 'center', value: 'id', width: '8%' },
        {
          text: 'ユーザ名',
          align: 'center',
          value: 'name',
          sortable: false,
          width: '25%',
        },
        { text: '年齢', align: 'center', value: 'birth', width: '8%' },
        {
          text: '進行中の応募',
          align: 'center',
          value: 'active_entries_count',
          width: '15%',
        },
        {
          text: '案件稼働状況',
          align: 'center',
          value: 'workStatus',
          sortable: false,
          width: '18%',
        },
        {
          text: '進行中の問い合わせ',
          align: 'center',
          value: 'active_inquiries_count',
          width: '18%',
        },
        { text: '', align: 'center', value: 'bookmark', sortable: false },
        {
          text: '',
          align: 'center',
          value: 'data-table-expand',
          sortable: false,
          width: '8%',
        },
      ],
      expandedHeaders: [
        { text: '', value: '', width: '8%' },
        { text: '応募ID', align: 'center', value: 'id', width: '8%' },
        {
          text: '案件名',
          align: 'center',
          value: 'project_name',
          sortable: false,
          width: '30%',
        },
        {
          text: '事前面談',
          align: 'center',
          value: 'first_interview',
          width: '10%',
        },
        { text: '優先度', align: 'center', value: 'priority', width: '10%' },
        { text: '進行状況', align: 'center', value: 'status', width: '10%' },
        {
          text: '担当者',
          align: 'center',
          value: 'admin',
          sortable: false,
          width: '12%',
        },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '12%',
        },
      ],
      loading: true,
    };
  },
  async created() {
    await this.getUsers();
    await this.getBookmarks();
    this.loading = false;
  },
  computed: {
    getActiveEntries() {
      return userEntries => {
        const cancelEntryId = this.entryStatus.find(
          element => element.value === 'キャンセル'
        ).id;
        const sendOffEntryId = this.entryStatus.find(
          element => element.value === '面談見送り'
        ).id;
        const workingEndEntryId = this.entryStatus.find(
          element => element.value === '稼働終了'
        ).id;
        return userEntries.filter(
          entry =>
            ![cancelEntryId, sendOffEntryId, workingEndEntryId].includes(
              entry.status
            )
        );
      };
    },

    getProgressEntries() {
      return userEntries => {
        const cancelEntryId = this.entryStatus.find(
          element => element.value === 'キャンセル'
        ).id;
        const sendOffEntryId = this.entryStatus.find(
          element => element.value === '面談見送り'
        ).id;
        const contractedEntryId = this.entryStatus.find(
          element => element.value === '成約'
        ).id;
        const workingEndEntryId = this.entryStatus.find(
          element => element.value === '稼働終了'
        ).id;
        return userEntries.filter(
          entry =>
            ![
              cancelEntryId,
              sendOffEntryId,
              contractedEntryId,
              workingEndEntryId,
            ].includes(entry.status)
        );
      };
    },

    getWorkingEntries() {
      return userEntries => {
        const workingEntryId = this.entryStatus.find(
          element => element.value === '稼働中'
        ).id;
        return userEntries.filter(entry => entry.status === workingEntryId);
      };
    },

    entryUpdatedAt() {
      return updatedAt => {
        return this.formatDatetime(updatedAt);
      };
    },

    entryRepAdmin() {
      return value => {
        return value.admin_first_name != null && value.admin_last_name != null
          ? value.admin_last_name + ' ' + value.admin_first_name
          : '-';
      };
    },

    usersWithBookmarks() {
      return this.users.map(user => {
        const hasBookmark = this.bookmarks.find(
          bookmark => bookmark.id === user.id
        );
        return {
          ...user,
          bookmark: hasBookmark ? true : false,
        };
      });
    },

    displayUserName() {
      return user => {
        if (user.last_name && user.first_name)
          return user.last_name + ' ' + user.first_name;
        return '-';
      };
    },
  },
  methods: {
    getAge(birthDate) {
      if (birthDate == null) return '-';
      const now = dayjs().format('YYYYMMDD');
      const dayjsParse = dayjs(birthDate).format('YYYYMMDD');
      return Math.trunc((now - dayjsParse) / 10000);
    },

    getPriority(eachNum) {
      const targetValue = this.entryPriority.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    async postBookmark(userId) {
      const favorite = {
        user_id: userId,
      };
      try {
        await this.$axios.post('/api/v1/admin/favorites', favorite, {
          headers: session.apiAuthHeaders(),
        });
        this.$emit('showSnackbar', 'success', 'ブックマークしました');
        await this.getBookmarks();
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 400:
            this.$emit('showSnackbar', 'error', '既にブックマークしています');
            this.getBookmarks();
            break;
          default:
            this.$emit('showSnackbar', 'error', 'エラーが発生しました');
            break;
        }
      }
    },

    async deleteBookmark(userId) {
      try {
        await this.$axios.delete(`/api/v1/admin/favorites/${userId}`, {
          headers: session.apiAuthHeaders(),
        });
        this.$emit('showSnackbar', 'success', 'ブックマークを解除しました');
        await this.getBookmarks();
      } catch (err) {
        switch (err.response.status) {
          case 401:
            session.removeItemKeys();
            this.$router.push({ name: 'Login' });
            break;
          case 404:
            this.$emit('showSnackbar', 'error', 'ブックマークが見つかりません');
            this.getBookmarks();
            break;
          default:
            this.$emit('showSnackbar', 'error', 'エラーが発生しました');
            break;
        }
      }
    },

    async toggleBookmark(user) {
      if (user.bookmark) {
        await this.deleteBookmark(user.id);
      } else {
        await this.postBookmark(user.id);
      }
    },

    async getBookmarks() {
      try {
        const { status, data } = await this.$axios.get(
          '/api/v1/admin/favorites',
          {
            headers: session.apiAuthHeaders(),
          }
        );
        if (status === 200) this.bookmarks = data;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getUsers() {
      try {
        const result = await this.$axios.get('/api/v1/admin/users', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.users = result.data;
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getStatus(eachNum) {
      const targetValue = this.entryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },
  },
};
</script>
