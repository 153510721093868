<template>
  <v-card outlined elevation="0" class="pa-5">
    <v-container>
      <v-row>
        <v-col :class="skillExists ? 'pt-0 pb-1' : 'pt-0 pb-0'">
          <h5>登録スキル</h5>
        </v-col>
        <v-col cols="12" :class="skillExists ? 'pl-6' : 'py-1'">
          <div
            v-if="!skillExists"
            style="font-size: 15px"
            v-text="'スキルが登録されていません。'"
          />
          <v-row v-else>
            <v-col
              v-for="(item, index) in skillTypes"
              :key="index"
              :class="userSkills[item.type].length === 0 ? 'pa-0' : 'py-1'"
              cols="12"
            >
              <div v-if="userSkills[item.type].length !== 0">
                <h5 class="pb-2">{{ item.label }}</h5>
                <v-chip
                  v-for="(skill, index) in userSkills[item.type]"
                  :key="index"
                  label
                  color="white black--text"
                  class="mr-2 mb-2 skills"
                  style="border: solid 1px; border-color: #bdbdbd !important"
                  v-text="skill.name"
                />
              </div>
            </v-col>
            <v-col cols="12" class="py-1" v-if="user.other_skills">
              <h5 class="pb-2">その他(自由入力)</h5>
              <v-chip
                v-for="(skill, index) in otherSkills"
                :key="index"
                label
                color="white black--text"
                class="mr-2 mb-2 skills"
                style="border: solid 1px; border-color: #bdbdbd !important"
                v-text="skill"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h5>スキルシート</h5>
          <div class="d-flex">
            <template v-if="skillSheet === null">
              <div class="pt-1" style="font-size: 15px">
                アップロードされていません。
              </div>
            </template>
            <template v-else>
              <div class="pt-2" v-text="skillSheet.filename" />
              <div class="pt-2 pl-2" v-text="skillSheetCreatedAtInitialValue" />
              <v-btn
                class="pt-1 font-weight-bold"
                color="primary"
                text
                @click="download"
              >
                ダウンロード
              </v-btn>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<style scoped>
/* 各スキルのホバー表示を消す */
.skills.v-chip {
  pointer-events: none;
}
</style>
<script>
import mixinDate from '../../const/date';

export default {
  name: 'UserDetail',
  props: ['user', 'skills', 'userSkills', 'skillSheet'],
  mixins: [mixinDate],
  data() {
    return {
      skillTypes: [
        { label: '言語', type: 'languages' },
        { label: 'フレームワーク', type: 'frameworks' },
        { label: 'その他', type: 'others' },
      ],
    };
  },
  computed: {
    skillExists() {
      return !(
        this.userSkills.languages.length === 0 &&
        this.userSkills.frameworks.length === 0 &&
        this.userSkills.others.length === 0 &&
        !this.user.other_skills
      );
    },

    skillSheetCreatedAtInitialValue() {
      return `( ${this.formatDatetimeWithSec(this.skillSheet.created_at)} )`;
    },

    otherSkills() {
      return this.user.other_skills ? this.user.other_skills : [];
    },
  },
  methods: {
    download() {
      this.$emit('download');
    },
  },
};
</script>
