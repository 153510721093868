<template>
  <v-card outlined elevation="0" class="pa-1">
    <v-data-table
      show-select
      v-model="selectedEntries"
      :headers="headers"
      :items="entries"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'Entry', params: { id: item.id } }"
        >
          {{ '#' + item.id }}
        </router-link>
      </template>

      <template v-slot:[`item.project`]="{ item }">
        <router-link :to="{ name: 'Project', params: { id: item.project.id } }">
          {{ item.project.name }}
        </router-link>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        {{ displayUserName(item.user) }}
      </template>

      <template v-slot:[`item.internal_interview`]="{ item }">
        <div class="pr-4" v-text="item.internal_interview ? 'あり' : '-'" />
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <v-icon
          class="pr-4"
          :color="getEntryPriority(item.priority).color"
          v-text="getEntryPriority(item.priority).value"
        />
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="pr-4">
          <v-chip :color="getEntryStatus(item.status).color" dense>
            {{ getEntryStatus(item.status).value }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.evaluation`]="{ item }">
        <v-chip :color="getEntryEvaluation(item.evaluation).color" dense>
          {{ getEntryEvaluation(item.evaluation).value }}
        </v-chip>
      </template>

      <template v-slot:[`item.admin`]="{ item }">
        {{ item.admin.last_name + item.admin.first_name || '-' }}
      </template>

      <template v-slot:[`item.updated_at`]="{ item }">
        <div class="pr-4" v-text="displayUpdatedAt(item.updated_at)"></div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import mixinDate from '../../const/date';
import mixinEntryEvaluation from '../../const/entry/evaluation';
import mixinEntryStatus from '../../const/entry/status';
import mixinEntryPriority from '../../const/entry/priority';
import mixinPagination from '../../plugins/pagination';

export default {
  name: 'UserEntries',
  props: ['entries'],
  mixins: [
    mixinDate,
    mixinEntryEvaluation,
    mixinEntryStatus,
    mixinEntryPriority,
    mixinPagination,
  ],
  data() {
    return {
      headers: [
        { text: 'Entry ID', align: 'center', value: 'id', width: '9%' },
        {
          text: '案件名',
          align: 'center',
          value: 'project',
          sortable: false,
          width: '22%',
        },
        {
          text: '応募者',
          align: 'center',
          value: 'user',
          sortable: false,
          width: '11%',
        },
        {
          text: '事前面談',
          align: 'center',
          value: 'internal_interview',
          width: '10%',
        },
        { text: '優先度', align: 'center', value: 'priority', width: '8%' },
        { text: '進行状況', align: 'center', value: 'status', width: '13%' },
        {
          text: '担当者',
          align: 'center',
          value: 'admin',
          sortable: false,
          width: '13%',
        },
        {
          text: '最終更新',
          align: 'center',
          value: 'updated_at',
          width: '14%',
        },
      ],
      selectedEntries: [],
    };
  },
  computed: {
    displayUserName() {
      return user => {
        if (user.last_name && user.first_name)
          return user.last_name + ' ' + user.first_name;
        return '-';
      };
    },

    displayUpdatedAt() {
      return updatedAt => {
        return this.formatDatetime(updatedAt);
      };
    },
  },
  methods: {
    getEntryEvaluation(eachNum) {
      const targetValue = this.entryEvaluation.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getEntryPriority(eachNum) {
      const targetValue = this.entryPriority.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },

    getEntryStatus(eachNum) {
      const targetValue = this.entryStatus.find(
        element => element.id === eachNum
      );
      return { value: targetValue.value, color: targetValue.color };
    },
  },
};
</script>
