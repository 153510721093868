export default {
  data() {
    return {
      minimumWorkDays: [
        { id: 0, value: 1 },
        { id: 1, value: 2 },
        { id: 2, value: 3 },
        { id: 3, value: 4 },
        { id: 4, value: 5 },
        { id: 5, value: 6 },
        { id: 6, value: 7 },
      ],
    };
  },
};
