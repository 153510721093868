<template>
  <v-container>
    <v-responsive max-width="1161" class="mx-auto">
      <v-container class="px-0 d-flex">
        <v-row>
          <v-col>
            <h2>退会者詳細</h2>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-row v-if="userLoading">
        <v-col cols="12" align="center" style="height: 50vh">
          <div class="d-flex justify-center align-center" style="height: 100%">
            <v-progress-circular
              :width="3"
              size="32"
              color="primary"
              indeterminate
            />
          </div>
        </v-col>
      </v-row>
      <div v-else>
        <div v-if="user" class="pt-4">
          <h3 class="py-4">基本情報</h3>
          <WithdrawalUserDetail :user="user" @getCopy="getCopy" />
        </div>
      </div>
    </v-responsive>
  </v-container>
</template>

<script>
import session from '../plugins/session';
import copy from '../plugins/copy';
import WithdrawalUserDetail from '../components/pc/WithdrawalUserDetail.vue';
import mixinDate from '../const/date';

export default {
  name: 'WithdrawalUser',
  components: {
    WithdrawalUserDetail,
  },
  mixins: [mixinDate],
  data() {
    return {
      user: null,
      userLoading: true,
    };
  },
  created() {
    this.getWithdrawalUser();
  },
  computed: {
    displayUserName() {
      return user => {
        if (user.last_name && user.first_name)
          return user.last_name + ' ' + user.first_name;
        return '-';
      };
    },
  },
  methods: {
    async getWithdrawalUser() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/withdrawal_users/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status === 200) this.user = result.data;
        this.userLoading = false;
      } catch (err) {
        this.userLoading = false;
        if (err.response.status === 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getCopy(type) {
      copy.textCopy(this.user[type]);
      this.$emit('showSnackbar', 'success', 'コピーしました。');
    },
  },
};
</script>
