<template>
  <v-card tile elevation="0" height="100%">
    <v-toolbar elevation="0">
      <v-toolbar-title>案件一覧</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="pt-6 px-2"
        outlined
        dense
        color="primary"
        :value="outputFreeWord"
        @input="inputFreeWord"
      />
      <v-btn
        elevation="0"
        color="primary white--text"
        @click="search"
        v-text="'検索'"
      />
      <v-checkbox
        dense
        label="公開案件のみ"
        class="mx-5 pt-5 mb-1"
        v-model="isPublicOnlyProjects"
      />
      <v-btn
        elevation="0"
        color="primary"
        @click="$router.push({ name: 'ProjectsAdd' })"
      >
        新規追加
      </v-btn>
    </v-toolbar>

    <v-divider />

    <v-data-table
      show-select
      :loading="loading"
      v-model="selectedProjects"
      :headers="headers"
      :items="setProjects"
      :items-per-page="itemsPerPage"
      @update:items-per-page="updateItemsPerPage"
      fixed-header
      height="70vh"
    >
      <template v-slot:[`item.id`]="{ item }">
        <router-link
          class="pr-4"
          :to="{ name: 'Project', params: { id: item.id } }"
        >
          {{ projectNumber(item.id) }}
        </router-link>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.municipality`]="{ item }">
        <div class="pr-4" v-text="municipalityName(item.municipality)" />
      </template>

      <template v-slot:[`item.cost`]="{ item }">
        <div class="pr-4" v-text="costPrice(item.cost)" />
      </template>

      <template v-slot:[`item.public_project`]="{ item }">
        <div
          class="pr-4"
          v-text="item.public_project ? '公開中' : '非公開中'"
        />
      </template>

      <template v-slot:[`item.recruitment_start_at`]="{ item }">
        <div class="pr-4" v-text="recruitmentStartAt(item)" />
      </template>

      <template v-slot:[`item.recruitment_finish_at`]="{ item }">
        <div class="pr-4" v-text="recruitmentFinishAt(item)" />
      </template>
    </v-data-table>
  </v-card>
</template>

<style scoped>
.v-text-field {
  max-width: 300px;
}
</style>

<script>
import session from '../plugins/session';
import common from '../plugins/common';
import mixinDate from '../const/date';
import mixinMunicipalities from '../const/project/municipality';
import mixinPagination from '../plugins/pagination';

export default {
  name: 'Projects',
  mixins: [mixinDate, mixinMunicipalities, mixinPagination],
  data() {
    return {
      headers: [
        { text: 'Project ID', align: 'center', value: 'id', width: '10%' },
        {
          text: '案件名',
          align: 'center',
          value: 'name',
          sortable: false,
          width: '18%',
        },
        {
          text: '勤務地',
          align: 'center',
          value: 'municipality',
          width: '12%',
        },
        {
          text: 'エリア',
          align: 'center',
          value: 'station',
          sortable: false,
          width: '12%',
        },
        { text: '単価', align: 'center', value: 'cost', width: '10%' },
        {
          text: '公開状況',
          align: 'center',
          value: 'public_project',
          width: '12%',
        },
        {
          text: '公開開始期間',
          align: 'center',
          value: 'recruitment_start_at',
          width: '14%',
        },
        {
          text: '公開終了期間',
          align: 'center',
          value: 'recruitment_finish_at',
          width: '14%',
        },
      ],
      selectedProjects: [],
      allProjects: [],
      publicOnlyProjects: [],
      isPublicOnlyProjects: false,
      loading: true,
      freeWordList: [],
      searchWordsArray: [],
    };
  },
  created() {
    this.getProjects();
  },
  beforeRouteUpdate(to, from, next) {
    this.getProjects(to);
    this.setSearchedWord(to);
    window.scrollTo(0, 0);
    next();
  },
  computed: {
    setProjects() {
      return this.isPublicOnlyProjects
        ? this.publicOnlyProjects
        : this.allProjects;
    },

    recruitmentStartAt() {
      return item => {
        return this.formatDate(item.recruitment_start_at);
      };
    },

    recruitmentFinishAt() {
      return item => {
        return this.formatDate(item.recruitment_finish_at);
      };
    },

    outputFreeWord() {
      if (this.$route.query['free_words[]'] == null) return null;
      // HACK: リファクタ必要なため一旦skip
      /* eslint vue/no-side-effects-in-computed-properties: 0 */
      if (
        this.$route.query['free_words[]'] &&
        Array.isArray(this.$route.query['free_words[]'])
      ) {
        this.freeWordList = this.$route.query['free_words[]'];
        return this.$route.query['free_words[]'].join(' ');
      } else {
        this.freeWordList = [this.$route.query['free_words[]']];
      }
      return this.$route.query['free_words[]'];
    },
  },
  methods: {
    projectNumber(projectId) {
      return common.formatProjectNum(projectId);
    },

    costPrice(cost) {
      return cost != null ? `${cost}万円` : '';
    },

    setSearchedWord(nextRoute) {
      const setRoute = nextRoute ? nextRoute : this.$route;
      this.searchWordsArray = this.freeWordsAsQuery(setRoute);
    },

    // queryを元にフリーワードを配列に変換させる関数
    freeWordsAsQuery(route) {
      let freeWordArray = [];
      if (route.query['free_words[]'] != null) {
        freeWordArray = freeWordArray.concat(route.query['free_words[]']);
      }
      return freeWordArray;
    },

    AdjustSearchAPI(nextRoute) {
      const setRoute = nextRoute ? nextRoute : this.$route;
      let setSearchAPI = [];

      // setRoute.queryが存在しない場合はガード節
      if (!Object.keys(setRoute.query).length) {
        this.freeWordList = [];
        return '';
      }
      if (setRoute.query['free_words[]']) {
        setSearchAPI.push(this.encodePase('free_words[]', setRoute));
      }
      // 重複しているqueryを削除
      const filteredSearchAPI = setSearchAPI.filter(
        (elem, index, self) => self.indexOf(elem) === index
      );

      let formalAdjustment = '';
      filteredSearchAPI.forEach((element, index) => {
        if (index === 0) {
          formalAdjustment += '/search?' + element;
        } else {
          formalAdjustment += '&' + element;
        }
      });

      return formalAdjustment;
    },

    encodePase(searchName, setRoute) {
      let result = '';
      // queryが複数の場合はarray
      if (Array.isArray(setRoute.query[searchName])) {
        setRoute.query[searchName].forEach((element, index) => {
          if (index === 0)
            return (result += searchName + '=' + encodeURI(element));
          return (result += '&' + searchName + '=' + encodeURI(element));
        });
      } else {
        // queryが1つの場合は要素本体
        return (result +=
          searchName + '=' + encodeURI(setRoute.query[searchName]));
      }
      return result;
    },

    async getProjects(nextRoute) {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/projects' + this.AdjustSearchAPI(nextRoute),
          {
            headers: session.apiAuthHeaders(),
          }
        );
        this.loading = false;
        if (result.status == 200) {
          this.allProjects = result.data;
          this.publicOnlyProjects = result.data.filter(
            project => project.public_project
          );
        }
      } catch (err) {
        this.loading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    municipalityName(municipalityEachNum) {
      return this.municipalities.find(
        element => element.id === municipalityEachNum
      ).value;
    },

    inputFreeWord(event) {
      const REGEX = /\s+/;
      const setFreeWords = event.split(REGEX).filter(element => {
        return element != '';
      });
      this.freeWordList = setFreeWords;
    },

    setSearchRequest() {
      let targetQuery = {};
      if (this.freeWordList.length != 0) {
        targetQuery['free_words[]'] = this.freeWordList;
      }
      return targetQuery;
    },

    resetSearch() {
      this.$router.push({ path: '/projects' }).catch(() => {});
    },

    search() {
      const setRequest = this.setSearchRequest();
      if (!setRequest) return;
      if (!Object.keys(setRequest).length) {
        this.resetSearch();
      } else {
        this.$router
          .push({ name: 'Projects', query: setRequest })
          .catch(() => {});
      }
      window.scrollTo(0, 0);
    },
  },
};
</script>
