import Vue from 'vue';
import VueRouter from 'vue-router';

import Admins from '../views/Admins.vue';
import Admin from '../views/Admin.vue';
import AdminMypage from '../views/AdminMypage.vue';
import Entries from '../views/Entries.vue';
import Entry from '../views/Entry.vue';
import Inquiries from '../views/Inquiries.vue';
import Inquiry from '../views/Inquiry.vue';
import Login from '../views/Login.vue';
import Projects from '../views/Projects.vue';
import ProjectsAdd from '../views/ProjectsAdd.vue';
import Project from '../views/Project.vue';
import ResetPassword from '../views/ResetPassword.vue';
import User from '../views/User.vue';
import Users from '../views/Users.vue';
import WithdrawalUser from '../views/WithdrawalUser.vue';
import WithdrawalUsers from '../views/WithdrawalUsers.vue';
import RegisterAdmin from '../views/RegisterAdmin.vue';
import UpdatePassword from '../views/UpdatePassword.vue';
import Bookmarks from '../views/Bookmarks.vue';
import ConfirmationEmail from '../views/ConfirmationEmail.vue';
import Skills from '../views/Skills.vue';
import VueCookies from 'vue-cookies';

Vue.use(VueRouter);
Vue.use(VueCookies);

const routes = [
  {
    path: '*',
    name: 'NotFound',
    redirect: '/login',
  },
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
  },
  {
    path: '/admins',
    name: 'Admins',
    component: Admins,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/mypage',
    name: 'AdminMypage',
    component: AdminMypage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/:id',
    name: 'Admin',
    component: Admin,
    meta: { requiresAuth: true },
  },
  {
    path: '/entries',
    name: 'Entries',
    component: Entries,
    meta: { requiresAuth: true },
  },
  {
    path: '/entry/:id',
    name: 'Entry',
    component: Entry,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiries',
    name: 'Inquiries',
    component: Inquiries,
    meta: { requiresAuth: true },
  },
  {
    path: '/inquiry/:id',
    name: 'Inquiry',
    component: Inquiry,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: { requiresAuth: true },
  },
  {
    path: '/projects/add',
    name: 'ProjectsAdd',
    component: ProjectsAdd,
    meta: { requiresAuth: true },
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Project,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: '/withdrawal_users/:id',
    name: 'WithdrawalUser',
    component: WithdrawalUser,
    meta: { requiresAuth: true },
  },
  {
    path: '/withdrawal_users',
    name: 'WithdrawalUsers',
    component: WithdrawalUsers,
    meta: { requiresAuth: true },
  },
  {
    path: '/bookmarks',
    name: 'Bookmarks',
    component: Bookmarks,
    meta: { requiresAuth: true },
  },
  {
    path: '/skills',
    name: 'Skills',
    component: Skills,
    meta: { requiresAuth: true },
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/registeradmin',
    name: 'RegisterAdmin',
    component: RegisterAdmin,
    meta: { requiresAuth: false },
  },
  {
    path: '/updatepassword',
    name: 'UpdatePassword',
    component: UpdatePassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/confirmation-email',
    name: 'ConfirmationEmail',
    component: ConfirmationEmail,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
