<template>
  <v-responsive max-width="1161" class="mx-auto">
    <v-container class="px-0 d-flex">
      <h2>応募詳細</h2>
    </v-container>
    <v-divider />
    <v-row v-if="entryLoading || adminLoading">
      <v-col cols="12" align="center" style="height: 50vh">
        <div class="d-flex justify-center align-center" style="height: 100%">
          <v-progress-circular
            :width="3"
            size="32"
            color="primary"
            indeterminate
          />
        </div>
      </v-col>
    </v-row>
    <div v-else>
      <div class="pt-4">
        <h3 class="py-4">応募内容</h3>
        <EntryDetail :entry="entryDeepCopy" @getCopy="getCopy" />
      </div>
      <div class="pt-4">
        <h3 class="py-4">応募管理情報</h3>
        <EntryUpdateDetail
          v-model="editEntry"
          :admins="admins"
          @update="updateEntry"
        />
      </div>
      <div class="pt-4">
        <h3 class="py-4">応募ステータス変更履歴</h3>
        <EntryStatusHistory :entryStatusHistories="entryStatusHistories" />
      </div>
    </div>
  </v-responsive>
</template>

<script>
import session from '../plugins/session';
import copy from '../plugins/copy';
import EntryDetail from '../components/pc/EntryDetail.vue';
import EntryUpdateDetail from '../components/pc/EntryUpdateDetail.vue';
import EntryStatusHistory from '../components/pc/EntryStatusHistory.vue';

export default {
  name: 'Entry',
  components: {
    EntryDetail,
    EntryUpdateDetail,
    EntryStatusHistory,
  },
  data() {
    return {
      entryDeepCopy: null,
      entryStatusHistories: [],
      editEntry: null,
      admins: [],
      adminLoading: true,
      entryLoading: true,
      entryStatusHistoriesLoading: true,
    };
  },
  created() {
    this.getEntry();
    this.getEntryStatusHistories();
    this.getAdmins();
  },
  methods: {
    async getAdmins() {
      try {
        const result = await this.$axios.get('/api/v1/admin/admins', {
          headers: session.apiAuthHeaders(),
        });
        if (result.status == 200) this.admins = result.data;
        this.adminLoading = false;
      } catch (err) {
        this.adminLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getEntry() {
      try {
        const result = await this.$axios.get(
          '/api/v1/admin/entries/' + this.$route.params.id,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) {
          // 片方のデータを書き換えるともう片方も書き変わってしまうためDeepCopyを使用しています。
          this.editEntry = result.data;
          this.entryDeepCopy = JSON.parse(JSON.stringify(result.data));
        }
        this.entryLoading = false;
      } catch (err) {
        this.entryLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    async getEntryStatusHistories() {
      try {
        const result = await this.$axios.get(
          `/api/v1/admin/entries/${this.$route.params.id}/entry_status_histories`,
          { headers: session.apiAuthHeaders() }
        );
        if (result.status == 200) this.entryStatusHistories = result.data;
        this.entryStatusHistoriesLoading = false;
      } catch (err) {
        this.entryStatusHistoriesLoading = false;
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
      }
    },

    getCopy(type) {
      copy.textCopy(this.entryDeepCopy.user[type]);
      this.$emit('showSnackbar', 'success', 'コピーしました。');
    },

    async updateEntry(inValid, column) {
      if (inValid) {
        const errorMessage =
          this.editEntry.admin_id != null
            ? '入力項目を正しく入力してください。'
            : '担当者を設定してください。';
        this.$emit('showSnackbar', 'error', errorMessage);
        return;
      }

      try {
        const result = await this.$axios.patch(
          '/api/v1/admin/entries/' + this.$route.params.id,
          { entry: this.setUpdateValue(column) },
          { headers: session.apiAuthHeaders() }
        );
        this.editEntry = result.data;
        this.entryDeepCopy = JSON.parse(JSON.stringify(result.data));
        if (this.editEntry.admin_id != null) {
          this.$emit('showSnackbar', 'success', '更新に成功しました。');
          await this.getEntryStatusHistories();
        } else {
          this.$emit(
            'showSnackbar',
            'success',
            '更新に成功しました。（担当者が未設定です。）'
          );
          await this.getEntryStatusHistories();
        }
      } catch (err) {
        if (err.response.status == 401) {
          session.removeItemKeys();
          this.$router.push({ name: 'Login' });
          return;
        }
        if (err.response.status == 404) {
          this.$emit('showSnackbar', 'error', '指定した応募が見つかりません。');
          this.$router.push({ name: 'Entries' });
          return;
        }
        if (err.response.status == 400) {
          const error_message = err.response.data.message
            ? err.response.data.message
            : 'ご確認の上、再度実行してください。';
          this.$emit('showSnackbar', 'error', error_message);
          return;
        }
        this.$emit(
          'showSnackbar',
          'error',
          '通信環境をご確認の上、再度実行してください。'
        );
      }
    },

    setUpdateValue(column) {
      let updateValue = {};
      switch (column) {
        case 'internal_interview':
          updateValue = {
            ...this.entryDeepCopy,
            internal_interview: this.editEntry.internal_interview,
          };
          break;
        case 'evaluation':
          updateValue = {
            ...this.entryDeepCopy,
            evaluation: this.editEntry.evaluation,
          };
          break;
        case 'priority':
          updateValue = {
            ...this.entryDeepCopy,
            priority: this.editEntry.priority,
          };
          break;
        case 'status':
          updateValue = {
            ...this.entryDeepCopy,
            status: this.editEntry.status,
          };
          break;
        case 'admin_id':
          updateValue = {
            ...this.entryDeepCopy,
            admin_id: this.editEntry.admin_id,
          };
          break;
        default:
          updateValue = this.editEntry;
          break;
      }
      return updateValue;
    },
  },
};
</script>
